import { ErrorOutlineOutlined } from "@mui/icons-material";
import clsx from "clsx";
import { useStoreState } from "easy-peasy";
import React from "react";
import useStyles from "./self_ordering_banner_styles";

export default function SelfOrderingBanner() {
  const classes = useStyles();

  const subscriptionStatus = useStoreState((state) => state.subscriptionStatus);
  const restaurant = useStoreState((state) => state.restaurant);

  return (
    <>
      {subscriptionStatus &&
        restaurant &&
        !subscriptionStatus.show_warning &&
        subscriptionStatus.active &&
        !restaurant.self_ordering && (
          <div className={clsx(classes.outer, classes.outer_critical)}>
            <div>
              <ErrorOutlineOutlined />
            </div>
            <div className={classes.message}>
              {`Attention: Self-ordering feature disabled. Customers can view the menu by scanning QR code but won't be able to place orders themselves. To activate self-ordering, access the admin portal.`}
            </div>
          </div>
        )}
    </>
  );
}
