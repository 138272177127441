import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  modifiers_list: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
});

export default useStyles;
