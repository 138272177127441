import styled from "styled-components";

export const ViewBillContainer = styled.div`
  bottom: 44px;
  position: fixed;
  z-index: 2;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 800px;
  padding: 0px 20px;
  scale: 1;
  outline: none;
`;

export const CardOuter = styled.div`
  position: fixed;
  bottom: 44px;
  top: 61px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0px 20px;
  max-width: 800px;
`;
