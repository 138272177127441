import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  outer: {
    marginTop: "10px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "15px",
    justifyContent: "space-evenly",
    height: "80px",
    gap: 10,
  },
  col: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "15px",
    justifyContent: "space-evenly",
    gap: 20,
  },
  filter_header: {
    padding: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 18,
    alignItems: "center",
    cursor: "pointer",
  },
});

export default useStyles;
