import axios from "axios";
import { api_base_url } from "../config/config";
import store from "../store/store";
import _ from "lodash";
import { createLogCall } from "./logger_api";
import { getClientTimeZone } from "../utils/get_time_zone";
import queryString from "query-string";
import { showAlert } from "../components/message_box/show_alert";
import { setLoading } from "../components/loader/loader";

const actions = store.getActions();

const handleApiCall = async (method, url, body, blockLoading) => {
  if (!blockLoading) {
    setLoading(true);
  }

  const token = localStorage.getItem("sn_rest_access_token") || "";
  try {
    const response = await axios({
      baseURL: api_base_url,
      url: url,
      timeout: 60000,
      method: method,
      data: body,
      headers: {
        "Content-Type": "application/json",
        "Bypass-Tunnel-Reminder": "yes",
        Authorization: token,
        "Client-Timezone": getClientTimeZone(),
      },
    });
    setLoading(false);
    return response.data;
  } catch (error) {
    setLoading(false);
    if (error.response) {
      if (error.response.status !== 401) {
        createLogCall(
          "RESTAURANT_FE",
          null,
          error.response.status,
          method,
          url,
          body,
          error.response.data
        );
      }
      switch (error.response.status) {
        case 401:
          if (error.response.data.data.message === "TokenExpiredError") {
            return await refresh(method, url, body);
          } else {
            localStorage.removeItem("sn_rest_access_token");
            localStorage.removeItem("sn_rest_refresh_token");
            window.location.href = "/";
          }
          break;
        case 422:
          showAlert({
            head: "Unable to proceed with validation errors",
            body: error.response.data.data[0]
              ? error.response.data.data[0].message
              : "Please check form fields and try again",
          });
          return {
            alert_showed: true,
            success: false,
            data: null,
            message: "Validation Errors",
          };
        case 500:
          showAlert({
            head: "Something went wrong",
            body: "Please contact system admin",
          });
          console.log(error.response.data);
          return {
            success: false,
            data: null,
            message: "Internal Server Error",
          };
        case 403:
          showAlert({
            head: "Access Denied!",
            body:
              error?.response?.data?.message || "Please contact system admin",
          });
          return {
            success: false,
            data: null,
            message: "Access Denied",
          };
        default:
          showAlert({
            head: "Something went wrong",
            body: "Please contact system admin",
          });
          console.log("unhandled status code", error.response.data);
          return {
            success: false,
            data: null,
            message: "Internal Server Error",
          };
      }
    } else {
      createLogCall("RESTAURANT_FE", null, 500, method, url, body, error);
      console.log("no response from server", error);
      window.location.href = "/";
      showAlert({
        head: "Something went wrong",
        body: "Please contact system admin",
      });
      return {
        success: false,
        data: null,
        message: "no response from server",
      };
    }
  }
};

const loginCall = async (userName, password) => {
  const response = await handleApiCall("POST", "/auth/restaurant/login", {
    user_name: userName,
    password: password,
  });
  if (response.success) {
    localStorage.setItem("sn_rest_access_token", response.data.access_token);
    localStorage.setItem("sn_rest_refresh_token", response.data.refresh_token);
  }
  return response;
};

const setPasswordCall = async (restaurantToken, password) => {
  const response = await handleApiCall(
    "POST",
    "/auth/restaurant/set-password",
    {
      restaurant_token: restaurantToken,
      password: password,
    }
  );
  if (response.success) {
    localStorage.setItem("sn_rest_access_token", response.data.access_token);
    localStorage.setItem("sn_rest_refresh_token", response.data.refresh_token);
  }
  return response;
};

const refresh = async (method, url, body) => {
  const refreshToken = localStorage.getItem("sn_rest_refresh_token");
  try {
    const response = await axios.post(
      `${api_base_url}/auth/restaurant/refresh`,
      {
        refresh_token: refreshToken,
      }
    );
    if (response.data.success) {
      localStorage.setItem(
        "sn_rest_access_token",
        response.data.data.access_token
      );
      localStorage.setItem(
        "sn_rest_refresh_token",
        response.data.data.refresh_token
      );
      return await handleApiCall(method, url, body);
    }
  } catch (error) {}
};

const getRestaurantCall = async () => {
  const response = await handleApiCall("GET", "/api/restaurant/me", null);
  if (response.success) {
    actions.setRestaurant(response.data);
  }
  return response;
};

const getItemCategoriesCall = async (isActiveOnly) => {
  const response = await handleApiCall(
    "GET",
    `/api/restaurant/item-categories?active_only=${isActiveOnly ? "1" : "0"}`,
    null
  );
  if (response.success) {
    actions.setItemCategories(response.data);
    actions.setSelectedItemCategory(_.first(response.data));
  }
  return response;
};

const setAdminPasswordCall = async (adminPassword) => {
  const response = await handleApiCall(
    "POST",
    "/api/restaurant/set_admin_password",
    {
      admin_password: adminPassword,
    }
  );
  if (response.success) {
    actions.setRestaurant(response.data);
  }
  return response;
};

const checkAdminPasswordCall = async (adminPassword) => {
  const response = await handleApiCall(
    "POST",
    "/api/restaurant/check_admin_password",
    {
      admin_password: adminPassword,
    }
  );
  return response;
};

const createItemCategoryCall = async (data) => {
  const response = await handleApiCall(
    "POST",
    "/api/restaurant/item-categories",
    data
  );
  if (response.success) {
    actions.addItemCategory(response.data);
  }
  return response;
};

const updateItemCategoryCall = async (data, id) => {
  const response = await handleApiCall(
    "PUT",
    `/api/restaurant/item-categories/${id}`,
    data
  );
  if (response.success) {
    actions.updateItemCategory(response.data);
  }
  return response;
};

const createItemCall = async (data) => {
  const response = await handleApiCall("POST", "/api/restaurant/items", data);
  if (response.success) {
    actions.addItem(response.data);
  }
  return response;
};

const updateItemCall = async (data, id) => {
  const response = await handleApiCall(
    "PUT",
    `/api/restaurant/items/${id}`,
    data
  );
  if (response.success) {
    actions.updateItem(response.data);
  }
  return response;
};

const getTablesCall = async (blockLoading) => {
  const response = await handleApiCall(
    "GET",
    "/api/restaurant/tables",
    null,
    blockLoading
  );
  if (response.success) {
    actions.setTables(response.data);
  }
  return response;
};

const createTableCall = async (data) => {
  const response = await handleApiCall("POST", "/api/restaurant/tables", data);
  if (response.success) {
    actions.addTable(response.data);
  }
  return response;
};

const updateTableCall = async (data, id) => {
  const response = await handleApiCall(
    "PUT",
    `/api/restaurant/tables/${id}`,
    data
  );
  if (response.success) {
    actions.updateTable(response.data);
  }
  return response;
};

const createOrderCall = async (data) => {
  const response = await handleApiCall("POST", "/api/restaurant/orders", data);
  return response;
};

const updateOrderCall = async (id, data, blockLoading) => {
  const response = await handleApiCall(
    "PUT",
    `/api/restaurant/orders/${id}`,
    data,
    blockLoading
  );
  if (response.success) {
    actions.updateSelectedOrder(response.data);
  }
  return response;
};

const getTableOrdersCall = async (tableId, blockLoading) => {
  const response = await handleApiCall(
    "GET",
    `/api/restaurant/orders/table/${tableId}`,
    null,
    blockLoading
  );
  if (response.success) {
    actions.setSelectedTableOrders(response.data);
  }
  return response;
};

const addItemToOrderCall = async (data) => {
  const response = await handleApiCall(
    "POST",
    `/api/restaurant/order_items/order/add`,
    data
  );
  if (response.success) {
    actions.addItemToOrder(response.data);
  } else if (response.data && response.data.already_exists) {
    showAlert({ head: "Info", body: response.message });
  }
  return response;
};

const removeItemFromOrderCall = async (orderId, orderItemId) => {
  const response = await handleApiCall(
    "DELETE",
    `/api/restaurant/order_items`,
    {
      order_id: orderId,
      order_item_id: orderItemId,
    }
  );
  if (response.success) {
    actions.removeItemFromOrder(response.data);
  } else if (response.data && response.data.item_not_found) {
    showAlert({ head: "Info", body: response.message });
  }
  return response;
};

const updateOrderItemQuantityCall = async (orderId, orderItemId, quantity) => {
  const response = await handleApiCall("PUT", `/api/restaurant/order_items`, {
    order_id: orderId,
    order_item_id: orderItemId,
    quantity: quantity,
  });
  if (response.success) {
    actions.updateItemQuantity(response.data);
  } else if (response.data && response.data.item_not_found) {
    showAlert({ head: "Info", body: response.message });
  }
  return response;
};

const getRestaurantOrderByIdCall = async (orderId, blockLoading) => {
  const response = await handleApiCall(
    "GET",
    `/api/restaurant/orders/${orderId}`,
    null,
    blockLoading
  );
  if (response.success) {
    actions.setOrderById(response.data);
  }
  return response;
};

const getRestaurantChargesCall = async () => {
  const response = await handleApiCall("GET", `/api/restaurant/charges`);
  if (response.success) {
    actions.setCharges(response.data);
  }
  return response;
};

const createChargeCall = async (data) => {
  const response = await handleApiCall("POST", `/api/restaurant/charges`, data);
  if (response.success) {
    actions.createCharge(response.data);
  }
  return response;
};

const updateChargeCall = async (data, id) => {
  const response = await handleApiCall(
    "PUT",
    `/api/restaurant/charges/${id}`,
    data
  );
  if (response.success) {
    actions.updateCharge(response.data);
  }
  return response;
};

const addChargeToOrderCall = async (data) => {
  const response = await handleApiCall(
    "POST",
    `/api/restaurant/order_charges/order/add`,
    data
  );
  if (response.success) {
  } else if (response.data && response.data.already_exists) {
    showAlert({ head: "Info", body: response.message });
  }
  return response;
};

const removeChargeFromOrderCall = async (orderId, chargeItemId) => {
  const response = await handleApiCall(
    "DELETE",
    `/api/restaurant/order_charges`,
    {
      order_id: orderId,
      order_charge_id: chargeItemId,
    }
  );
  if (response.success) {
    actions.removeChargeFromOrder(response.data);
  } else if (response.data && response.data.item_not_found) {
    showAlert({ head: "Info", body: response.message });
  }
  return response;
};

const payOrderByCashCall = async (orderId) => {
  const response = await handleApiCall("POST", `/api/restaurant/orders/pay`, {
    order_id: orderId,
  });
  if (response.success) {
    actions.orderPay(response.data);
  } else if (!response.alert_showed) {
    showAlert({ head: "Info", body: response.message });
  }
  return response;
};

const getOrderListCall = async (filter, page, limit) => {
  const response = await handleApiCall(
    "POST",
    `/api/restaurant/orders/list?page=${page}&limit=${limit}`,
    filter
  );
  return response;
};

const generateQrUrlCall = async (tableId) => {
  const response = await handleApiCall(
    "GET",
    `/api/restaurant/tables/${tableId}/qr_code`,
    null
  );
  return response;
};

const generateAllQrUrlsCall = async () => {
  const response = await handleApiCall(
    "GET",
    `/api/restaurant/tables/all_qr_codes`,
    null
  );
  return response;
};

const generateMainQrUrlCall = async () => {
  const response = await handleApiCall("GET", `/api/restaurant/main_qr`, null);
  return response;
};

const updateRestaurantCall = async (data) => {
  const response = await handleApiCall("PUT", `/api/restaurant`, data);
  if (response.success) {
    actions.setRestaurant(response.data);
  }
  return response;
};

const pinOrderCall = async (orderId) => {
  const response = await handleApiCall(
    "PUT",
    `/api/restaurant/orders/${orderId}/pin`
  );
  return response;
};

const unpinOrderCall = async (orderId) => {
  const response = await handleApiCall(
    "PUT",
    `/api/restaurant/orders/${orderId}/unpin`
  );
  return response;
};

const closeOrderCall = async (orderId) => {
  const response = await handleApiCall(
    "DELETE",
    `/api/restaurant/orders/${orderId}`
  );
  return response;
};

const printRequestCall = async (orderId) => {
  const response = await handleApiCall(
    "GET",
    `/api/restaurant/print_request/request/${orderId}`
  );
  return response;
};

const clearPrintRequestCall = async (orderId) => {
  const response = await handleApiCall(
    "GET",
    `/api/restaurant/print_request/delete/${orderId}`,
    null,
    true
  );
  return response;
};

const getPrintRequestsCall = async () => {
  const response = await handleApiCall(
    "GET",
    `/api/restaurant/print_request`,
    null,
    true
  );
  return response;
};

const serveItemCall = async (orderItemId, serveStatus) => {
  const response = await handleApiCall(
    "PUT",
    `/api/restaurant/order_items/serve_status/${orderItemId}`,
    {
      serve_status: serveStatus,
    },
    false
  );
  if (response.success) {
    actions.serveItemOnOrder({ id: orderItemId, status: serveStatus });
  }
  return response;
};

const clearPaymentRequestCall = async (orderId) => {
  const response = await handleApiCall(
    "PUT",
    `/api/restaurant/orders/${orderId}/clear_payment_request`
  );
  return response;
};

const getPayouts = async (filter, page, limit) => {
  const query = queryString.stringify({ ...filter, page, limit });
  const response = await handleApiCall(
    "GET",
    `/api/restaurant/payouts?${query}`
  );
  return response;
};

const getSubscriptions = async () => {
  const response = await handleApiCall("GET", `/api/restaurant/subscriptions`);
  return response;
};

const createSubscription = async (body) => {
  const response = await handleApiCall(
    "POST",
    `/api/restaurant/subscriptions`,
    body
  );
  return response;
};

const checkSubscription = async () => {
  const response = await handleApiCall(
    "GET",
    `/api/restaurant/subscriptions/check`,
    null,
    true
  );
  if (response.success) {
    actions.setSubscriptionStatus(response.data);
  }
  return response;
};

export default function RestaurantApiService() {
  return {
    loginCall: loginCall,
    setPasswordCall: setPasswordCall,
    refresh: refresh,
    getRestaurantCall: getRestaurantCall,
    getItemCategoriesCall: getItemCategoriesCall,
    setAdminPasswordCall: setAdminPasswordCall,
    createItemCategoryCall: createItemCategoryCall,
    updateItemCategoryCall: updateItemCategoryCall,
    createItemCall: createItemCall,
    updateItemCall: updateItemCall,
    getTablesCall: getTablesCall,
    createTableCall: createTableCall,
    updateTableCall: updateTableCall,
    createOrderCall: createOrderCall,
    updateOrderCall: updateOrderCall,
    getTableOrdersCall: getTableOrdersCall,
    addItemToOrderCall: addItemToOrderCall,
    removeItemFromOrderCall: removeItemFromOrderCall,
    updateOrderItemQuantityCall: updateOrderItemQuantityCall,
    getRestaurantOrderByIdCall: getRestaurantOrderByIdCall,
    getRestaurantChargesCall: getRestaurantChargesCall,
    createChargeCall: createChargeCall,
    updateChargeCall: updateChargeCall,
    addChargeToOrderCall: addChargeToOrderCall,
    removeChargeFromOrderCall: removeChargeFromOrderCall,
    payOrderByCashCall: payOrderByCashCall,
    getOrderListCall: getOrderListCall,
    generateQrUrlCall: generateQrUrlCall,
    updateRestaurantCall: updateRestaurantCall,
    pinOrderCall: pinOrderCall,
    unpinOrderCall: unpinOrderCall,
    closeOrderCall: closeOrderCall,
    printRequestCall: printRequestCall,
    clearPrintRequestCall: clearPrintRequestCall,
    getPrintRequestsCall: getPrintRequestsCall,
    serveItemCall: serveItemCall,
    clearPaymentRequestCall: clearPaymentRequestCall,
    generateMainQrUrlCall: generateMainQrUrlCall,
    getPayouts: getPayouts,
    getSubscriptions: getSubscriptions,
    createSubscription: createSubscription,
    checkSubscription: checkSubscription,
    checkAdminPasswordCall: checkAdminPasswordCall,
    generateAllQrUrlsCall: generateAllQrUrlsCall,
  };
}
