import { Card, Grid } from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import RestaurantApiService from "../../api_service/restaurant_api_service";
import ZoomInAnimatedDiv from "../animated_div/zoom_in_animated_div";
import Button1 from "../button_1/button_1";
import useStyles from "./discounts_styles";

export default function Discounts() {
  const discounts = useStoreState((state) =>
    state.charges.filter((discount) => discount.is_discount)
  );
  const classes = useStyles();
  const adminView = useStoreState((state) => state.currentView === "ADMIN");
  const setOpenEditDiscount = useStoreActions(
    (actions) => actions.setOpenEditDiscount
  );
  const setDiscountToEdit = useStoreActions((actions) => actions.setDiscountToEdit);

  useEffect(() => {
    RestaurantApiService().getRestaurantChargesCall();
  }, []);

  const onAddNewDiscount = () => {
    setOpenEditDiscount(true);
    setDiscountToEdit(null);
  };

  return (
    <div>
      {adminView && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button1
            onClick={onAddNewDiscount}
            title="Add New Discount"
            width="50%"
          />
        </div>
      )}

      <Card className={classes.outer_card}>
        {!adminView && (
          <div className={classes.other_charges}>Other Discounts</div>
        )}
        <Grid className={classes.grid} container spacing={1}>
          {discounts.map((discount, index) => (
            <DiscountItem key={index} discount={discount} index={index} />
          ))}
        </Grid>
      </Card>
    </div>
  );
}

const DiscountItem = ({ discount, index }) => {
  const classes = useStyles();
  const [isHover, setHover] = useState(false);
  const adminView = useStoreState((state) => state.currentView === "ADMIN");
  const setOpenEditDiscount = useStoreActions(
    (actions) => actions.setOpenEditDiscount
  );
  const setDiscountToEdit = useStoreActions((actions) => actions.setDiscountToEdit);
  const selectedTab = useStoreState((state) => state.selectedTab);
  const selectedOrder = useStoreState((state) => state.selectedOrder);

  const addDiscountToOrder = async () => {
    if (selectedTab === "order_detail" && selectedOrder) {
      await RestaurantApiService().addChargeToOrderCall({
        order_id: selectedOrder.id,
        charge_id: discount.id,
      });
      RestaurantApiService().getRestaurantOrderByIdCall(selectedOrder.id, true);
    }
  };

  return adminView || (!adminView && discount.is_active) ? (
    <Grid style={{ backgroundColor: "transparent" }} item xs={6}>
      <ZoomInAnimatedDiv delay={index / 2} show={discount.id}>
        <Card
          onClick={
            adminView
              ? () => {
                  setOpenEditDiscount(true);
                  setDiscountToEdit(discount);
                }
              : addDiscountToOrder
          }
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          elevation={isHover ? 15 : 3}
          className={classes.item_outer}
          style={{
            backgroundColor: !adminView
              ? "#e9e9e9"
              : discount.is_active
              ? "#d5ffbf"
              : "#ffbfbf",
          }}
        >
          <div className={classes.name}>{discount.name}</div>
          <div className={classes.price}>
            {discount.value_type === "PERCENTAGE"
              ? `${discount.value}%`
              : discount.value}
          </div>
        </Card>
      </ZoomInAnimatedDiv>
    </Grid>
  ) : (
    <div />
  );
};
