import { Box, FormControl, TextField } from "@mui/material";
import React, { useState } from "react";
import Button1 from "../../../components/button_1/button_1";
import useStyles from "./login_page_styles";
import TermsAndConditions from "../../../components/terms_and_conditions/terms_and_conditions";
import { useHistory } from "react-router-dom";
import AdminApiService from "../../../api_service/admin_api_service";
import { showAlert } from "../../../components/message_box/show_alert";

export default function AdminLoginForm() {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    email: "",
    password: "",
    loading: false,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const loginAction = () => {
    if (!state.email) {
      return showAlert({
        head: "Please Be Careful!",
        body: "'Email' is required to login.",
      });
    }
    if (!state.password) {
      return showAlert({
        head: "Please Be Careful!",
        body: "'Password' is required to login.",
      });
    }
    changeState("loading", true);
    AdminApiService()
      .loginCall(state.email, state.password)
      .then((response) => {
        changeState("loading", false);
        if (response.success) {
          history.replace("/admin/home");
        } else {
          return showAlert({ head: "Info", body: response.message });
        }
      });
  };

  return (
    <div className={classes.login_form_outer}>
      <Box height={50} />
      <FormControl fullWidth>
        <TextField
          className={classes.text_field}
          type="email"
          name="email"
          label="Please enter your Email"
          InputLabelProps={{ shrink: true }}
          value={state.email}
          onChange={(event) => {
            changeState("email", event.target.value.toLowerCase());
          }}
        />
        <Box height={50} />
        <TextField
          className={classes.text_field}
          type="password"
          name="password"
          label="Please enter your Password"
          InputLabelProps={{ shrink: true }}
          value={state.password}
          onChange={(event) => {
            changeState("password", event.target.value);
          }}
        />
        <Box height={50} />
        <div className={classes.login_btn_outer}>
          <Button1
            loading={state.loading}
            onClick={loginAction}
            title={"ok"}
            isSelected
            width={150}
          />
        </div>
        <TermsAndConditions />
      </FormControl>
    </div>
  );
}
