import axios from "axios";
import { api_base_url } from "../config/config";
import { getClientTimeZone } from "../utils/get_time_zone";

export const createLogCall = (
  type,
  type_id,
  status,
  method,
  path,
  request,
  response
) => {
  try {
    axios({
      baseURL: api_base_url,
      url: "/logs",
      timeout: 60000,
      method: "POST",
      data: {
        type,
        type_id,
        status,
        method,
        path,
        request,
        response,
      },
      headers: {
        "Content-Type": "application/json",
        "Bypass-Tunnel-Reminder": "yes",
        "Client-Timezone": getClientTimeZone()
      },
    });
  } catch (error) {
      console.log(error)
  }
};
