import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  footer: {
    backgroundColor: "#333",
    color: "#fff",
    textAlign: "center",
    position: "fixed",
    bottom: 0,
    width: "100%",
  },
});

export default useStyles;
