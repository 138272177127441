import React from "react";
import Item from "../Item";
import * as Styled from "./index.styled";
import useWindowSize from "../../../../utils/use_window_size";

function Index({ data, customStyles = {} }) {
  const { height } = useWindowSize();

  const { paddingBottom } = customStyles;

  return (
    <Styled.ItemContainer>
      <Styled.ItemSubContainer
        height={height}
        pb={paddingBottom ? paddingBottom : undefined}
      >
        {data?.length &&
          data.map((item, index) =>
            // item.is_active ? (
              <Styled.EachItemContainer key={index}>
                <Item item={item} />
              </Styled.EachItemContainer>
            // ) : (
            //   <div />
            // )
          )}
      </Styled.ItemSubContainer>
    </Styled.ItemContainer>
  );
}

export default Index;



