import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  outer: {
    border: "1px solid #d5d5d5",
    borderRadius: "5px",
    backgroundColor: "#ededed",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 10px",
  },
  modifiers_col: {
    padding: 10,
    gap: 10,
    display: "flex",
    flexDirection: "column",
  },
  modifier_outer: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
    border: "1px solid #dadada",
    padding: 10,
    borderRadius: 5,
    backgroundColor: "#ffffffcc",
  },
  input_row: { display: "flex", flexDirection: "row", gap: 10 },
});

export default useStyles;
