import {
  Backdrop,
  Box,
  Card,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./edit_table_styles";
import { useStoreActions, useStoreState } from "easy-peasy";
import Button1 from "../button_1/button_1";
import RestaurantApiService from "../../api_service/restaurant_api_service";
import html2canvas from "html2canvas";
import SNQrCode from "./qr_code";
import ModelHeader from "../model_header/model_header";
import SNQrCodeDesign2 from "./qr_code_design_2";
import { showAlert } from "../message_box/show_alert";

export default function EditTable() {
  const classes = useStyles();
  const openEditTable = useStoreState((state) => state.openEditTable);
  const tableToEdit = useStoreState((state) => state.tableToEdit);
  const setOpenEditTable = useStoreActions(
    (actions) => actions.setOpenEditTable
  );

  const [state, setState] = useState({
    number: tableToEdit?.number || "",
    is_active: tableToEdit?.is_active || true,
    qr_url: null,
  });

  const [qr, setQr] = useState();
  const changeState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const [qrDesign, setQrDesign] = useState(0);

  const setInitialData = () => {
    setState({
      ...state,
      number: tableToEdit?.number || "",
      is_active: tableToEdit?.is_active || true,
    });
    setQr(null);
  };
  useEffect(() => {
    if (openEditTable) {
      setInitialData();
    }
  }, [openEditTable]); // eslint-disable-line

  const onClose = () => {
    setOpenEditTable(false);
  };

  const onSubmit = () => {
    if (!state.number) {
      return showAlert({
        head: "Please be careful!",
        body: "Please enter the number of your 'Table'",
      });
    }
    if (tableToEdit) {
      const previousNumber = tableToEdit.number;
      RestaurantApiService()
        .updateTableCall(
          {
            number: state.number,
            is_active: state.is_active,
          },
          tableToEdit.id
        )
        .then((response) => {
          if (response.success) {
            onClose();
            if (previousNumber !== response.data.number) {
              showAlert({
                head: "Info",
                body: `Table no : ${previousNumber} is successfully changed as Table no : ${response.data.number}. Please generate a new QR code according to the updated table number. Previous QR code will not work now onwards.`,
              });
            }
          } else {
            showAlert({ head: "Info", body: response.message });
          }
        });
    } else {
      RestaurantApiService()
        .createTableCall({
          number: state.number,
        })
        .then((response) => {
          if (response.success) {
            onClose();
          }
        });
    }
  };

  const generateQrCode = async () => {
    const response = await RestaurantApiService().generateQrUrlCall(
      tableToEdit.id
    );
    if (response.success) {
      setQr(response.data);
    }
  };

  const downloadQr = async (id) => {
    const canvas = await html2canvas(document.getElementById(id));
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${qr.restaurant_code}_${qr.table_number}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Backdrop className={classes.backdrop} open={openEditTable}>
      <Card className={classes.card}>
        <ModelHeader
          title={tableToEdit ? "Update Table" : "Add New Table"}
          onClose={onClose}
        />
        <div style={{ padding: "20px" }}>
          <Box height={30} />
          <TextField
            inputRef={(input) => input && input.focus()}
            className={classes.text_field}
            label="Please enter 'Table Number'"
            value={state.number}
            onChange={(event) => {
              changeState("number", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(ev) => {
              if (ev.key === "Enter") {
                onSubmit();
                ev.preventDefault();
              }
            }}
          />

          <Box height={20} />

          {qr ? (
            <div style={{ textAlign: "center" }}>
              <FormControl>
                <RadioGroup
                  row
                  value={qrDesign}
                  onChange={(event, value) => setQrDesign(Number(value))}
                >
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label="Design 1"
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Design 2"
                  />
                </RadioGroup>
              </FormControl>
              <Box height={20} />
              {qrDesign === 0 ? (
                <SNQrCode qr={qr} id="qr_generator" />
              ) : qrDesign === 1 ? (
                <SNQrCodeDesign2 qr={qr} id="qr_generator" />
              ) : null}

              <Button1
                width={"200px"}
                onClick={() => downloadQr("qr_generator")}
                title="Download"
              />
            </div>
          ) : tableToEdit ? (
            <Button1
              width={"100%"}
              onClick={generateQrCode}
              title="Generate QR Code"
            />
          ) : (
            <div />
          )}

          <Box height={20} />
          <div className={classes.button_row}>
            <Button1 onClick={onSubmit} isSelected title="Submit" />
          </div>
        </div>
      </Card>
    </Backdrop>
  );
}
