import {
  Backdrop,
  Box,
  Card,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./download_all_qr_styles";
import { useStoreActions, useStoreState } from "easy-peasy";
import Button1 from "../button_1/button_1";
import RestaurantApiService from "../../api_service/restaurant_api_service";
import html2canvas from "html2canvas";
import SNQrCode from "../edit_table/qr_code";
import ModelHeader from "../model_header/model_header";
import SNQrCodeDesign2 from "../edit_table/qr_code_design_2";

export default function DownloadAllQR() {
  const classes = useStyles();
  const openDownloadAllTables = useStoreState(
    (state) => state.openDownloadAllTables
  );
  const setOpenDownloadAllTables = useStoreActions(
    (actions) => actions.setOpenDownloadAllTables
  );

  const [state, setState] = useState({
    number: "",
    is_active: true,
    qr_url: null,
  });

  const [qr, setQr] = useState();

  const [qrDesign, setQrDesign] = useState(0);

  const setInitialData = () => {
    setState({
      ...state,
      number: "",
      is_active: true,
    });
    setQr(null);
  };
  useEffect(() => {
    if (openDownloadAllTables) {
      setInitialData();
    }
  }, [openDownloadAllTables]); // eslint-disable-line

  const onClose = () => {
    setOpenDownloadAllTables(false);
  };

  const downloadAll = async () => {
    const response = await RestaurantApiService().generateAllQrUrlsCall();
    if (response.success) {
      for (const qrCode of response.data) {
        setQr(qrCode);
        await downloadQr("qr_generator", qrCode);
      }
    }
    onClose();
  };

  const downloadQr = async (id, qrCode) => {
    const canvas = await html2canvas(document.getElementById(id));
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${qrCode.restaurant_code}_${qrCode.table_number}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Backdrop className={classes.backdrop} open={openDownloadAllTables}>
      <Card className={classes.card}>
        <ModelHeader title={"Download all qr codes"} onClose={onClose} />
        <div style={{ padding: "20px" }}>
          <FormControl>
            <RadioGroup
              row
              value={qrDesign}
              onChange={(event, value) => setQrDesign(Number(value))}
            >
              <FormControlLabel
                value={0}
                control={<Radio />}
                label="Design 1"
              />
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="Design 2"
              />
            </RadioGroup>
          </FormControl>
          <Box height={20} />
          {qr && (
            <div style={{ textAlign: "center" }}>
              {qrDesign === 0 ? (
                <SNQrCode qr={qr} id="qr_generator" />
              ) : qrDesign === 1 ? (
                <SNQrCodeDesign2 qr={qr} id="qr_generator" />
              ) : null}
            </div>
          )}

          <Button1 onClick={downloadAll} title="Download All" />
          <Box height={20} />
        </div>
      </Card>
    </Backdrop>
  );
}
