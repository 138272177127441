import React from "react";
import useStyles from "./edit_table_styles";
import QRCode from "qrcode.react";

export default function SNQrCode({ qr, id }) {
  const classes = useStyles();

  const logoInsideQR = {
    src: "/images/ceypaysvglogo.svg",
    x: undefined,
    y: undefined,
    height: 60,
    width: 60,
    excavate: true,
  };
  return (
    <div id={id} className={classes.qr_outer}>
      <div id={id} className={classes.qr_outer2}>
        <div className={classes.scan_text}>
          Scan me and Order as you wish
        </div>
        <div className={classes.rest_name}>{qr.restaurant_name}</div>
        <QRCode
          value={qr.url}
          size={350}
          level={"H"}
          className={classes.qr}
          imageSettings={logoInsideQR}
        />

        <div className={classes.desc_row}>
          <span className={classes.code}>{qr.restaurant_code}</span>
        </div>
        <div className={classes.desc_row}>
          <span className={classes.table_no}>Table : {qr.table_number}</span>
        </div>
      </div>
    </div>
  );
}
