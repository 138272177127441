import styles from "../styles/backArrow.module.scss";
import { ArrowBack } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

export default function BackArrow({ onClick, id }) {
  const history = useHistory()

  const navigate = () => {
    if (onClick != null) {
      onClick();
    } else {
      history.goBack();
    }
  };

  return (
    <div id={id} className={styles.container} onClick={navigate}>
      <ArrowBack className={styles.icon} fontSize="small" />
    </div>
  );
}
