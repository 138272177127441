import { makeStyles } from "@mui/styles";
import { background_color_2 } from "../../../themes/colors";

const useStyles = makeStyles({
  root: {
    height: "100vh",
    width: "100%",
    backgroundColor: background_color_2,
    padding: "20px",
    overflow: "auto",
  },
  main_row: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    paddingTop: "20px",
    zIndex: 4,
  },
  paginator_outer: {
    width: "100%",
    justifyContent: "right",
    display: "flex",
    margin: "20px 20px 20px 0",
  },
});

export default useStyles;
