import axios from "axios";
import { setLoading } from "../components/loader/loader";
import { showAlert } from "../components/message_box/show_alert";
import { api_base_url } from "../config/config";
import { getClientTimeZone } from "../utils/get_time_zone";
import { createLogCall } from "./logger_api";

const handleApiCall = async (method, url, body, blockLoading) => {
  if (!blockLoading) {
    setLoading(true);
  }
  const access_token =
    localStorage.getItem("sn_rest_cust_access_token") || null;
  try {
    const response = await axios({
      baseURL: api_base_url,
      url: url,
      timeout: 60000,
      method: method,
      data: body,
      headers: {
        "Content-Type": "application/json",
        "Bypass-Tunnel-Reminder": "yes",
        Authorization: `Bearer ${access_token}`,
        "Client-Timezone": getClientTimeZone(),
      },
    });
    setLoading(false);
    if (response.data.access_token) {
      localStorage.setItem(
        "sn_rest_cust_access_token",
        response.data.access_token
      );
    }
    if (response.data.user_profile) {
      localStorage.setItem(
        "sn_rest_local_cust_profile",
        JSON.stringify(response.data.user_profile)
      );
    }
    return response.data;
  } catch (error) {
    setLoading(false);
    if (error.response) {
      if (error.response.status !== 401) {
        createLogCall(
          "CUSTOMER_FE",
          null,
          error.response.status,
          method,
          url,
          body || {},
          error.response.data
        );
      }
      switch (error.response.status) {
        case 422:
          showAlert({
            head: "Unable to proceed",
            body: error.response.data.data[0]
              ? error.response.data.data[0].message
              : "Please check form fields and try again",
            actions: [],
            isCustomer: true,
          });
          return {
            alert_showed: true,
            success: false,
            data: null,
            message: "Validation Errors",
          };
        case 404:
          showAlert({
            head: "Info",
            body: error.response.data.message,
            isCustomer: true,
          });
          return {
            alert_showed: true,
            success: false,
            data: error.response.data.data,
            message: error.response.data.message,
          };
        case 500:
          showAlert({
            head: "Something went wrong",
            body: "Please contact system admin",
            isCustomer: true,
          });
          console.log(error.response.data);
          return {
            success: false,
            data: null,
            message: "Internal Server Error",
          };
        default:
          showAlert({
            head: "Something went wrong",
            body: "Please contact system admin",
            isCustomer: true,
          });
          console.log("unhandled status code", error.response.data);
          return {
            success: false,
            data: null,
            message: "Internal Server Error",
          };
      }
    } else {
      createLogCall("CUSTOMER_FE", null, 500, method, url, body, error);
      console.log("no response from server", error);
      // window.location.href = "/";
      showAlert({
        head: "Something went wrong",
        body: "Please contact system admin",
        isCustomer: true,
      });
      return {
        success: false,
        data: null,
        message: "no response from server",
      };
    }
  }
};

const getRestaurantDetailsQrCall = async (restaurantCode, tableNo) => {
  const response = await handleApiCall(
    "GET",
    `/api/customer/restaurants/${restaurantCode}/${tableNo}`
  );
  return response;
};

const getRestaurantDetailsCall = async (restaurantCode) => {
  const response = await handleApiCall(
    "GET",
    `/api/customer/restaurants/${restaurantCode}`
  );
  return response;
};

const getItemCategoriesWithItemsCall = async (restaurantCode) => {
  const response = await handleApiCall(
    "GET",
    `/api/customer/restaurants/${restaurantCode}/item_categories`
  );
  return response;
};

const addItemToOrderCall = async (data) => {
  const response = await handleApiCall(
    "POST",
    `/api/customer/orders/add_item`,
    data
  );
  return response;
};

const removeItemFromOrderCall = async (data) => {
  const response = await handleApiCall(
    "DELETE",
    `/api/customer/orders/remove_item`,
    data
  );
  return response;
};

const getOrderByIdCall = async (
  restaurantId,
  tableId,
  orderId,
  blockLoading
) => {
  const response = await handleApiCall(
    "GET",
    `/api/customer/restaurants/${restaurantId}/${tableId}/orders/${orderId}`,
    null,
    blockLoading
  );
  return response;
};

const getOrderByIdSuccessPageCall = async (restaurantId, tableId, orderId) => {
  const response = await handleApiCall(
    "GET",
    `/api/customer/restaurants/${restaurantId}/${tableId}/orders/${orderId}?page=success`
  );
  return response;
};

const paymentInitializeCall = async (
  restaurantId,
  tableId,
  orderId,
  tipAmount
) => {
  const response = await handleApiCall(
    "POST",
    `/api/customer/restaurants/${restaurantId}/${tableId}/orders/${orderId}/payment_initialize`,
    {
      tip_amount: tipAmount,
    }
  );
  return response;
};

const checkPaymentStatusCall = async (restaurantId, orderId, blockLoading) => {
  const response = await handleApiCall(
    "GET",
    `/api/customer/restaurants/${restaurantId}/orders/${orderId}/check_payment_status`,
    null,
    blockLoading
  );
  return response;
};

const getOrderHistoryCall = async (page) => {
  const response = await handleApiCall(
    "GET",
    `/api/customer/orders/history?page=${page}`
  );
  return response;
};

const updateProfileCall = async (data) => {
  const response = await handleApiCall("PUT", `/api/customer/profile`, data);
  return response;
};

const getProfileCall = async () => {
  const response = await handleApiCall("GET", `/api/customer/profile`);
  return response;
};

const sendBillEmail = async (orderId, email) => {
  const response = await handleApiCall(
    "POST",
    `/api/customer/orders/send_email`,
    {
      email: email,
      order_id: orderId,
    }
  );
  return response;
};

const updateOrderCall = async (restaurantId, tableId, orderId, data) => {
  const response = await handleApiCall(
    "PUT",
    `/api/customer/restaurants/${restaurantId}/${tableId}/orders/${orderId}/update`,
    data
  );
  return response;
};

const updateWaiterCallingTableCall = async (data, id) => {
  const response = await handleApiCall(
    "PUT",
    `/api/customer/restaurants/table/${id}/waitercall`,
    data
  );
  // if (response.success) {
  //   actions.updateTable(response.data);
  // }
  return response;
};

export default function CustomerApiService() {
  return {
    getRestaurantDetailsQrCall: getRestaurantDetailsQrCall,
    getRestaurantDetailsCall: getRestaurantDetailsCall,
    getItemCategoriesWithItemsCall: getItemCategoriesWithItemsCall,
    addItemToOrderCall: addItemToOrderCall,
    getOrderByIdCall: getOrderByIdCall,
    paymentInitializeCall: paymentInitializeCall,
    checkPaymentStatusCall: checkPaymentStatusCall,
    getOrderByIdSuccessPageCall: getOrderByIdSuccessPageCall,
    getOrderHistoryCall: getOrderHistoryCall,
    updateProfileCall: updateProfileCall,
    sendBillEmail: sendBillEmail,
    getProfileCall: getProfileCall,
    updateOrderCall: updateOrderCall,
    updateWaiterCallingTableCall: updateWaiterCallingTableCall,
    removeItemFromOrderCall: removeItemFromOrderCall,
  };
}
