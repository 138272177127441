import { useEffect, useState } from "react";
import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import styles from "../../../styles/detailedBill.module.scss";
import NavBar from "../../../../src/components/navbar";
import BackArrow from "../../../../src/components/back_arrow";
import { getImageUrl, getInitialsByName } from "../../../utils/helpers";
import { format } from "date-fns";
import commaNumber from "comma-number";
import CustomerApiService from "../../../api_service/customer_api_service";
import { PaymentMethods } from "../../../utils/constants";

export default function BillInfoPage({ match }) {
  const { restaurant_id, table_id, order_id } = match.params;

  const getBillDetails = async () => {
    const response = await CustomerApiService().getOrderByIdSuccessPageCall(
      restaurant_id,
      table_id,
      order_id
    );
    if (response.success) {
      setData({
        order: response.data.order,
        restaurant: response.data.restaurant,
        table: response.data.table,
        customer: response?.data?.customer,
      });
    }
  };

  useEffect(() => {
    getBillDetails();
  }, []); //eslint-disable-line

  const [data, setData] = useState({
    order: null,
    restaurant: null,
    table: null,
    customer: null,
  });

  const paymentMethodsWithImage = {
    CASHIER: "/images/cash.png",
    PAYHERE: "/images/payhere_logo.png",
  };

  const getPaymentMethodImage = (method) => {
    return paymentMethodsWithImage[method];
  };

  return data.order ? (
    <>
      <NavBar
        userName={getInitialsByName(data.customer.name)}
        email={data.customer.email}
      />
      <div className={styles.container}>
        <div className={styles.BackArrowCont}>
          <BackArrow id="order-history-back"/>
        </div>
        <Grid container className="body" justify="center">
          <Grid item xs={12} className={styles.leftContainer}>
            <div className={styles.headerLogoContainer}>
              <img
                src={getImageUrl(data.restaurant.logo)}
                alt="restaurant logo"
                height={100}
              />
            </div>
          </Grid>
        </Grid>

        <Grid container className="body" justify="center">
          <Grid item xs={12}>
            <div className={styles.buttonAndCardWrapper}>
              <div className={styles.buttonContainer}>
                <div className={styles.line}></div>
                <div className={styles.detailsRow}>
                  <div
                    style={{
                      textAlign: "left",
                      textTransform: "capitalize",
                    }}
                  >
                    Table:{" " + data.table.number}
                  </div>
                  <div
                    style={{
                      fontSize: "11px",
                      color: "#5E587380",
                      textAlign: "right",
                      fontFamily: "Montserrat-Medium",
                      paddingTop: "3px",
                    }}
                  >
                    {format(Date.parse(data.order.createdAt), "d MMM yyyy ")}
                    <span className={styles.timeGap}></span>
                    {format(Date.parse(data.order.createdAt), " h.mm ")}
                    <span
                      style={{
                        display: "inline-block",
                        textTransform: "lowercase",
                        paddingLeft: "2px",
                      }}
                    >
                      {format(Date.parse(data.order.createdAt), "aaa")}
                    </span>
                  </div>
                </div>
              </div>

              <Card className={styles.card1}>
                <Grid container className={styles.billDataContainer}>
                  <Grid item xs={4} className={styles.leftContainer}>
                    Invoice:
                  </Grid>
                  <Grid item xs={8} className={styles.inleftCont}>
                    {data.order.id}
                  </Grid>
                  <Grid item xs={4} className={styles.rightContainer}>
                    Server:
                  </Grid>

                  <Grid item xs={8} className={styles.inRightCont}>
                    {data.order.waiter || "Unknown"}
                  </Grid>
                </Grid>
                <Table className={styles.table0}>
                  <TableBody className={styles.tableRow}>
                    <TableRow
                      style={{ width: "100%", height: "9px" }}
                    ></TableRow>
                    {data.order.order_items.map((item, index) => (
                      <TableRow key={index} onClick={() => {}}>
                        <TableCell
                          className={styles.tableRowCellBill}
                          align="left"
                        >
                          <div className={styles.tableBillItem}>
                            <div className={styles.itemQty}>
                              {item.quantity}x
                            </div>
                            <div className={styles.itemSpacer}>
                              {item.item.name}
                            </div>
                          </div>
                          {item.discount ? (
                            <div className={styles.billItemDis}>
                              {`Discount ${
                                item.discount_type === "PERCENTAGE"
                                  ? `${item.discount} %`
                                  : item.discount_type === "AMOUNT"
                                  ? `${commaNumber(item.discount.toFixed(2))} ${
                                      data.restaurant.currency
                                    }`
                                  : ""
                              } per item`}
                            </div>
                          ) : (
                            <div />
                          )}
                        </TableCell>
                        <TableCell
                          className={styles.tableRowCellRight}
                          align="right"
                          scope="row"
                        >
                          <div style={{ marginTop: "0" }}>
                            {commaNumber(
                              (
                                (item.unit_price + item.calculated_discount) *
                                item.quantity
                              ).toFixed(2)
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}

                    {data.order.total_item_level_discounts > 0 ? (
                      <TableRow>
                        <TableCell className={styles.totalItemLevelDiscounts}>
                          <div>Total item level discount</div>
                        </TableCell>
                        <TableCell className={styles.tableRowCellRightDiscount}>
                          <div>
                            {commaNumber(
                              (
                                data.order.total_item_level_discounts * -1
                              ).toFixed(2)
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <div />
                    )}
                    {data.order.order_discounts.map((discount, index) => (
                      <TableRow key={index} className={styles.billItemRowCont}>
                        <TableCell
                          className={styles.tableRowCellBill}
                          align="left"
                        >
                          <div className={styles.tableBillItem}>
                            <div className={styles.itemQty}>--</div>
                            <div className={styles.itemSpacer}>
                              {discount.charge.name}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className={styles.totalItemDiscountsCell}
                          align="right"
                          scope="row"
                        >
                          <div style={{ marginTop: "0", color: "#1dc369" }}>
                            {commaNumber(
                              (discount.calculated_value * -1).toFixed(2)
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                    {data.order.order_charges.map((charge, index) => (
                      <TableRow key={index} className={styles.billItemRowCont}>
                        <TableCell
                          className={styles.tableRowCellBill}
                          align="left"
                        >
                          <div className={styles.tableBillItem}>
                            <div className={styles.itemQty}>--</div>
                            <div className={styles.itemSpacer}>
                              {charge.charge.name}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className={styles.tableRowCellRight}
                          align="right"
                          scope="row"
                        >
                          <div style={{ marginTop: "0" }}>
                            {commaNumber(charge.calculated_value.toFixed(2))}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                    {data.order.tip_amount > 0 && (
                      <TableRow className={styles.billItemRowCont}>
                        <TableCell
                          className={styles.tableRowCellBill}
                          align="left"
                        >
                          <div className={styles.tableBillItem}>
                            <div className={styles.itemQty}>--</div>
                            <div className={styles.itemSpacer}>
                              {`Tip for ${data.order.waiter || "Server"}`}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className={styles.tableRowCellRight}
                          align="right"
                          scope="row"
                        >
                          <div style={{ marginTop: "0" }}>
                            {commaNumber(data.order.tip_amount.toFixed(2))}
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Card>
              <Card className={styles.card2}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid
                      container
                      alignItems="flex-start"
                      alignContent="flex-start"
                    >
                      <Grid item xs={12}></Grid>
                      <Table>
                        <TableBody>
                          {data.order.payment_id && (
                            <TableRow>
                              <TableCell
                                className={styles.tableRowCell2}
                                align="left"
                                style={{ borderBottom: "none" }}
                              >
                                Transaction ID
                              </TableCell>

                              <TableCell
                                className={styles.tableRowCellRight2}
                                align="right"
                                scope="row"
                                style={{
                                  color: "#5E5873",
                                  borderBottom: "none",
                                }}
                              >
                                {data.order.payment_id}
                              </TableCell>
                            </TableRow>
                          )}
                          {data.order.payment_method ===
                            PaymentMethods.PAYHERE && (
                            <TableRow>
                              <TableCell
                                className={styles.tableRowCell2}
                                align="left"
                                style={{ borderBottom: "none" }}
                              >
                                Order Id
                              </TableCell>

                              <TableCell
                                className={styles.tableRowCellRight2}
                                align="right"
                                scope="row"
                                style={{
                                  color: "#5E5873",
                                  borderBottom: "none",
                                }}
                              >
                                {data.order.payment_order_id}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>

                      <Table
                        className={styles.table}
                        style={{ marginTop: "10px" }}
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell
                              className={styles.tableRowCell2}
                              align="left"
                              style={{ color: "#5E5873B3", fontSize: "12px" }}
                            >
                              Payment Method
                            </TableCell>
                            <TableCell className={styles.midCell}></TableCell>
                            <TableCell
                              className={styles.tableRowCell2}
                              align="right"
                              scope="row"
                              style={{ borderBottom: "none" }}
                            >
                              <img
                                style={{
                                  backgroundColor: "white",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                                src={getPaymentMethodImage(
                                  data.order.payment_method
                                )}
                                alt={"payment method"}
                                width={50}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
              <div className={styles.totCont}>
                <Table>
                  <TableBody style={{ position: "relative" }}>
                    <TableRow className={styles.totalRow}>
                      <TableCell
                        className={styles.tableTotalAmountCell}
                        align="left"
                      >
                        Total
                      </TableCell>

                      <TableCell
                        className={styles.tableBottomCellRight}
                        align="right"
                        scope="row"
                      >
                        {`${commaNumber(
                          (
                            data.order.order_amount + data.order.tip_amount
                          ).toFixed(2)
                        )} ${data.restaurant.currency}`}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  ) : (
    <div />
  );
}
