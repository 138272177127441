import React from "react";
import styles from "../styles/restaurantLogo.module.scss";
import { getImageUrl } from "../utils/helpers";
const RestaurantLogo = ({ url }) => {
  return (
    <div className={styles.logoContainer}>
      <img src={getImageUrl(url)} alt="restaurant-logo" className={styles.logo} />
    </div>
  );
};

export default RestaurantLogo;
