import store from "../../store/store";

const storeActions = store.getActions();

export const showAlert = ({ head, body, actions, isCustomer }) => {
  storeActions.setAlert({
    open: true,
    head,
    body,
    actions: actions || [],
    isCustomer: isCustomer || false,
  });
};
