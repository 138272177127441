import { Card, Grid } from "@mui/material";
import { useStoreState } from "easy-peasy";
import React, { useState, useEffect } from "react";
import { getTableColor } from "../../utils/helpers";
import ZoomInAnimatedDiv from "../animated_div/zoom_in_animated_div";
import useStyles from "./table_item_styles";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

export default function TableItem({ table, onClick, index }) {
  const [isHover, setHover] = useState(false);
  const classes = useStyles();
  const adminView = useStoreState((state) => state.currentView === "ADMIN");

  const [showBell, setShowBell] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowBell((showBell) => !showBell);
    }, 300);
    return () => clearInterval(interval);
  }, []);

  return adminView || (!adminView && table.is_active) ? (
    <Grid style={{ backgroundColor: "transparent" }} item xs={3}>
      <ZoomInAnimatedDiv delay={index / 2} show={table.number}>
        <Card
          onClick={() => {
            onClick(table);
          }}
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          elevation={isHover ? 15 : 3}
          className={classes.item_outer}
          style={{
            backgroundColor: adminView ? "white" : getTableColor(table),
          }}
        >
          {table.is_waiter_calling && showBell && (
            <div className={classes.tableStatus}>
              <NotificationsActiveIcon color="error" fontSize="small" />
            </div>
          )}

          <div className={classes.name}>{table.number}</div>
        </Card>
      </ZoomInAnimatedDiv>
    </Grid>
  ) : (
    <div />
  );
}
