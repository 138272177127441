import React from "react";
import useStyles from "./edit_table_styles";
import QRCode from "qrcode.react";

export default function SNQrCodeDesign2({ qr, id }) {
  const classes = useStyles();

  const logoInsideQR = {
    src: "/images/ceypaysvglogo.svg",
    x: undefined,
    y: undefined,
    height: 60,
    width: 60,
    excavate: true,
  };
  return (
    <div id={id} className={classes.table_qr_outer_with_frame}>
      <div id={id}>
        <div className={classes.table_no_2}>Table : {qr.table_number}</div>
        <QRCode
          value={qr.url}
          size={260}
          level={"H"}
          className={classes.qr_deign_2}
          imageSettings={logoInsideQR}
        />
        <div className={classes.rest_name_2}>{qr.restaurant_name}</div>
        <span className={classes.code_2}>{`Code: ${qr.restaurant_code}`}</span>
      </div>
    </div>
  );
}
