import React from "react";
import useStyles from "./price_tag_styles";
const commaNumber = require("comma-number");

export default function PriceTag({ item, currency, qty }) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.price}>
        <span>{`${commaNumber(
          (item.discounted_price * qty).toFixed(2)
        )} ${currency}`}</span>{" "}
      </div>
    </div>
  );
}
