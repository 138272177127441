import React, { useEffect, useState } from "react";
import useStyles from "./payouts_page_styles";
import {
  Button,
  Collapse,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from "@mui/system";
import { addDays, format } from "date-fns";
import AdminApiService from "../../../api_service/admin_api_service";
import AdminAppBar from "../../../components/admin_app_bar/app_bar";
import commaNumber from "comma-number";
import PayoutsFilter from "../../../components/payouts_filter/payouts_filter";
import { PayoutsStartDate, PayoutStatuses } from "../../../utils/constants";
import { Download, Upload } from "@mui/icons-material";
import Button1 from "../../../components/button_1/button_1";
import InitiatePayout from "../../../components/initiate_payout/initiate_payout";
import PayoutPayModal from "../../../components/payout_pay/payout_pay";
import { getImageUrl } from "../../../utils/helpers";

export default function AdminPayoutsPage() {
  const classes = useStyles();
  const [state, setState] = useState({
    page: 1,
    limit: 10,
    data: [],
    totalPages: 1,
  });

  const [filter, setFilter] = useState({
    from: format(PayoutsStartDate, "yyyy-MM-dd"),
    to: format(addDays(new Date(), 1), "yyyy-MM-dd"),
    restaurant_id: -1,
    status: -1,
  });

  const [payModalState, setPayModalState] = useState({
    open: false,
    payout: null,
  });

  const [initPayoutOpen, setInitPayoutOpen] = useState(false);

  const getPayoutData = () => {
    AdminApiService()
      .getPayoutsList(
        {
          from_date: filter.from,
          to_date: filter.to,
          restaurant_id:
            filter.restaurant_id === -1 ? undefined : filter.restaurant_id,
          status: filter.status === -1 ? undefined : filter.status,
        },
        state.page,
        state.limit
      )
      .then((response) => {
        if (response.success) {
          setState({
            ...state,
            data: response.data.data,
            totalPages: response.data.total_pages,
          });
        }
      });
  };

  useEffect(() => {
    getPayoutData();
  }, [state.page, state.limit, filter]); // eslint-disable-line

  const onFilterChanged = (key, value) => {
    setFilter({ ...filter, [key]: value });
  };

  const onClosePayModal = () => {
    setPayModalState({ ...payModalState, open: false });
  };

  const openPayModal = (payout) => {
    setPayModalState({ ...payModalState, open: true, payout });
  };
  return (
    <div className={classes.root}>
      <AdminAppBar title={"Payouts"} />
      <PayoutsFilter
        restaurant
        onFilterChanged={onFilterChanged}
        filter={filter}
      />

      <div className={classes.main_row}>
        <Button1
          className={classes.add_btn}
          title={"Initiate new payout"}
          isSelected
          onClick={() => {
            setInitPayoutOpen(true);
          }}
        />
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell style={{ minWidth: 100 }} align="center">
                  Payout Id
                </TableCell>
                <TableCell style={{ minWidth: 120 }} align="left">
                  From
                </TableCell>
                <TableCell style={{ minWidth: 120 }} align="left">
                  To
                </TableCell>
                <TableCell style={{ minWidth: 130 }} align="left">
                  Restaurant
                </TableCell>
                <TableCell style={{ minWidth: 90 }} align="center">
                  Currency
                </TableCell>
                <TableCell style={{ minWidth: 110 }} align="right">
                  Total Sales
                </TableCell>
                <TableCell style={{ minWidth: 160 }} align="center">
                  Fee Percentage (%)
                </TableCell>
                <TableCell style={{ minWidth: 130 }} align="right">
                  Restaurant Fee
                </TableCell>
                <TableCell style={{ minWidth: 140 }} align="right">
                  Application Fee
                </TableCell>
                <TableCell style={{ minWidth: 130 }} align="center">
                  Payout Status
                </TableCell>
                <TableCell style={{ minWidth: 100 }} align="center">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.data.map((payout) => (
                <Row
                  key={payout.id}
                  payout={payout}
                  openPayModal={openPayModal}
                />
              ))}
            </TableBody>
          </Table>
          <div className={classes.paginator_outer}>
            {state.totalPages > 1 && (
              <Pagination
                count={state.totalPages}
                page={state.page}
                onChange={(event, newPage) => {
                  setState({ ...state, page: newPage });
                }}
              />
            )}
          </div>
        </TableContainer>
      </div>
      <InitiatePayout
        open={initPayoutOpen}
        setOpen={setInitPayoutOpen}
        onRefresh={getPayoutData}
      />
      <PayoutPayModal
        open={payModalState.open}
        payout={payModalState.payout}
        onRefresh={getPayoutData}
        onClose={onClosePayModal}
      />
    </div>
  );
}

function Row({ payout, openPayModal }) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand payout"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{payout.id}</TableCell>
        <TableCell component="th" scope="order">
          {format(new Date(payout.from_date), "yyyy MMM d")}
        </TableCell>
        <TableCell component="th" scope="order">
          {format(new Date(payout.to_date), "yyyy MMM d")}
        </TableCell>
        <TableCell align="left">{payout.restaurant.name}</TableCell>
        <TableCell align="center">{payout.currency}</TableCell>
        <TableCell align="right">
          {commaNumber(payout.total_amount.toFixed(2))}
        </TableCell>
        <TableCell align="center">{payout.app_fee_percentage}</TableCell>
        <TableCell align="right">
          {commaNumber(payout.restaurant_fee.toFixed(2))}
        </TableCell>
        <TableCell align="right">
          {commaNumber(payout.app_fee.toFixed(2))}
        </TableCell>
        <TableCell
          align="center"
          style={{
            color: payout.status === "PAID" ? "green" : "#2b7ab3",
            fontWeight: "bold",
          }}
        >
          {payout.status}
        </TableCell>

        <TableCell align="center">
          {payout.status === PayoutStatuses.PAID && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                window.open(getImageUrl(payout.receipt_url), "_blank");
              }}
            >
              <Tooltip title="Download payment receipt">
                <Download />
              </Tooltip>
            </Button>
          )}
          {payout.status === PayoutStatuses.INITIALIZED && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                openPayModal(payout);
              }}
            >
              <Tooltip title="Upload payment receipt">
                <Upload />
              </Tooltip>
            </Button>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {`Orders included in the payout`}
              </Typography>
              <Box height={20} />
              <DetailRow
                title={"Initiated date"}
                value={format(
                  new Date(payout.createdAt),
                  "yyyy MMM d - hh:mm aaa"
                )}
              />
              <DetailRow
                title={"Payment date"}
                value={
                  payout.payment_update_date
                    ? format(
                        new Date(payout.payment_update_date),
                        "yyyy MMM d - hh:mm aaa"
                      )
                    : "--"
                }
              />
              <DetailRow
                title={"Transfer Amount"}
                value={
                  <span style={{ color: "green" }}>
                    {commaNumber(payout.restaurant_fee.toFixed(2))}
                  </span>
                }
              />
              <DetailRow
                title={"Bank Details"}
                value={payout.bank_account_details?.split(",").map((b) => (
                  <>
                    <span>{b}</span>
                    <br />
                  </>
                ))}
              />
              {payout.comment && (
                <DetailRow title={"Comment"} value={payout.comment} />
              )}

              <Box height={20} />

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Payment Order Id</TableCell>
                    <TableCell align="left">Customer</TableCell>
                    <TableCell align="left">Server</TableCell>
                    <TableCell align="center">Payment Method</TableCell>
                    <TableCell align="right">Order Amount</TableCell>
                    <TableCell align="right">Tip Amount</TableCell>
                    <TableCell align="right">Total Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payout.orders.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell align="left">
                        {order.payment_order_id}
                      </TableCell>
                      <TableCell align="left">
                        {order.customer_name || "---"}
                      </TableCell>
                      <TableCell align="left">
                        {order.waiter || "---"}
                      </TableCell>
                      <TableCell align="center">
                        {order.payment_method}
                      </TableCell>
                      <TableCell align="right">
                        {commaNumber(order.order_amount.toFixed(2))}
                      </TableCell>
                      <TableCell align="right">
                        {commaNumber(order.tip_amount.toFixed(2))}
                      </TableCell>
                      <TableCell align="right">
                        {commaNumber(
                          (order.order_amount + order.tip_amount).toFixed(2)
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const DetailRow = ({ title, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.detail_row}>
      <div className={classes.detail_label}>{title}</div>
      <div className={classes.detail_value}>{value}</div>
    </div>
  );
};
