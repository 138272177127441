import { makeStyles } from "@mui/styles";
import { ceypay_logo_blue_color, primary_color_1 } from "../../themes/colors";

const useStyles = makeStyles({
  outer: {
    width: "100px",
    height: "100px",
    margin: "10px auto",
    textAlign: "center",
    cursor: "pointer",
    border: `2px solid white`,
    opacity: 0.8,
    transition: "0.5s",
    borderRadius: "60px",
  },
  outer_hover: {
    opacity: 1,
    border: `2px solid ${primary_color_1}`,
  },
  item_category: {
    height: "100px",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "3px",
    alignItems: "center",
    display: "flex",
    flexDirection: "column-reverse",
    justifyContent: "end",
    borderRadius: "60px",
  },
  category_name: {
    backgroundColor: "white",
    padding: "4px",
    borderRadius: "6px",
    fontSize: "12px",
    color: ceypay_logo_blue_color,
    fontWeight: 300,
    maxWidth: "110px",
    maxHeight: "40px",
    overflow: "hidden",
  },
});

export default useStyles;
