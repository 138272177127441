import styles from "../styles/footer.module.scss";

export default function Footer() {
  return (
    <div className={styles.container}>
      <div className={styles.footerLabel}>Powered by</div>
      <img height={"20px"} src="/images/logo1-white.png" alt="ceypay" />
    </div>
  );
}
