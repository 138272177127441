import { Numbers, Person, PushPin } from "@mui/icons-material";
import { Card, Grid } from "@mui/material";
import commaNumber from "comma-number";
import { useStoreState } from "easy-peasy";
import React, { useState } from "react";
import ZoomInAnimatedDiv from "../animated_div/zoom_in_animated_div";
import useStyles from "./order_card_styles";

export default function OrderCard({ order, onClick, index }) {
  const [isHover, setHover] = useState(false);
  const classes = useStyles();
  const { currency } = useStoreState((state) => state.restaurant);
  return (
    <Grid style={{ backgroundColor: "transparent" }} item xs={12}>
      <ZoomInAnimatedDiv delay={index / 2} show={order.id}>
        <Card
          onClick={() => {
            onClick(order);
          }}
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          elevation={isHover ? 15 : 3}
          className={classes.item_outer}
        >
          <div className={classes.icon}>
            <img
              height={50}
              src={
                order.created_by_customer
                  ? "/images/mobile.png"
                  : "/images/pos.png"
              }
              alt="mobile or pos"
            />
          </div>
          <div className={classes.names}>
            <div className={classes.name_row}>
              <Numbers />
              <span className={classes.order_id}>{order.id}</span>
            </div>
            <div className={classes.name_row}>
              <Person />
              <span className={classes.customer_name}>
                {order.customer_name || order.customer?.name || "Unknown"}
              </span>
            </div>
            {order.pinned && (
              <div className={classes.name_row}>
                <PushPin />
                <span className={classes.customer_name}>order is pinned</span>
              </div>
            )}
          </div>
          <div className={classes.amount}>{`${commaNumber(
            order.order_amount.toFixed(2)
          )} ${currency}`}</div>
        </Card>
      </ZoomInAnimatedDiv>
    </Grid>
  );
}
