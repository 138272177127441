import { Backdrop } from "@mui/material";
import React from "react";
import useStyles from "./loader_styles";
import { Hypnosis } from "react-cssfx-loading";
import { useStoreState } from "easy-peasy";
import store from "../../store/store";

const actions = store.getActions();

export default function Loader() {
  const classes = useStyles();
  const loading = useStoreState((state) => state.loading);
  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <div className={classes.loader_outer}>
        <Hypnosis color="#fbaf41" width="50px" height="50px" duration="3s" />
      </div>
    </Backdrop>
  );
}

export const setLoading = (state) => {
  actions.setLoading(state);
};
