import { Grid } from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";
import React from "react";
import CustomerApiService from "../../api_service/customer_api_service";
import RestaurantApiService from "../../api_service/restaurant_api_service";
import Button1 from "../button_1/button_1";
import TableItem from "../table_item/table_item";

export default function TablesTab() {
  const tables = useStoreState((state) => state.tables);
  const setOpenEditTable = useStoreActions(
    (actions) => actions.setOpenEditTable
  );
  const setOpenDownloadAllTables = useStoreActions(
    (actions) => actions.setOpenDownloadAllTables
  );
  const setTableToEdit = useStoreActions((actions) => actions.setTableToEdit);
  const setSelectedTable = useStoreActions(
    (actions) => actions.setSelectedTable
  );
  const setSelectedTab = useStoreActions((actions) => actions.setSelectedTab);
  const changeTableUpdatedStatus = useStoreActions(
    (actions) => actions.changeTableUpdatedStatus
  );
  const setSelectedOrder = useStoreActions(
    (actions) => actions.setSelectedOrder
  );
  const adminView = useStoreState((state) => state.currentView === "ADMIN");

  const onAddNewItem = () => {
    setTableToEdit(null);
    setOpenEditTable(true);
  };

  const onDownloadAll = () => {
    setOpenDownloadAllTables(true);
  };

  const onUpdateTable = (table) => {
    setTableToEdit(table);
    setOpenEditTable(true);
  };

  const onClickTable = async (table) => {
    setSelectedTable(table);
    changeTableUpdatedStatus({ id: table.id, status: false });

    if (table.orders.length === 1) {
      RestaurantApiService()
        .getRestaurantOrderByIdCall(table.orders[0].id, true)
        .then((response) => {
          setSelectedOrder(response.data);
          setSelectedTab("order_detail");
        });
    } else {
      setSelectedTab("orders");
    }

    if (table.is_waiter_calling) {
      await CustomerApiService().updateWaiterCallingTableCall(
        {
          is_waiter_calling: false,
        },
        table.id
      );
    }
  };

  return (
    <div style={{ paddingBottom: "40px" }}>
      {adminView && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button1 onClick={onAddNewItem} title="Add New Table" />
          <Button1 onClick={onDownloadAll} title="Download All" />
        </div>
      )}
      <Grid container spacing={1}>
        {tables.map((item, index) => (
          <TableItem
            key={index}
            table={item}
            index={index}
            onClick={adminView ? onUpdateTable : onClickTable}
          />
        ))}
      </Grid>
    </div>
  );
}
