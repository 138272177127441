import { Backdrop, Box, Card, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./edit_item_styles";
import { useStoreActions, useStoreState } from "easy-peasy";
import Button1 from "../button_1/button_1";
import ImagePicker from "../image_picker/image_picker";
import RestaurantApiService from "../../api_service/restaurant_api_service";
import ModelHeader from "../model_header/model_header";
import EditModifiers from "../edit_modifiers/edit_modifiers";
import { showAlert } from "../message_box/show_alert";

export default function EditItem() {
  const classes = useStyles();
  const openEditItem = useStoreState((state) => state.openEditItem);
  const itemToEdit = useStoreState((state) => state.itemToEdit);
  const selectedItemCategory = useStoreState(
    (state) => state.selectedItemCategory
  );
  const setOpenEditItem = useStoreActions((actions) => actions.setOpenEditItem);

  const [state, setState] = useState({
    image: null,
    image_url: itemToEdit?.image || "",
    name: itemToEdit?.name || "",
    description: itemToEdit?.description || "",
    unit_price: itemToEdit?.unit_price || "",
    discount_type: itemToEdit?.discount_type || "AMOUNT",
    discount: itemToEdit?.discount || 0,
    order_id: itemToEdit?.order_id || undefined,
    modifiers: itemToEdit?.modifiers || [],
  });
  const changeState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const setInitialData = () => {
    setState({
      ...state,
      image: null,
      image_url: itemToEdit?.image || "",
      name: itemToEdit?.name || "",
      description: itemToEdit?.description || "",
      unit_price: itemToEdit?.unit_price || "",
      discount_type: itemToEdit?.discount_type || "AMOUNT",
      discount: itemToEdit?.discount || 0,
      order_id: itemToEdit?.order_id || undefined,
      modifiers: itemToEdit?.modifiers || [],
    });
  };
  useEffect(() => {
    if (openEditItem) {
      setInitialData();
    }
  }, [openEditItem]); // eslint-disable-line

  const onClose = () => {
    setOpenEditItem(false);
  };

  const setFile = (file) => {
    changeState("image", file);
  };

  const onSubmit = () => {
    const formData = new FormData();
    if (state.image) {
      formData.append("file", state.image);
    }
    if (!state.name) {
      return showAlert({
        head: "Please be careful!",
        body: "Please enter the name of your 'Item'",
      });
    }
    formData.append("name", state.name);
    if (state.description || state.description === "") {
      formData.append("description", state.description);
    }

    if (!state.unit_price) {
      return showAlert({
        head: "Please be careful!",
        body: "Please enter the amount of your 'Item'",
      });
    }
    formData.append("unit_price", state.unit_price);
    formData.append("discount_type", state.discount_type);

    if (state.discount === "" || state.discount === null) {
      return showAlert({
        head: "Please be careful!",
        body: "Please enter the discount of your 'Item'",
      });
    }
    formData.append("discount", state.discount);
    if (itemToEdit && state.order_id) {
      formData.append("order_id", state.order_id);
    }

    formData.append("modifiers", JSON.stringify(state.modifiers || []));

    if (itemToEdit) {
      RestaurantApiService()
        .updateItemCall(formData, itemToEdit.id)
        .then((response) => {
          if (response.success) {
            onClose();
          }
        });
    } else {
      formData.append("item_category_id", selectedItemCategory.id);
      RestaurantApiService()
        .createItemCall(formData)
        .then((response) => {
          if (response.success) {
            onClose();
          }
        });
    }
  };
  return (
    <Backdrop className={classes.backdrop} open={openEditItem}>
      <Card className={classes.card}>
        <ModelHeader
          title={itemToEdit ? "Update Item" : "Add New Item"}
          onClose={onClose}
        />
        <div className={classes.card_body}>
          <Box height={10} />
          <ImagePicker
            imageUrl={state.image_url}
            setFile={setFile}
            file={state.image}
            title={"Item Image"}
          />
          <Box height={40} />
          <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
            <TextField
              className={classes.text_field}
              label="Please enter 'Item Name'"
              value={state.name}
              onChange={(event) => {
                changeState("name", event.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            />
            {itemToEdit && (
              <>
                <TextField
                  className={classes.text_field}
                  label="Order Id"
                  value={state.order_id}
                  onChange={(event) => {
                    changeState("order_id", event.target.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  style={{ width: 80 }}
                />
              </>
            )}
          </div>

          <Box height={20} />
          <TextField
            className={classes.text_field}
            label="Please enter 'Item Description'"
            value={state.description}
            onChange={(event) => {
              changeState("description", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
          <Box height={20} />

          <TextField
            variant="filled"
            type="number"
            className={classes.text_field}
            style={{ width: "30%" }}
            label="Enter 'Unit Price'"
            value={state.unit_price}
            onChange={(event) => {
              changeState("unit_price", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            variant="filled"
            className={classes.text_field}
            style={{ width: "35%", marginLeft: "3%" }}
            label="Select 'Discount Type'"
            value={state.discount_type}
            onChange={(event) => {
              changeState("discount_type", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            select
          >
            <MenuItem value={"AMOUNT"}>Amount</MenuItem>
            <MenuItem value={"PERCENTAGE"}>Percentage</MenuItem>
          </TextField>
          <TextField
            variant="filled"
            type="number"
            className={classes.text_field}
            style={{ width: "29%", marginLeft: "3%" }}
            label="Enter 'Discount'"
            value={state.discount}
            onChange={(event) => {
              changeState("discount", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />

          <Box height={20} />
          <EditModifiers
            modifiers={state.modifiers}
            setModifiers={(newModifiers) =>
              changeState("modifiers", newModifiers)
            }
          />
          <Box height={20} />
          <div className={classes.button_row}>
            <Button1 onClick={onSubmit} isSelected title="Submit" />
          </div>
        </div>
      </Card>
    </Backdrop>
  );
}
