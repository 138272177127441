import { makeStyles } from "@mui/styles";
import { primary_gradient_reverse } from "../../themes/colors";

const useStyles = makeStyles({
  backdrop: {
    zIndex: 10,
    color: "#fff",
    overflow: "auto",
    paddingBottom: "100px",
  },
  card: { width: "600px", marginTop: "100px", marginLeft:10, marginRight:10 },
  card_header: {
    backgroundImage: primary_gradient_reverse,
    color: "white",
    fontWeight: "bold",
    fontSize: "15px",
    padding: "10px",
  },
  text_field: { width: "100%" },
  button_row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
  },
  filter_results_outer: {
    width: "100%",
    padding: "20px",
    border: "1px dashed #c3c3c3",
    borderRadius: "10px",
    textAlign: "center",
  },
  filter_row: { display: "flex", flexDirection: "row", gap: "10px" },
  filter_col: { width: "100%", textAlign: "left" },
  filter_item_row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#efefefd9",
    marginBottom: "3px",
    padding: "1px 5px",
  },
  restaurant_fee: {
    color: "green",
    fontWeight: "bold",
  },
  account_detail: {
    marginLeft: "20px",
  },
});

export default useStyles;
