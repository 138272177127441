import { Card } from "@mui/material";
import React, { useEffect } from "react";
import useStyles from "./login_page_styles";
import FadeInUpAnimationDiv from "../../../components/animated_div/fade_in_up_animated_div";
import FadeInAnimatedDiv from "../../../components/animated_div/fade_in_animated_div";
import useWindowSize from "../../../utils/use_window_size";
import clsx from "clsx";
import AdminLoginForm from "./login_form";
import AdminApiService from "../../../api_service/admin_api_service";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function AdminLoginPage() {
  const classes = useStyles();
  const { isTab } = useWindowSize();
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("sn_rest_admin_access_token")) {
      AdminApiService()
        .getConfig()
        .then((response) => {
          if (response?.success) {
            history.replace("/admin/home");
          } else {
            localStorage.removeItem("sn_rest_admin_access_token");
          }
        });
    }
  }, []); //eslint-disable-line

  return (
    <div className={classes.root}>
      <FadeInAnimatedDiv>
        <Card className={classes.login_card} elevation={20}>
          {!isTab && <div className={classes.left_card} />} 
          <div
            className={clsx(
              classes.right_card,
              isTab && classes.right_card_mobile
            )}
          >
            <FadeInUpAnimationDiv show={true}>
              <div className={classes.login_scroll_outer}>
                <div className={classes.welcome_text}>Admin Panel</div>
                <div>Please login to Super Admin Account!</div>
                <AdminLoginForm />
              </div>
            </FadeInUpAnimationDiv>
          </div>
        </Card>
      </FadeInAnimatedDiv>
    </div>
  );
}
