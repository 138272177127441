import axios from "axios";
import { getClientTimeZone } from "../utils/get_time_zone";
import RestaurantApiService from "./restaurant_api_service";

const handleApiCall = async (method, url, body) => {
  try {
    const response = await axios({
      baseURL: "http://localhost:5001",
      url: url,
      timeout: 60000,
      method: method,
      data: body,
      headers: {
        "Content-Type": "application/json",
        "Client-Timezone": getClientTimeZone()
      },
    });
    return response.data;
  } catch (error) {}
};

const receiptPrintCall = async (orderOrId) => {
  if (typeof orderOrId !== "object") {
    const orderResponse =
      await RestaurantApiService().getRestaurantOrderByIdCall(orderOrId);
    orderOrId = orderResponse.data;
  }
  const response = await handleApiCall("POST", "/print", {
    order: orderOrId,
  });
  return response;
};

const printerHealthCheck = async () => {
  const response = await handleApiCall("GET", "/health");
  return response;
};

export default function LocalApiService() {
  return {
    receiptPrintCall: receiptPrintCall,
    printerHealthCheck: printerHealthCheck,
  };
}
