import { Box, FormControl, TextField } from "@mui/material";
import React, { useState } from "react";
import Button1 from "../../../components/button_1/button_1";
import useStyles from "./login_page_styles";
import TermsAndConditions from "../../../components/terms_and_conditions/terms_and_conditions";
import { useHistory } from "react-router-dom";
import RestaurantApiService from "../../../api_service/restaurant_api_service";
import { showAlert } from "../../../components/message_box/show_alert";

export default function LoginForm() {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    userName: "",
    password: "",
    confirmPassword: "",
    loading: false,
    setPassword: false,
    restaurantToken: "",
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const loginAction = () => {
    if (!state.userName) {
      return showAlert({
        head: "Please Be Careful!",
        body: "'User Name' is required to login.",
      });
    }
    changeState("loading", true);
    RestaurantApiService()
      .loginCall(state.userName, state.password)
      .then((response) => {
        changeState("loading", false);
        if (response.success) {
          history.replace("/restaurant/home");
        } else if (response.data && response.data.first_login) {
          setState({
            ...state,
            setPassword: true,
            password: "",
            restaurantToken: response.data.restaurant_token,
          });
          showAlert({ head: "", body: response.message });
        } else {
          if (!response.alert_showed) {
            return showAlert({ head: "", body: response.message });
          }
        }
      });
  };

  const setPasswordAction = () => {
    if (!state.password) {
      return showAlert({
        head: "Please Be Careful!",
        body: "'Password' is required.",
      });
    }
    if (!state.confirmPassword) {
      return showAlert({
        head: "Please Be Careful!",
        body: "'Confirm Password' is required.",
      });
    }
    if (state.password !== state.confirmPassword) {
      return showAlert({
        head: "Please Be Careful!",
        body: "Password you entered is not matched with the Confirm Password. Please try again",
      });
    }
    changeState("loading", true);
    RestaurantApiService()
      .setPasswordCall(state.restaurantToken, state.password)
      .then((response) => {
        changeState("loading", false);
        if (response.success) {
          history.replace("/restaurant/home");
        } else {
          return showAlert({ head: "", body: response.message });
        }
      });
  };

  return (
    <div
      style={{
        backgroundColor: state.setPassword ? "#fff" : "#fff",
        padding: "10px",
        borderRadius: "10px",
      }}
    >
      {state.setPassword ? (
        <div style={{ textAlign: "center" }}>
          Please create a new password for your account!
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>Please login to your account!</div>
      )}
      <div className={classes.login_form_outer}>
        <Box height={20} />
        {state.setPassword ? (
          <FormControl fullWidth>
            <TextField
              className={classes.text_field}
              type="password"
              label="New Password"
              InputLabelProps={{ shrink: true }}
              value={state.password}
              onChange={(event) => {
                changeState("password", event.target.value);
              }}
            />
            <Box height={15} />
            <TextField
              className={classes.text_field}
              type="password"
              label="Confirm Password"
              InputLabelProps={{ shrink: true }}
              value={state.confirmPassword}
              onChange={(event) => {
                changeState("confirmPassword", event.target.value);
              }}
            />
            <Box height={20} />
            <div className={classes.login_btn_outer}>
              <Button1
                loading={state.loading}
                onClick={setPasswordAction}
                title={"Login"}
                isSelected
                width={150}
              />
            </div>
            <TermsAndConditions />
          </FormControl>
        ) : (
          <FormControl fullWidth>
            <TextField
              className={classes.text_field}
              type="text"
              label="Please enter your User Name"
              InputLabelProps={{ shrink: true }}
              value={state.userName}
              onChange={(event) => {
                changeState("userName", event.target.value.toLowerCase());
              }}
            />
            <Box height={15} />
            <TextField
              className={classes.text_field}
              type="password"
              label="Please enter your Password"
              InputLabelProps={{ shrink: true }}
              value={state.password}
              onChange={(event) => {
                changeState("password", event.target.value);
              }}
            />
            <Box height={20} />
            <div className={classes.login_btn_outer}>
              <Button1
                loading={state.loading}
                onClick={loginAction}
                title={"Login"}
                isSelected
                width={150}
              />
            </div>
            <TermsAndConditions />
          </FormControl>
        )}
      </div>
    </div>
  );
}
