import React from "react";
import styles from "../styles/ceypayLogo.module.scss";
const CeypayLogo = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.mainContainer}>
        <img src="/images/logo.png" alt="logo" height="30" />
      </div>
    </div>
  );
};

export default CeypayLogo;
