import { makeStyles } from "@mui/styles";
import { ceypay_logo_blue_color, model_header_color } from "../../themes/colors";

const useStyles = makeStyles({
  card_header: {
    backgroundColor: model_header_color,
    color: "white",
    fontWeight: "bold",
    fontSize: "15px",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  card_header_cusomer: {
    backgroundColor: ceypay_logo_blue_color,
    color: "white",
    fontWeight: "bold",
    fontSize: "15px",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  close_btn: {
    backgroundColor: "white !important",
    height: "23px !important",
    width: "23px !important",
    padding: "0px !important",
    borderRadius: "5px !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    color: "red !important",
    fontSize: "17px !important",
    "&:hover": {
      backgroundColor: "black !important",
      color: "white !important",
    },
  },
});

export default useStyles;
