import { makeStyles } from "@mui/styles";
import { background_color_2 } from "../../../themes/colors";

const useStyles = makeStyles({
  root: {
    height: "100vh",
    width: "100%",
    backgroundColor: background_color_2,
    padding: "20px",
    overflow: "auto",
  },
  main_row: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    zIndex: 4,
  },
  paginator_outer: {
    width: "100%",
    justifyContent: "right",
    display: "flex",
    margin: "20px 20px 20px 0",
  },
  orders_in_payout_label: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    alignItems: "center",
    margin: "20px 0px",
  },
  add_btn: {
    width: "fit-content",
    alignSelf: "end",
  },
  detail_row: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    marginBottom:'5px'
  },
  detail_label: {
    width: "110px",
    fontWeight: "bold",
    color: "gray",
  },
  detail_value: {
    fontWeight: "bold",
  },
});

export default useStyles;
