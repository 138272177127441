import React from "react";
import Footer from "../footer/footer";
import Header from "../header/header";
import useStyles from "./refund_policy_styles";

export default function RefundPolicy() {
  const classes = useStyles();
  const data = [
    {
      header: "Refund Eligibility",
      content: [
        "Depending on the customer requests, Restaurant administration can request a refund within 10 days of the payment date.",
      ],
    },
    {
      header: "Refund Request Process",
      content: [
        "All refund requests must be made in writing and sent to CeyPay at ceyposlk@gmail.com.",
        "If your request is approved, we will initiate a refund to your original method of payment.",
      ],
    },
    {
      header: "Processing Time",
      content: [
        "Refunds will be processed within 7-10 business days after the approval of the request.",
      ],
    },
    {
      header: "Contact Information",
      content: ["For refund inquiries, contact CeyPay at ceyposlk@gmail.com."],
    },
  ];

  return (
    <div>
      <Header />
      <div className={classes.container}>
        <h1 className={classes.heading}>Refund Policy</h1>
        <p>
          At CeyPay, We value your satisfaction and strive to provide you with
          the best online ordering and payment experience possible.
        </p>
        <ol className={classes.list}>
          {data.map((term, index) => (
            <li key={index} className={classes.listItem}>
              <strong>{term.header}</strong>
              <ul>
                {term.content.map((content, index) => (
                  <li key={index}>{content}</li>
                ))}
              </ul>
            </li>
          ))}
        </ol>
      </div>
      <Footer />
    </div>
  );
}
