import React, { useEffect } from "react";
import useStyles from "./home_page_styles";
import {
  Badge,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import AdminAppBar from "../../../components/admin_app_bar/app_bar";
import Button1 from "../../../components/button_1/button_1";
import AdminApiService from "../../../api_service/admin_api_service";
import EditRestaurant from "../../../components/edit_restaurant/edit_restaurant";
import { useStoreActions, useStoreState } from "easy-peasy";
import {
  CardMembership,
  Delete,
  Edit,
  RestoreFromTrash,
} from "@mui/icons-material";
import { getImageUrl } from "../../../utils/helpers";
import { useHistory } from "react-router-dom";
import commaNumber from "comma-number";
import { format } from "date-fns";

export default function AdminHomePage() {
  const classes = useStyles();
  const adminRestaurants = useStoreState((state) => state.adminRestaurants);
  const setOpenRestaurantEditModal = useStoreActions(
    (actions) => actions.setOpenRestaurantEditModal
  );
  const setRestaurantToEdit = useStoreActions(
    (actions) => actions.setRestaurantToEdit
  );

  useEffect(() => {
    AdminApiService().getAllRestaurantsCall();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.right_bar}>
        <AdminAppBar title={"Restaurants"} />
        <div className={classes.main_row}>
          <Button1
            className={classes.add_btn}
            title={"Add New Restaurant"}
            isSelected
            onClick={() => {
              setRestaurantToEdit(null);
              setOpenRestaurantEditModal(true);
            }}
          />
          <RestaurantsTable restaurants={adminRestaurants} />
        </div>
      </div>
      <EditRestaurant />
    </div>
  );
}

function RestaurantsTable({ restaurants }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Logo</TableCell>
            <TableCell align="left" style={{ minWidth: 150 }}>
              Name
            </TableCell>
            <TableCell align="left">Code</TableCell>
            <TableCell align="left" style={{ minWidth: 200 }}>
              Address
            </TableCell>
            <TableCell align="left" style={{ minWidth: 150 }}>
              Subscription Fee
            </TableCell>
            <TableCell align="left" style={{ minWidth: 150 }}>Subscribed Until</TableCell>
            <TableCell align="center" style={{ minWidth: 160 }}>Subscription Status</TableCell>
            <TableCell align="center" style={{ minWidth: 170 }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {restaurants.map((restaurant) => (
            <Row key={restaurant.id} restaurant={restaurant} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function Row({ restaurant }) {
  const history = useHistory();
  const setOpenRestaurantEditModal = useStoreActions(
    (actions) => actions.setOpenRestaurantEditModal
  );
  const showEditRestaurant = async () => {
    await AdminApiService().getSpecificRestaurantCall(restaurant.id);
    setOpenRestaurantEditModal(true);
  };

  const toggleStatus = async () => {
    await AdminApiService().updateRestaurantStatusCall(
      {
        status: !restaurant.is_active,
      },
      restaurant.id
    );
    AdminApiService().getAllRestaurantsCall();
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="center">
          {restaurant.logo ? (
            <img src={getImageUrl(restaurant.logo)} alt={"logo"} height={50} />
          ) : (
            <img src="/images/sample_logo_rest.png" alt={"logo"} height={50} />
          )}
        </TableCell>
        <TableCell align="left">{restaurant.name}</TableCell>
        <TableCell align="left">{restaurant.code}</TableCell>
        <TableCell align="left">{restaurant.address}</TableCell>
        <TableCell align="left">
          {commaNumber(Number(restaurant.subscription_fee).toFixed(2))}
        </TableCell>
        <TableCell align="left">
          {restaurant.subscribed_until
            ? format(new Date(restaurant.subscribed_until), "do MMM yyyy")
            : "--"}
        </TableCell>
        <TableCell
          align="center"
          style={{
            fontWeight: "bold",
            color:
              restaurant.subscription_status === "ACTIVE"
                ? "green"
                : restaurant.subscription_status === "EXPIRE_SOON"
                ? "orange"
                : restaurant.subscription_status === "EXPIRED"
                ? "red"
                : "gray",
          }}
        >
          {restaurant.subscription_status}
        </TableCell>
        <TableCell align="center">
          {restaurant.is_active && (
            <Tooltip title="Edit or View Restaurant">
              <IconButton onClick={showEditRestaurant}>
                <Edit color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {restaurant.is_active ? (
            <Tooltip title="Deactivate Restaurant">
              <IconButton onClick={toggleStatus}>
                <Delete color="error" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Activate Restaurant">
              <IconButton onClick={toggleStatus}>
                <RestoreFromTrash color="success" />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Go to subscriptions">
            <Badge
              badgeContent={restaurant.has_pending_subscriptions ? 1 : null}
              color="error"
            >
              <IconButton
                onClick={() => {
                  history.push(`/admin/subscriptions/${restaurant.id}`);
                }}
              >
                <CardMembership color="success" />
              </IconButton>
            </Badge>
          </Tooltip>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
