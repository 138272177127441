import React from "react";
import useStyles from "./modifiers_styles";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { ModifierTypes } from "../../utils/constants";

export default function Modifiers({ modifiers, setModifiers }) {
  const classes = useStyles();
  const setModifierSelected = (index, value) => {
    modifiers[index].selected = value;
    setModifiers([...modifiers]);
  };
  return (
    modifiers && (
      <div className={classes.modifiers_list}>
        {modifiers.map((modifier, index) => (
          <ModifierItem
            key={index}
            modifier={modifier}
            setModifierSelected={setModifierSelected}
            index={index}
          />
        ))}
      </div>
    )
  );
}

function ModifierItem({ modifier, setModifierSelected, index }) {
  return (
    <div>
      {modifier.type === ModifierTypes.SINGLE_CHOICE ? (
        <FormControl>
          <FormLabel>
            {modifier.name}
          </FormLabel>
          <RadioGroup
            defaultValue={modifier.values[0]}
            value={modifier.selected}
            onChange={(event, value) => {
              setModifierSelected(index, [value]);
            }}
            style={{ display: "block" }}
          >
            {modifier.values.map((modValue, index) => (
              <FormControlLabel
                key={index}
                value={modValue}
                control={<Radio />}
                label={modValue}
              />
            ))}
          </RadioGroup>
        </FormControl>
      ) : (
        <FormControl component="fieldset" variant="standard" value>
          <FormLabel component="legend">{modifier.name}</FormLabel>
          <FormGroup style={{ display: "block" }}>
            {modifier.values.map((modValue, valueIndex) => (
              <FormControlLabel
                key={valueIndex}
                control={<Checkbox />}
                label={modValue}
                checked={modifier.selected.includes(modValue)}
                onChange={() => {
                  if (modifier.selected.includes(modValue)) {
                    const ind = modifier.selected.findIndex(
                      (m) => m === modValue
                    );
                    modifier.selected.splice(ind, 1);
                  } else {
                    modifier.selected.push(modValue);
                  }
                  setModifierSelected(index, [...modifier.selected]);
                }}
              />
            ))}
          </FormGroup>
        </FormControl>
      )}
    </div>
  );
}
