import { Card } from "@mui/material";
import React, { useEffect } from "react";
import Button1 from "../../../components/button_1/button_1";
import useStyles from "./login_page_styles";
import FadeInUpAnimationDiv from "../../../components/animated_div/fade_in_up_animated_div";
import FadeInAnimatedDiv from "../../../components/animated_div/fade_in_animated_div";
import useWindowSize from "../../../utils/use_window_size";
import LoginForm from "./login_form";
import clsx from "clsx";
import RestaurantApiService from "../../../api_service/restaurant_api_service";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useStoreActions } from "easy-peasy";

export default function LoginPage() {
  const classes = useStyles();
  const history = useHistory();
  const { isTab } = useWindowSize();

  const setCurrentView = useStoreActions((actions) => actions.setCurrentView);

  useEffect(() => {
    if (localStorage.getItem("sn_rest_access_token")) {
      RestaurantApiService()
        .getRestaurantCall()
        .then((response) => {
          if (response.success && response.data.is_active) {
            setCurrentView("CASHIER");
            history.replace("/restaurant/home");
          } else {
            localStorage.removeItem("sn_rest_access_token");
          }
        });
    }
  }, []); //eslint-disable-line

  return (
    <div className={classes.root}>
      <FadeInAnimatedDiv>
        <Card
          className={isTab ? classes.login_card_mobile : classes.login_card}
          elevation={20}
        >
          {!isTab && <div className={classes.left_card} />}
          <div
            className={clsx(
              classes.right_card,
              isTab && classes.right_card_mobile
            )}
          >
            <div className={classes.button_bar}>
              <Button1 title="Enter Here" isSelected />
              <Button1
                title="Contact Us"
                onClick={() => history.push("/contact_us")}
              />
            </div>
            <FadeInUpAnimationDiv show={true}>
              <div className={classes.login_scroll_outer}>
                <div className={classes.welcome_text}>Welcome</div>
                <LoginForm />
              </div>
            </FadeInUpAnimationDiv>
          </div>
        </Card>
      </FadeInAnimatedDiv>
    </div>
  );
}
