import styles from "../../../styles/paymentSuccess.module.scss";
import validator from "email-validator";
import CustomButton from "../../../components/custom_button";
import CustomInput from "../../../components/custom_input";
import Layout from "../../../components/layout";
import RestaurantLogo from "../../../components/restaurant_logo";
import { Card, Grid } from "@mui/material";
import { Done } from "@mui/icons-material";
import { useEffect, useState } from "react";
import CustomerApiService from "../../../api_service/customer_api_service";
import { useHistory } from "react-router-dom";
import commaNumber from "comma-number";

export default function PaymentSuccessPage({ match }) {
  const history = useHistory();
  const { restaurant_id, table_id, order_id } = match.params;
  const [state, setState] = useState({
    loading: true,
    restaurant: null,
    table: null,
    order: null,
    customer: null,
    is_email_sent: true,
    order_sent_email: null,
  });

  const getOrder = async () => {
    const response = await CustomerApiService().getOrderByIdSuccessPageCall(
      restaurant_id,
      table_id,
      order_id
    );
    if (response.success) {
      setState((state) => {
        return {
          ...state,
          ...response.data,
          order_sent_email:
            response.data.order_sent_email ||
            response?.data?.customer?.email ||
            "",
          loading: false,
        };
      });
      if (response.data.order.payment_status !== "SUCCESS") {
        history.replace(
          `/restaurants/${restaurant_id}/tables/${table_id}/orders/${order_id}`
        );
      }
    }
  };

  const sendBillEmail = async () => {
    const response = await CustomerApiService().sendBillEmail(
      Number(order_id),
      state.order_sent_email
    );
    if (response.success) {
      getOrder();
    }
  };

  useEffect(() => {
    getOrder();
  }, []); // eslint-disable-line

  return (
    <Layout>
      {state.restaurant && <RestaurantLogo url={state.restaurant.logo} />}
      <Grid container spacing={2}>
        {state.order && (
          <Grid item xs={12}>
            <Card style={{ padding: "25px" }}>
              <Grid container>
                <Grid item xs={2} className={styles.headerIconContainer}>
                  <Done className={styles.thumbsUpIcon} />
                </Grid>
                <Grid item xs={10} className={styles.headerTextContainer}>
                  <div className={styles.thankYouTextHeader}>Thank you</div>
                  <div className={styles.thankYouTextSubHeader}>
                    Your payment has been successfully processed and settled in
                    full.
                  </div>

                  <div className={styles.amountText}>
                    {`${commaNumber(
                      (
                        state.order.order_amount + state.order.tip_amount
                      ).toFixed(2)
                    )} ${state.restaurant?.currency}`}
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}

        {state.customer && (
          <Grid item xs={12}>
            <Card style={{ padding: "25px" }}>
              <Grid container>
                <Grid item xs={12}>
                  <div className={styles.label}>Do you want a receipt?</div>
                </Grid>
              </Grid>

              {state.is_email_sent ? (
                <Grid container alignItems="center" alignContent="center">
                  <Grid item xs={12}>
                    <Grid
                      container
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Grid item xs={2} className={styles.headerIconContainer}>
                        <img
                          src="/images/email_sent.png"
                          alt="email sent"
                          width="70"
                          height="70"
                        />
                      </Grid>
                      <Grid item xs={10} className={styles.headerTextContainer}>
                        <div className={styles.getBillHeader}>
                          Your receipt has been e-mailed to <br />
                          {state.order_sent_email}
                        </div>
                      </Grid>
                    </Grid>

                    <div className={styles.divider}></div>

                    <CustomButton
                      id="success-send-bill"
                      onClick={() => {
                        history.replace("/customer/order_history");
                      }}
                    >
                      View my bills
                    </CustomButton>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <label className={styles.label} for="custom-email-input">
                      Enter email address
                    </label>
                    <CustomInput
                      id="success-send-bill-email"
                      onChange={(e) => {
                        setState({
                          ...state,
                          order_sent_email: e.target.value,
                        });
                      }}
                      value={state.order_sent_email}
                      type="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomButton
                      id="success-send-bill-submit"
                      disabled={!validator.validate(state.order_sent_email)}
                      onClick={sendBillEmail}
                    >
                      Yes please!
                    </CustomButton>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={styles.OrCont}>
                      <div className={styles.lineAlt}></div>
                      <div className={styles.midText}>OR</div>
                      <div className={styles.lineAlt}></div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      className={styles.loginSignup}
                      onClick={() => {
                        history.replace("/customer/order_history");
                      }}
                    >
                      View my bills
                    </div>
                  </Grid>
                </Grid>
              )}
            </Card>
          </Grid>
        )}
      </Grid>
    </Layout>
  );
}
