import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  inputStyle: {
    align: "center",
    textAlign: "center",
    "& input": {
      textAlign: "center",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    margin: "10px 0px",
    alignItems: "center",
  },
  removeBtn: {
    color: "white !important",
    backgroundColor: "#cb000075 !important",
    padding: "0 !important",
  },
  qty_field: {
    width: "20px",
    padding: 0,
    marginLeft: "5px",
  },
  x: {
    margin: "0px 5px",
    color: "#80808096",
    fontWeight: "bold",
    fontSize: "12px",
  },
  name: { width: "100%", fontSize: "14px", fontWeight: "bold" },
  amount: {
    textAlign: "right",
    width: "70px",
    fontSize: "14px",
    fontWeight: "bold",
  },
}));

export default useStyles;
