import { IconButton } from "@mui/material";
import { Delete, Edit, RestoreFromTrash } from "@mui/icons-material";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect } from "react";
import RestaurantApiService from "../../../api_service/restaurant_api_service";
import BriefNavBar from "../../../components/restuarant/common/BriefNavBar";
import Button1 from "../../../components/button_1/button_1";
import EditCategory from "../../../components/edit_category/edit_category";
import EditItem from "../../../components/edit_item/edit_item";
import ItemCategory from "../../../components/item_category/item_category";
import TablesTab from "../../../components/tables_tab/tables_tab";
import OrdersTab from "../../../components/orders_tab/orders_tab";
import EditTable from "../../../components/edit_table/edit_table";
import ItemContainer from "../../../components/restuarant/home/ItemsContainer";
import * as Styled from "./admin.styled";
import { useHistory } from "react-router-dom";
import Charges from "../../../components/charges/charges";
import EditCharge from "../../../components/edit_charge/edit_charge";
import TabView from "../../../components/tab_view/tab_view";
import RestaurantEditRestaurant from "../../../components/restaurant_details_modal/restaurant_details_modal";
import MobileView from "../../../components/mobile_view/mobile_view";
import useWindowSize from "../../../utils/use_window_size";
import Discounts from "../../../components/discounts/discounts";
import EditDiscount from "../../../components/edit_discount/edit_discount";
import { showAlert } from "../../../components/message_box/show_alert";
import DownloadAllQR from "../../../components/download_all_qr/download_all_qr";

export default function AdminPage() {
  const history = useHistory();
  const itemCategories = useStoreState((state) => state.itemCategories);
  const setOpenEditCategory = useStoreActions(
    (actions) => actions.setOpenEditCategory
  );
  const setCategoryToEdit = useStoreActions(
    (actions) => actions.setCategoryToEdit
  );
  const setOpenEditItem = useStoreActions((actions) => actions.setOpenEditItem);
  const setItemToEdit = useStoreActions((actions) => actions.setItemToEdit);
  const selectedItemCategory = useStoreState(
    (state) => state.selectedItemCategory
  );
  const isAdminView = useStoreState((state) => state.currentView === "ADMIN");

  const { height, isTab } = useWindowSize();

  const onAddNewCategory = () => {
    setCategoryToEdit(null);
    setOpenEditCategory(true);
  };
  const onAddNewItem = () => {
    if (selectedItemCategory) {
      setItemToEdit(null);
      setOpenEditItem(true);
    } else {
      showAlert({
        head: "Info",
        body: "You cannot add new items without item category. Please select an Item Category first. If there is no item categories, please add Item Categories first",
      });
    }
  };
  useEffect(() => {
    if (!isAdminView) {
      history.replace("/restaurant/home");
    }
    RestaurantApiService().getRestaurantCall();
    RestaurantApiService().getItemCategoriesCall(false);
    RestaurantApiService().getTablesCall();
  }, [isAdminView]); // eslint-disable-line

  const onEditCategory = () => {
    setCategoryToEdit(selectedItemCategory);
    setOpenEditCategory(true);
  };

  const onDeleteCategory = () => {
    RestaurantApiService().updateItemCategoryCall(
      { is_active: !selectedItemCategory.is_active },
      selectedItemCategory.id
    );
  };

  return (
    <div>
      {isTab ? (
        <MobileView />
      ) : (
        <Styled.MainContainer>
          <Styled.LeftMainContainer>
            <Styled.LeftFirstChildContainer>
              <Styled.LogoContianer>
                <img width={"100%"} src="/images/logo.png" alt="logo" />
              </Styled.LogoContianer>
              <Styled.CategoryContainer height={height - 70}>
                {itemCategories.map((category, index) => (
                  <ItemCategory
                    key={index}
                    category={category}
                    isSelected={
                      selectedItemCategory
                        ? selectedItemCategory.id === category.id
                        : false
                    }
                  />
                ))}
              </Styled.CategoryContainer>
            </Styled.LeftFirstChildContainer>
            <Styled.LeftSecondChildContainer>
              <Styled.LeftSecondChildHeaderContainer>
                <Styled.CategoryHeader>
                  <Styled.CategoryText>
                    {selectedItemCategory?.name}
                  </Styled.CategoryText>
                  <IconButton onClick={onEditCategory}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={onDeleteCategory}>
                    {selectedItemCategory?.is_active ? (
                      <Delete />
                    ) : (
                      <RestoreFromTrash />
                    )}
                  </IconButton>
                </Styled.CategoryHeader>
              </Styled.LeftSecondChildHeaderContainer>
              <Styled.CategoryEditBtnContainer>
                <Button1
                  onClick={onAddNewCategory}
                  title="Add New Item Category"
                  width="40%"
                />
                <Button1
                  onClick={onAddNewItem}
                  title="Add New Item"
                  width="40%"
                />
              </Styled.CategoryEditBtnContainer>

              {selectedItemCategory?.items?.length > 0 && (
                <ItemContainer
                  data={selectedItemCategory?.items}
                  customStyles={{ paddingBottom: 180 }}
                />
              )}
            </Styled.LeftSecondChildContainer>
          </Styled.LeftMainContainer>
          <Styled.RightMainContainer>
            <Styled.RightHeaderContainer>
              <BriefNavBar />
            </Styled.RightHeaderContainer>
            <Styled.TablePadContainer height={height}>
              <TabView
                tables={<TablesTab />}
                orders={<OrdersTab />}
                charges={<Charges />}
                discounts={<Discounts />}
              />
            </Styled.TablePadContainer>
          </Styled.RightMainContainer>
        </Styled.MainContainer>
      )}
      <EditCategory />
      <EditItem />
      <EditTable />
      <DownloadAllQR/>
      <EditCharge />
      <EditDiscount />
      <RestaurantEditRestaurant />
    </div>
  );
}
