import { Backdrop, Box, Card, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./payout_pay_styles";
import ModelHeader from "../model_header/model_header";
import { format } from "date-fns";
import commaNumber from "comma-number";
import ImagePicker from "../image_picker/image_picker";
import Button1 from "../button_1/button_1";
import AdminApiService from "../../api_service/admin_api_service";
import { showAlert } from "../message_box/show_alert";

const initialData = { comment: "", receipt: "", receipt_image: null };

export default function PayoutPayModal({ open, onClose, onRefresh, payout }) {
  const classes = useStyles();

  const [state, setState] = useState(initialData);

  const changeState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const setInitialData = () => {
    setState({
      ...state,
      ...initialData,
    });
  };
  useEffect(() => {
    if (open) {
      setInitialData();
    }
  }, [open]); // eslint-disable-line

  const onSubmit = () => {
    const formData = new FormData();

    if (!state.receipt_image) {
      return showAlert({
        head: "Please be careful!",
        body: "Receipt image is required to submit the payout",
      });
    }
    formData.append("comment", state.comment);
    formData.append("file", state.receipt_image);
    AdminApiService()
      .payPayout(payout.id, formData)
      .then((response) => {
        if (response.success) {
          onRefresh();
          onClose();
        }
      });
  };

  return (
    payout && (
      <Backdrop className={classes.backdrop} open={open}>
        <Card className={classes.card}>
          <ModelHeader title={"Payout pay"} onClose={onClose} />
          <div style={{ padding: "20px" }}>
            <Box height={10} />
            <div className={classes.filter_row}>
              <TextField
                type="text"
                InputProps={{ readOnly: true }}
                label="From"
                value={format(new Date(payout.from_date), "yyyy-MM-dd")}
                fullWidth
              />
              <TextField
                type="text"
                InputProps={{ readOnly: true }}
                label="To"
                value={format(new Date(payout.to_date), "yyyy-MM-dd")}
                fullWidth
              />
            </div>
            <Box height={20} />
            <div className={classes.filter_row}>
              <TextField
                type="text"
                InputProps={{ readOnly: true }}
                label="Restaurant"
                value={payout.restaurant.name}
                fullWidth
              />
              <TextField
                type="text"
                InputProps={{ readOnly: true }}
                label="Amount to transfer"
                value={commaNumber(payout.restaurant_fee.toFixed(2))}
                fullWidth
              />
            </div>
            <Box height={20} />
            <TextField
              className={classes.text_field}
              style={{ width: "100%" }}
              name="comment"
              type="text"
              label="Comment (If any)"
              InputLabelProps={{ shrink: true }}
              value={state.comment}
              onChange={(event) => {
                changeState("comment", event.target.value);
              }}
            />
            <Box height={20} />
            <ImagePicker
              imageUrl={state.receipt}
              setFile={(file) => {
                changeState("receipt_image", file);
              }}
              file={state.receipt_image}
              title={"Receipt"}
              width={"100%"}
              height={500}
            />
            <Box height={40} />
            <div className={classes.button_row}>
              <Button1 onClick={onSubmit} isSelected title="Submit" />
            </div>
          </div>
        </Card>
      </Backdrop>
    )
  );
}
