import styles from "../styles/navBar.module.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  Grid,
  Avatar,
  IconButton,
} from "@mui/material";

import {
  AccountBox,
  Close,
  Menu,
  QrCodeScanner,
  ReceiptLong,
} from "@mui/icons-material";
import { getImageUrl, getInitialsByName } from "../utils/helpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UserHooks from "../utils/user_hooks";

export default function Index({ userFromPage }) {
  const [show, setShow] = useState(false);
  const history = useHistory();
  const { getUser } = UserHooks();
  const [user, setUser] = useState();

  const getUserFromLocalStorage = async () => {
    const user = userFromPage ? userFromPage : await getUser();
    setUser(user);
  };

  useEffect(() => {
    getUserFromLocalStorage();
  }, []); // eslint-disable-line

  const toggleDrawer = () => (event) => {
    setShow(!show);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.topbBar}>
        <div className={styles.navRow}>
          <div className={styles.topbBarColumn}>
            <IconButton id="navbar-toggle-drawer" onClick={toggleDrawer()}>
              <Menu style={{ color: "#fff" }} />
            </IconButton>
          </div>

          <div className={styles.topbBarMiddleColumn}>
            {/* <img alt="logo" src="/images/logo.png" height="50" /> */}
          </div>

          <div className={styles.topbBarColumn}>
            <Link to="/customer/profile">
              <div
                id="navbar-profile-picture"
                className={styles.profileImageWrappper}
              >
                {user && user.image ? (
                  <Avatar src={getImageUrl(user.image)} />
                ) : user && user.name ? (
                  <Avatar
                    onClick={() => {
                      history.push("/customer/profile");
                    }}
                    style={{ height: 39, width: 39 }}
                  >
                    {getInitialsByName(user.name)}
                  </Avatar>
                ) : (
                  <img
                    src={"/images/avatar.svg"}
                    width="39"
                    height="39"
                    className={styles.profileImage}
                    alt="avatar"
                  />
                )}
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.drawerWrapper}>
        <MyDrawer show={show} toggleDrawer={toggleDrawer} user={user} />
      </div>
    </div>
  );
}

function MyDrawer({ show, toggleDrawer, user }) {
  return (
    <Drawer anchor={"left"} open={show} onClose={toggleDrawer()}>
      <div className={styles.list}>
        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <div
              style={{ cursor: "pointer" }}
              className={styles.closeIconContainer}
            >
              <Close onClick={toggleDrawer()} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.navLogoContainer}>
              {user && user.image ? (
                <Avatar
                  src={getImageUrl(user.image)}
                  style={{ height: 80, width: 80, paddingTop: "0px" }}
                  className={styles.image}
                />
              ) : user && user.name ? (
                <Avatar
                  style={{ height: 80, width: 80, paddingTop: "0px" }}
                  className={styles.image}
                >
                  {getInitialsByName(user.name)}
                </Avatar>
              ) : (
                <Avatar
                  src="/images/avatar.svg"
                  style={{ height: 80, width: 80, paddingTop: "0px" }}
                  className={styles.image}
                />
              )}
            </div>
          </Grid>
        </Grid>

        {user && (
          <div className={styles.userName}>
            {user.name || "Hello, Please update your profile"}
          </div>
        )}

        {user && <div className={styles.userEmail}>{user.email}</div>}

        <List className={styles.linkContainer}>
          <NavBarItem
            name={"Order History"}
            route={"/customer/order_history"}
            icon={<ReceiptLong />}
          />
          <NavBarItem
            name={"Profile"}
            route={"/customer/profile"}
            icon={<AccountBox />}
          />
          <NavBarItem
            name={"Scan QR Code"}
            route={"/customer/scan_qr"}
            icon={<QrCodeScanner />}
          />
        </List>
      </div>
    </Drawer>
  );
}

function NavBarItem({ route, name, icon }) {
  const history = useHistory();
  return (
    <ListItem
      id="drawer-logout"
      button
      className={styles.listItem}
      onClick={() => {
        history.push(route);
      }}
    >
      <div className={styles.navbarImage}>{icon}</div>
      <div className={styles.navBarText}>{name}</div>
    </ListItem>
  );
}
