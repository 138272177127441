import React from "react";
import Footer from "../footer/footer";
import Header from "../header/header";
import useStyles from "./privacy_policy_styles";

export default function PrivacyPolicy() {
  const classes = useStyles();
  const data = [
    {
      header: "Information We Collect",
      content: [
        "We collect customer information, including but not limited to names, contact details, and payment information.",
        "Information is collected when customers use our POS and customer applications.",
      ],
    },
    {
      header: "Use of Information",
      content: [
        "Customer information is used for order processing, payment transactions, and application functionality.",
        "We do not sell or share personal information with third parties for marketing purposes.",
      ],
    },
    {
      header: "Payment Processing",
      content: [
        "Payment transactions are securely processed through PayHere.",
        "PayHere's Privacy Policy governs the use of payment information.",
      ],
    },
    {
      header: "Data Security",
      content: [
        "We implement industry-standard security measures to protect customer data.",
        "Despite our best efforts, no method of transmission over the internet or electronic storage is completely secure.",
      ],
    },
    {
      header: "Cookies",
      content: [
        "We may use cookies to enhance the user experience. Users can adjust browser settings to refuse cookies.",
      ],
    },
    {
      header: "Changes to Privacy Policy",
      content: [
        "Changes to the Privacy Policy will be communicated through the application.",
        "Continued use of the application after changes constitutes acceptance of the updated policy.",
      ],
    },
    {
      header: "Contact Information",
      content: [
        "For inquiries regarding privacy concerns, contact Ceypay at ceyposlk@gmail.com.",
      ],
    },
  ];

  return (
    <div>
      <Header />
      <div className={classes.container}>
        <h1 className={classes.heading}>Privacy Policy</h1>
        <p>
          At CeyPay, we are committed to protecting your privacy. This Privacy
          Policy outlines how we collect, use, and safeguard your personal
          information:
        </p>
        <ol className={classes.list}>
          {data.map((term, index) => (
            <li key={index} className={classes.listItem}>
              <strong>{term.header}</strong>
              <ul>
                {term.content.map((content, index) => (
                  <li key={index}>{content}</li>
                ))}
              </ul>
            </li>
          ))}
        </ol>
      </div>
      <Footer />
    </div>
  );
}
