import { Card, Grid } from "@mui/material";
import styles from "../../../styles/editTableNumber.module.scss";
import { useEffect, useState } from "react";
import Layout from "../../../components/layout";
import CustomInput from "../../../components/custom_input";
import CustomButton from "../../../components/custom_button";
import RestaurantLogo from "../../../components/restaurant_logo";
import { useHistory } from "react-router-dom";
import BackArrow from "../../../components/back_arrow";
import CustomerApiService from "../../../api_service/customer_api_service";
import { Box } from "@mui/system";
import { showAlert } from "../../../components/message_box/show_alert";

export default function EditTablePage({ match }) {
  const { restaurant_code } = match.params;
  const history = useHistory();

  const [state, setState] = useState({
    tableNumber: "",
    restaurantLogo: "",
  });

  const getRestaurantDetails = async () => {
    const restaurantRes = await CustomerApiService().getRestaurantDetailsCall(
      restaurant_code
    );
    if (restaurantRes.success) {
      setState({
        ...state,
        restaurantLogo: restaurantRes.data.restaurant.logo,
      });
    } else {
      history.push("/customer/order_history");
    }
  };

  useEffect(() => {
    getRestaurantDetails();
  }, []); //eslint-disable-line

  const getTable = async () => {
    const response = await CustomerApiService().getRestaurantDetailsQrCall(
      restaurant_code,
      state.tableNumber
    );
    if (response.success) {
      history.push(
        `/restaurants/${restaurant_code}/tables/${state.tableNumber}/landing`
      );
    } else if (response.data.restaurant_not_available) {
      history.push("/customer/order_history");
    } else if (response.data.table_not_available) {
      showAlert({
        head: "Info",
        body: `Table no ${state.tableNumber} is not available in this restaurant`,
        isCustomer: true,
      });
    }
  };

  return (
    <Layout footer>
      <BackArrow id="edit-table-back" />
      <Box height={10} />
      <Card className={styles.card}>
        <Grid container spacing={2}>
          {state.restaurantLogo && (
            <Grid item xs={12}>
              <div className={styles.image}>
                <RestaurantLogo url={state.restaurantLogo} />
              </div>
            </Grid>
          )}

          <Grid item xs={12}>
            <div className={styles.label}>Change your table</div>
            <CustomInput
              id="edit-table-input"
              autoFocus
              value={state.tableNumber}
              onChange={(e) => {
                setState({ ...state, tableNumber: e.target.value });
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  getTable();
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomButton
              id="edit-table-submit"
              disabled={!state.tableNumber}
              onClick={getTable}
            >
              Get the bill
            </CustomButton>
          </Grid>
        </Grid>
      </Card>
    </Layout>
  );
}
