import { textToSpeech } from "./helpers";
import store from "../store/store";
const actions = store.getActions();

export const notifyIfOrderChanged = (oldTables, newTables) => {
  const OrdersAddedTables = [];
  const OrdersUpdatedTables = [];
  const waiterCallingTables = [];

  oldTables.forEach((oldTable) => {
    const newTable = newTables.find((a) => a.id === oldTable.id);
    const oldTableOrders = oldTable.orders;
    const newTableOrders = newTable.orders;
    newTableOrders.forEach((newOrder) => {
      const oldOrder = oldTableOrders.find((a) => a.id === newOrder.id);
      if (!oldOrder && newOrder?.created_by_customer) {
        if (!OrdersAddedTables.find((t) => t.id === newTable.id)) {
          OrdersAddedTables.push(newTable);
        }
      } else {
        const oldOrderItems = oldOrder?.order_items || [];
        const newOrderItems = newOrder?.order_items || [];

        // check for newly added items
        newOrderItems.forEach((newOrderItem) => {
          const oldOrderItem = oldOrderItems.find(
            (a) => a.id === newOrderItem.id
          );
          if (!oldOrderItem && newOrderItem?.added_by_customer) {
            if (!OrdersUpdatedTables.find((a) => a.id === newTable.id)) {
              OrdersUpdatedTables.push(newTable);
            }
          }
        });
      }
    });

    if (newTable.is_waiter_calling && !oldTable.is_waiter_calling) {
      waiterCallingTables.push(newTable);
    }
  });
  OrdersUpdatedTables.forEach((table) => {
    actions.changeTableUpdatedStatus({ id: table.id, status: true });
  });

  if (OrdersAddedTables.length > 0) {
    textToSpeech(
      `New orders added to table ${OrdersAddedTables.map((t) => t.number).join(
        " and "
      )}`
    );
  }
  if (OrdersUpdatedTables.length > 0) {
    textToSpeech(
      `Orders updated on table ${OrdersUpdatedTables.map((t) => t.number).join(
        " and "
      )}`
    );
  }
  if (waiterCallingTables.length > 0) {
    textToSpeech(
      `Calling waiter on table ${waiterCallingTables
        .map((t) => t.number)
        .join(" and ")}`
    );
  }
};
