import { Card } from "@mui/material";
import React from "react";
import Button1 from "../../../components/button_1/button_1";
import useStyles from "./login_page_styles";
import FadeInUpAnimationDiv from "../../../components/animated_div/fade_in_up_animated_div";
import FadeInAnimatedDiv from "../../../components/animated_div/fade_in_animated_div";
import useWindowSize from "../../../utils/use_window_size";
import ContactUsForm from "./contact_us_form";
import clsx from "clsx";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function ContactUs() {
  const classes = useStyles();
  const history = useHistory();
  const { isTab } = useWindowSize();

  return (
    <div className={classes.root}>
      <FadeInAnimatedDiv>
        <Card
          className={isTab ? classes.login_card_mobile : classes.login_card}
          elevation={20}
        >
          {!isTab && <div className={classes.left_card} />}
          <div
            className={clsx(
              classes.right_card,
              isTab && classes.right_card_mobile
            )}
          >
            <div className={classes.button_bar}>
              <Button1 title="Enter Here" onClick={() => history.push("/")} />
              <Button1 title="Contact Us" isSelected />
            </div>
            <FadeInUpAnimationDiv show={true}>
              <div className={classes.contact_scroll_outer}>
                <div className={classes.contact_title}>
                  Please send us little bit about you !
                </div>
                <ContactUsForm />
              </div>
            </FadeInUpAnimationDiv>
          </div>
        </Card>
      </FadeInAnimatedDiv>
    </div>
  );
}
