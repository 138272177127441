import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  backdrop: {
    zIndex: 10,
    color: "#fff",
    backgroundColor: "#000000c4",
  },
  card: {
    minWidth: "300px",
    maxWidth: "500px",
    margin: "10vh 10px auto 10px",
    maxHeight: "80vh",
    display: "flex",
    flexDirection: "column",
    borderRadius: 5,
    backgroundSize: "cover",
  },
  text_field: { backgroundColor: "white" },
  button_row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  item_name: {
    width: "100%",
    fontWeight: "bold",
    padding: "0px 3px",
  },
  modal_body: {
    overflow: "auto",
  },
  card_inner: {
    backgroundColor: "#fffffff5",
    padding: "15px",
  },
  qtyOuter: {
    display: "flex",
    justifyContent: "center",
  },
  bottom_section: { padding: 10, backgroundColor: "#edededed" },
  description_row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    gap: "7px",
  },
  description_text: {
    width: "100%",
    textAlign: "justify",
    fontSize: "12px",
    color: "gray",
  },
});

export default useStyles;
