import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    fontFamily: "Arial, sans-serif",
    margin: "80px 30px",
    lineHeight: "1.8",
  },
  heading: {
    color: "#333",
  },
  list: {
    listStyle: "none",
    padding: 0,
  },
  listItem: {
    marginBottom: "15px",
  },
});

export default useStyles;
