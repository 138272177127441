import React from "react";
import Footer from "../footer/footer";
import Header from "../header/header";
import useStyles from "./terms_conditions_styles";

export default function TermsConditions() {
  const classes = useStyles();
  const data = [
    {
      header: "Service Usage",
      content: [
        "Our POS application is designed for the management of menus and orders in restaurants.",
        "Customers can use the associated customer application to view menus, place orders, and make payments.",
      ],
    },
    {
      header: "Ordering Process",
      content: [
        "Customers can place orders by scanning the QR code on their table.",
        "Orders are automatically synchronized with the restaurant POS application.",
      ],
    },
    {
      header: "Payment",
      content: [
        "Customers can pay for their orders using the customer application, which includes a card payment option.",
      ],
    },
    {
      header: "Privacy",
      content: [
        "We respect your privacy. Customer information, including payment details, is securely processed through PayHere.",
        "For more details on how we handle personal information, please refer to our Privacy Policy.",
      ],
    },
    {
      header: "Changes to Terms",
      content: [
        "We reserve the right to modify these terms at any time. Any changes will be communicated through the application.",
      ],
    },
    {
      header: "Termination",
      content: [
        "We may terminate or suspend your account if we suspect any violation of these terms.",
      ],
    },
    {
      header: "Contact Information",
      content: [
        "For any questions or concerns, contact CeyPay at mailto:ceyposlk@gmail.com.",
      ],
    },
  ];

  return (
    <div>
      <Header />
      <div className={classes.container}>
        <h1 className={classes.heading}>Terms and Conditions</h1>
        <p>
          Welcome to CeyPay! By using our POS application, you agree to the
          following terms and conditions:
        </p>
        <ol className={classes.list}>
          {data.map((term, index) => (
            <li key={index} className={classes.listItem}>
              <strong>{term.header}</strong>
              <ul>
                {term.content.map((content, index) => (
                  <li key={index}>{content}</li>
                ))}
              </ul>
            </li>
          ))}
        </ol>
      </div>
      <Footer />
    </div>
  );
}
