import { makeStyles } from "@mui/styles";
import {
  payment_gradient,
  payment_gradient_reverse,
  primary_gradient,
  print_gradient,
  print_gradient_reverse,
  vanadyl_blue,
} from "../../themes/colors";

const useStyles = makeStyles({
  values_of_order_details: {
    color: `${vanadyl_blue} !important`,
  },
  outer_card: { padding: "0px" },
  outer_card_padding: { padding: "10px" },
  details_row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  customer_field: { margin: "0px 0px" },
  label: {
    margin: "20px 0 10px 0",
    fontWeight: "bold",
    fontSize: "13px",
  },
  total_amount: {
    backgroundImage: primary_gradient,
    padding: "5px 20px",
    color: "white",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  pay_btn: {
    width: "100%",
    backgroundImage: payment_gradient,
    padding: "5px 20px",
    color: "white !important",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "3px !important",
    cursor: "pointer",
    "&:hover": {
      transition: "0.5s",
      backgroundImage: payment_gradient_reverse,
      color: "black !important",
      boxShadow: "0px 2px 8px 3px rgb(0 0 0 / 20%)",
    },
  },
  order_details_row: {
    justifyContent: "space-between",
    display: "flex",
    fontSize: "13px",
    fontWeight: "bold",
    marginBottom: "10px",
    flexDirection: 'row',
    alignItems: 'center',
    padding: "0px 5px"
  },
  print_btn: {
    width: "100%",
    backgroundImage: print_gradient,
    padding: "5px 20px",
    color: "white !important",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "3px !important",
    cursor: "pointer",
    "&:hover": {
      transition: "0.5s",
      backgroundImage: print_gradient_reverse,
      color: "black !important",
      boxShadow: "0px 2px 8px 3px rgb(0 0 0 / 20%)",
    },
  },
  button_row: {
    display: "flex",
    flexDirection: "row",
  },
});

export default useStyles;
