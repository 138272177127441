import React, { useEffect, useState } from "react";
import useStyles from "./config_page_styles";
import { FormControlLabel, Grid, Switch, TextField } from "@mui/material";
import AdminApiService from "../../../api_service/admin_api_service";
import AdminAppBar from "../../../components/admin_app_bar/app_bar";
import { isBoolean, isString } from "lodash";
import Button1 from "../../../components/button_1/button_1";

export default function ConfigPage() {
  const classes = useStyles();
  const [state, setState] = useState({});

  const getConfig = () => {
    AdminApiService()
      .getConfig()
      .then((response) => {
        if (response.success) {
          delete response.data.id;
          delete response.data.createdAt;
          delete response.data.updatedAt;
          setState(response.data);
        }
      });
  };

  const updateConfig = () => {
    AdminApiService()
      .updateConfig(state)
      .then((response) => {
        if (response.success) {
        }
      });
  };

  const onChange = (key, value) => {
    setState({ ...state, [key]: value });
  };

  useEffect(() => {
    getConfig();
  }, []); // eslint-disable-line

  return (
    <div className={classes.root}>
      <div className={classes.right_bar}>
        <AdminAppBar title={"Configurations"} />
        <Grid container>
          {Object.keys(state).map((key, index) => (
            <Grid item xs={12} key={index}>
              {isBoolean(state[key]) ? (
                <CustomSwitch
                  attribute={key}
                  value={state[key]}
                  onChange={onChange}
                />
              ) : isString(state[key]) ? (
                <TextField
                  fullWidth
                  label={key}
                  value={state[key]}
                  onChange={(event) => onChange(key, event.target.value)}
                />
              ) : (
                <div />
              )}
            </Grid>
          ))}
        </Grid>
        <Button1
          title={"Submit Config"}
          isSelected
          onClick={() => {
            updateConfig();
          }}
        />
      </div>
    </div>
  );
}

const CustomSwitch = ({ attribute, value, onChange }) => {
  return (
    <div
      style={{
        padding: "20px",
      }}
    >
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={() => {
              onChange(attribute, !value);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label={attribute}
      />
    </div>
  );
};
