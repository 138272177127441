import { Backdrop, Box, Card, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./order_name_update_styles";
import ModelHeader from "../model_header/model_header";
import CustomButton from "../custom_button";

export default function OrderNameUpdate({
  open,
  onClose,
  onSubmit,
  initialName
}) {
  const classes = useStyles();

  const [data, setData] = useState({
    name: initialName || "",
  });

  useEffect(() => {
    setData({
      name: initialName || "",
    });
  }, [initialName]); // eslint-disable-line

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <Card className={classes.card}>
        <ModelHeader title={"Please confirm your details"} onClose={onClose} customer />
        <div style={{ padding: "20px" }}>
          <Box height={10} />

          <TextField
            className={classes.text_field}
            label="Your Name"
            name="name"
            value={data.name}
            onChange={(event) => {
              setData({ ...data, name: event.target.value });
            }}
            InputLabelProps={{ shrink: true }}
          />

          <Box height={40} />

          <div className={classes.button_row}>
            <CustomButton
              disabled={!data.name}
              onClick={() => {
                onSubmit(data.name);
              }}
            >
              Confirm
            </CustomButton>
          </div>
        </div>
      </Card>
    </Backdrop>
  );
}
