import { Backdrop, Box, Card, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./initiate_payout_styles";
import Button1 from "../button_1/button_1";
import ModelHeader from "../model_header/model_header";
import { PayoutsStartDate } from "../../utils/constants";
import { format, min, sub } from "date-fns";
import AdminApiService from "../../api_service/admin_api_service";
import commaNumber from "comma-number";

const initiatePayoutValues = {
  from_date: format(
    sub(new Date(), {
      days: 2,
    }),
    "yyyy-MM-dd"
  ),
  to_date: format(
    sub(new Date(), {
      days: 1,
    }),
    "yyyy-MM-dd"
  ),
  restaurant_id: null,
};

export default function InitiatePayout({ open, setOpen, onRefresh }) {
  const classes = useStyles();

  const [state, setState] = useState(initiatePayoutValues);
  const [restaurants, setRestaurants] = useState([]);
  const [payoutResult, setPayoutResult] = useState();

  const getAllRestaurants = async () => {
    const response = await AdminApiService().getAllRestaurantsCall();
    if (response.success) {
      setRestaurants(response.data);
    }
  };

  const changeState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const setInitialData = () => {
    setState({
      ...state,
      ...initiatePayoutValues,
    });
  };
  useEffect(() => {
    if (open) {
      setInitialData();
      getAllRestaurants();
    }
  }, [open]); // eslint-disable-line

  useEffect(() => {
    const searchPayouts = async () => {
      if (state.from_date && state.to_date && state.restaurant_id) {
        const response = await AdminApiService().searchPayout(state);
        if (response.success) {
          setPayoutResult(response.data);
        }
      } else {
        setPayoutResult(null);
      }
    };
    searchPayouts();
  }, [state.from_date, state.to_date, state.restaurant_id, state]);

  const onClose = () => {
    setOpen(false);
  };

  const createPayout = async () => {
    const response = await AdminApiService().createPayout(state);
    if (response.success) {
      onRefresh()
      onClose();
    }
  };

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <Card className={classes.card}>
        <ModelHeader title={"Initiate new payout"} onClose={onClose} />
        <div style={{ padding: "20px" }}>
          <Box height={10} />
          <TextField
            select
            className={classes.text_field}
            style={{ width: "100%" }}
            label="Select Restaurant"
            value={state.restaurant_id}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              changeState("restaurant_id", event.target.value);
            }}
          >
            {restaurants.map((restaurant) => (
              <MenuItem value={restaurant.id} key={restaurant.id}>
                {restaurant.name}
              </MenuItem>
            ))}
          </TextField>
          <Box height={40} />

          <TextField
            className={classes.text_field}
            style={{ width: "45%", marginRight: "10%" }}
            name="from"
            type="date"
            label="From"
            InputLabelProps={{ shrink: true }}
            value={state.from_date}
            onChange={(event) => {
              changeState("from_date", event.target.value);
            }}
            inputProps={{
              max: format(
                state.to_date
                  ? min([new Date(), new Date(state.to_date)])
                  : new Date(),
                "yyyy-MM-dd"
              ),
              min: format(PayoutsStartDate, "yyyy-MM-dd"),
            }}
          />

          <TextField
            className={classes.text_field}
            style={{ width: "45%" }}
            name="to"
            type="date"
            label="To"
            InputLabelProps={{ shrink: true }}
            value={state.to_date}
            onChange={(event) => {
              changeState("to_date", event.target.value);
            }}
            inputProps={{
              min: state.from_date,
              max: format(
                sub(new Date(), {
                  days: 1,
                }),
                "yyyy-MM-dd"
              ),
            }}
          />
          <Box height={40} />

          {payoutResult && (
            <div className={classes.filter_results_outer}>
              {payoutResult.total_orders === 0 ? (
                <p>No orders found for the selected period</p>
              ) : (
                <>
                  <div className={classes.filter_row}>
                    <div className={classes.filter_col}>
                      <div className={classes.filter_item_row}>
                        <span>Fee Percentage</span>
                        <span>
                          {payoutResult.restaurant.app_fee_percentage}
                        </span>
                      </div>
                      <div className={classes.filter_item_row}>
                        <span>Total Orders</span>
                        <span>{payoutResult.total_orders}</span>
                      </div>
                      <div className={classes.filter_item_row}>
                        <span>Total Amount</span>
                        <span>
                          {commaNumber(payoutResult.totals.total_amount)}
                        </span>
                      </div>
                      <div className={classes.filter_item_row}>
                        <span>App fee</span>
                        <span>{commaNumber(payoutResult.totals.app_fee)}</span>
                      </div>
                      <div className={classes.filter_item_row}>
                        <span>Restaurant Fee</span>
                        <span className={classes.restaurant_fee}>
                          {commaNumber(payoutResult.totals.restaurant_fee)}
                        </span>
                      </div>
                    </div>
                    <div className={classes.filter_col}>
                      <div>Bank Account</div>
                      {payoutResult.restaurant.bank_account_details.map(
                        (detail, index) => (
                          <div key={index} className={classes.account_detail}>
                            {detail}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <Button1
                    onClick={createPayout}
                    isSelected
                    title="Initialize Payout"
                  />
                </>
              )}
            </div>
          )}
        </div>
      </Card>
    </Backdrop>
  );
}
