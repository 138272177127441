import { Box, Card, Tab, Tabs } from "@mui/material";
import styles from "../../../styles/menuPage.module.scss";
import Layout from "../../../components/layout";
import CustomButton from "../../../components/custom_button";
import { useHistory } from "react-router-dom";
import BackArrow from "../../../components/back_arrow";
import { useEffect, useState } from "react";
import CustomerApiService from "../../../api_service/customer_api_service";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import ItemsTab from "./items_tab";
import CustomerSelectItemPopup from "../../../components/customer_select_item_popup/customer_select_item_popup";
import * as Styled from "./menu.styled";
import commaNumber from "comma-number";
import { getImageUrl } from "../../../utils/helpers";

export default function MenuPage({ match, menuOnly }) {
  const { restaurant_code, table_no } = match.params;
  const history = useHistory();
  const theme = useTheme();

  const [state, setState] = useState({
    categories: [],
    restaurant: null,
    loading: true,
    currentTabIndex: 0,
    order: null,
    disableSelfOrdering: false,
  });

  const onGoToBill = async () => {
    history.push(
      `/restaurants/${restaurant_code}/tables/${table_no}/landing?go_to_bill=1`
    );
  };

  const getInitialData = async () => {
    const categoriesResponse =
      await CustomerApiService().getItemCategoriesWithItemsCall(
        restaurant_code
      );
    const orderResponse = menuOnly
      ? {success: false}
      : await CustomerApiService().getRestaurantDetailsQrCall(
          restaurant_code,
          table_no
        );

    setState({
      ...state,
      categories: categoriesResponse.success
        ? categoriesResponse.data.item_categories
        : [],
      restaurant: categoriesResponse.success
        ? categoriesResponse.data.restaurant
        : null,
      loading: false,
      order: orderResponse.success ? orderResponse.data?.order : null,
      disableSelfOrdering:
        menuOnly ||
        (categoriesResponse.success &&
          categoriesResponse.data.restaurant &&
          "self_ordering" in categoriesResponse.data.restaurant &&
          !categoriesResponse.data.restaurant.self_ordering),
    });
  };

  const refreshOrder = async () => {
    const orderResponse = await CustomerApiService().getRestaurantDetailsQrCall(
      restaurant_code,
      table_no
    );
    if (orderResponse.success) {
      const { order } = orderResponse.data;
      if (order) {
        setState({ ...state, order: order });
      }
    }
  };

  const onChangeTab = (event, newValue) => {
    setState({ ...state, currentTabIndex: newValue });
  };

  const onChangeIndex = (index) => {
    setState({ ...state, currentTabIndex: index });
  };

  useEffect(() => {
    getInitialData();
  }, []); // eslint-disable-line

  useEffect(() => {
    setTimeout(() => {
      const e = document.getElementById(`menu-tab-end`);
      if (e) {
        e.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }, 5000);
  }, []);

  return (
    <Layout footer>
      {!state.loading && state.order && (
        <Styled.ViewBillContainer>
          <CustomButton
            onClick={onGoToBill}
            id="edit-table-submit"
            border={"0.5px solid #fff"}
          >
            View Bill &nbsp; ({commaNumber(state.order.order_amount.toFixed(2))}
            &nbsp; {state.restaurant?.currency})
          </CustomButton>
        </Styled.ViewBillContainer>
      )}
      <BackArrow id="edit-table-back" />
      <Box height={10} />
      {state.loading ? (
        <div />
      ) : (
        <Styled.CardOuter>
          <Card className={styles.card}>
            <Box sx={{ maxWidth: "100%", bgcolor: "background.paper" }}>
              <Tabs
                value={state.currentTabIndex}
                onChange={onChangeTab}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {state.categories.map((category, index) => (
                  <Tab
                    id={`menu-tab-${
                      index === 0
                        ? "start"
                        : index === state.categories.length - 1
                        ? "end"
                        : index
                    }`}
                    key={category.id}
                    label={category.name}
                    icon={
                      <div
                        style={{
                          backgroundImage: `url(${getImageUrl(
                            category.image
                          )})`,
                          backgroundSize: "cover",
                          height: "50px",
                          width: "50px",
                          borderRadius: "50%",
                        }}
                      ></div>
                    }
                  />
                ))}
              </Tabs>
            </Box>
            <Box height={10} />
            <div style={{ width: "100%" }}>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={state.currentTabIndex}
                onChangeIndex={onChangeIndex}
              >
                {state.categories.map((category, index) => (
                  <ItemsTab
                    category={category}
                    key={category.id}
                    value={state.currentTabIndex}
                    index={index}
                    dir={theme.direction}
                    restaurant_code={restaurant_code}
                    table_no={table_no}
                    currency={state.restaurant?.currency}
                  />
                ))}
              </SwipeableViews>
            </div>
            <Box height={30} />
          </Card>
        </Styled.CardOuter>
      )}
      {!state.disableSelfOrdering && (
        <CustomerSelectItemPopup refreshOrder={refreshOrder} />
      )}
    </Layout>
  );
}
