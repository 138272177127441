export const getChargeName = (charge) => {
  return `${charge.charge.name}`;
};

export const padSpaces = (val, length, align) => {
  let text = String(val);
  if (text.length < length) {
    switch (align) {
      case "center":
        return text
          .padStart(text.length + Math.floor((length - text.length) / 2), " ")
          .padEnd(length, " ");
      case "right":
        return text.padStart(length, " ");
      default:
        return text.padEnd(length, " ");
    }
  } else {
    return `${text.substring(0, length - 2)}..`;
  }
};

export const divideLines = (text, maxWidth) => {
  for (let i = maxWidth; i < text.length; i += maxWidth) {
    let spaceAdded = false;
    for (let j = i; j > i - 30; j--) {
      if (text[j] === " ") {
        i = j;
        text = [text.slice(0, j + 1), "\n", text.slice(j + 1)].join("");
        spaceAdded = true;
        break;
      }
    }
    if (!spaceAdded) {
      text = [text.slice(0, i + 1), "\n", text.slice(i + 1)].join("");
    }
  }
  return text;
};
