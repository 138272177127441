import {
  Card,
  Grid,
  Button,
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  IconButton,
  Backdrop,
} from "@mui/material";
import { Close, RemoveCircle } from "@mui/icons-material";
import styles from "../../../styles/billInfo.module.scss";
import { useEffect, useState } from "react";
import BackArrow from "../../../components/back_arrow";
import Layout from "../../../components/layout";
import Tip from "../../../components/tip";
import CustomButton from "../../../components/custom_button";
import { useHistory } from "react-router-dom";
import CustomerApiService from "../../../api_service/customer_api_service";
import commaNumber from "comma-number";
import OrderNameUpdate from "../../../components/order_name_update/order_name_update";
import { useStoreActions, useStoreState } from "easy-peasy";
import { ManageAccounts } from "@mui/icons-material";
import { button_green, button_red } from "../../../themes/colors";
import { showAlert } from "../../../components/message_box/show_alert";
import { playAudioCustomer } from "../../../utils/helpers";

export default function OrderPage({ match }) {
  const history = useHistory();
  const { restaurant_id, table_id, order_id } = match.params;

  const isCalledToWaiter = useStoreState((state) => state.isCalledToWaiter);
  const setIsCalledToWaiter = useStoreActions(
    (actions) => actions.setIsCalledToWaiter
  );
  const alert = useStoreState((state) => state.alert);

  const [tipState, setTipState] = useState({
    tipDialog: false,
    warning: false,
  });

  const [state, setState] = useState({
    loading: true,
    restaurant: null,
    table: null,
    order: null,
    customer: null,
    customer_name: "",
    tip_amount: 0,
    selected_tip_card: null,
    collapse_bill: true,
    name_popup: false,
    payment_progress: false,
  });

  const getBillDetails = async (callOnce) => {
    let isPaymentProgress = false;
    setState((state) => {
      isPaymentProgress = state.payment_progress;
      return state;
    });
    if (isPaymentProgress) {
      return;
    }
    if (!order_id) {
      return showAlert({
        head: "Info",
        body: "Unable to load your order",
        isCustomer: true,
      });
    }
    const response = await CustomerApiService().getOrderByIdCall(
      restaurant_id,
      table_id,
      order_id,
      true
    );

    if (response.success) {
      setState((state) => {
        return {
          ...state,
          ...response.data,
          loading: false,
          name_popup: state.name_popup
            ? true
            : callOnce && !response.data.customer_name
            ? true
            : false,
        };
      });
      setIsCalledToWaiter(response?.data?.table?.is_waiter_calling);
      if (
        response?.data?.order?.payment_status === "SUCCESS" &&
        response?.data?.order?.is_closed
      ) {
        history.replace(
          `/restaurants/${restaurant_id}/tables/${table_id}/orders/${order_id}/success`
        );
      }
    } else {
      setState((state) => {
        return {
          ...state,
          loading: false,
        };
      });
      history.replace("/customer/order_history");
    }
  };

  const toggleBill = () => {
    setState({ ...state, collapse_bill: !state.collapse_bill });
  };

  const handleContinueToPay = () => {
    tipPopupClose();
    onPay();
  };

  const tipPopupClose = () => {
    setTipState({ ...tipState, tipDialog: false, warning: false });
  };

  const stopGetBilWithInterval = () => {
    setState({ ...state, payment_progress: true });
  };

  const startGetBilWithInterval = () => {
    setState({ ...state, payment_progress: false });
  };

  const onPay = async () => {
    if (!state.customer_name) {
      setState({ ...state, name_popup: true });
      return;
    }

    if (state.selected_tip_card === null) {
      setTipState({ ...tipState, tipDialog: true, warning: true });
      return;
    }

    const paymentInitializeResponse =
      await CustomerApiService().paymentInitializeCall(
        restaurant_id,
        table_id,
        order_id,
        Number(Number(state.tip_amount).toFixed(2))
      );

    if (!paymentInitializeResponse) {
      return;
    }

    const {
      amount,
      payment_order_id,
      merchant_id,
      notify_url,
      cancel_url,
      return_url,
      hash,
    } = paymentInitializeResponse.data.payment;

    const { name } = paymentInitializeResponse.data.customer;

    const payment = {
      merchant_id: merchant_id,
      return_url: return_url,
      cancel_url: cancel_url,
      notify_url: notify_url,
      order_id: payment_order_id,
      items: state.order.order_items.map((item) => item.item.name).join(", "),
      amount: amount.toFixed(2),
      currency: "LKR",
      hash: hash,
      first_name: name,
      last_name: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      country: "",
      delivery_address: "",
      delivery_city: "",
      delivery_country: "",
      custom_2: "",
    };

    window.payhere.onCompleted = function onCompleted(orderId) {
      history.replace(
        `/restaurants/${restaurant_id}/tables/${table_id}/orders/${order_id}/payment_pending`
      );
    };

    window.payhere.onDismissed = function onDismissed() {
      console.log("Payment dismissed");
      startGetBilWithInterval();
    };

    window.payhere.onError = function onError(error) {
      console.log("Payhere Payment Error", error);
      showAlert({
        head: "Info",
        body: "Sorry, we are unable to process your payment",
        isCustomer: true,
      });
      startGetBilWithInterval();
      history.push(
        `/restaurants/${restaurant_id}/tables/${table_id}/orders/${order_id}`
      );
    };

    window.payhere.startPayment(payment);

    stopGetBilWithInterval();
  };

  const onSelectTipCard = (percentage, index, enteredValue) => {
    if (percentage === null) {
      setState({
        ...state,
        selected_tip_card: index,
        tip_amount: enteredValue,
      });
    } else {
      setState({
        ...state,
        selected_tip_card: index,
        tip_amount: (state.order.order_amount * percentage) / 100,
      });
    }
  };

  useEffect(() => {
    getBillDetails(true);
    const interval = setInterval(() => {
      getBillDetails(false);
    }, 10000);
    return () => clearInterval(interval);
  }, []); // eslint-disable-line

  useEffect(() => {
    const payhereScript = document.createElement("script");
    payhereScript.type = "text/javascript";
    payhereScript.src = "https://www.payhere.lk/lib/payhere.js";
    if (!window.payhere) {
      document.body.appendChild(payhereScript);
    }
  }, []);

  const onOrderUpdate = async (name) => {
    const response = await CustomerApiService().updateOrderCall(
      restaurant_id,
      table_id,
      order_id,
      { name }
    );
    if (response.success) {
      setState({
        ...state,
        name_popup: false,
        customer_name: name,
      });
    }
  };

  const onConfirmOrder = async () => {
    await CustomerApiService().updateOrderCall(
      restaurant_id,
      table_id,
      order_id,
      { is_confirmed: true }
    );
    getBillDetails(false);
  };

  const onConfirmOrderClick = () => {
    showAlert({
      head: "Are you sure?",
      body: "Tap on 'yes' if you want to finalize your order and dispatch it to the kitchen",
      actions: [
        {
          name: "No",
          color: button_red,
        },
        {
          name: "Yes",
          color: button_green,
          action: onConfirmOrder,
        },
      ],
      isCustomer: true,
    });
  };

  useEffect(() => {
    const showConfirmAlert = () => {
      if (!state.order.is_confirmed && !alert.open) {
        showAlert({
          head: `${
            state.customer_name ? `${state.customer_name.split(" ")[0]}, ` : ""
          }You forgot to confirm your order!`,
          body: "You're are one tap away. Please tap on 'Confirm' if your order is finalized.",
          actions: [
            { name: "Cancel", color: button_red },
            {
              name: "Confirm",
              color: button_green,
              action: onConfirmOrderClick,
            },
          ],
          isCustomer: true,
        });
        playAudioCustomer();
      }
    };
    const interval = setInterval(() => {
      showConfirmAlert();
    }, 30000);
    return () => clearInterval(interval);
  }, [state.customer_name, state?.order?.is_confirmed, alert.open]); //eslint-disable-line

  const removeItem = (item) => {
    CustomerApiService()
      .removeItemFromOrderCall({
        restaurant_id: state.restaurant.id,
        order_id: state.order.id,
        order_item_id: item.id,
      })
      .then((response) => {
        if (response.success) {
          getBillDetails(false);
        }
      });
  };

  const onRemoveItem = (item) => {
    showAlert({
      head: "Are you sure?",
      body: `Tap on 'Yes' if you're want to remove ${item.quantity} x ${item.item.name} from your order`,
      actions: [
        { name: "No", color: button_red },
        { name: "Yes", color: button_green, action: () => removeItem(item) },
      ],
      isCustomer: true,
    });
  };

  return (
    <Layout footer>
      <div className={styles.topRow}>
        <div className={styles.backWrapper}>
          <BackArrow
            onClick={() => {
              if (state.restaurant && state.table) {
                history.push(
                  `/restaurants/${state.restaurant.code}/tables/${state.table.number}/landing?go_to_bill=0`
                );
              } else {
                history.goBack();
              }
            }}
          />
        </div>

        {state.restaurant && (
          <div className={styles.headerOuter}>
            <div className={styles.headerText1}>Your bill at</div>
            <div className={styles.headerText2}>{state.restaurant.name}</div>
          </div>
        )}

        <div className={styles.fabIcon}>
          <Button
            variant="outlined"
            startIcon={<ManageAccounts />}
            onClick={() => {
              showAlert({
                head: "Are you sure?",
                body: "This action will send an alert to a server about your request.",
                actions: [
                  {
                    name: "No",
                    color: button_red,
                  },
                  {
                    name: "Yes",
                    color: button_green,
                    action: () => {
                      CustomerApiService()
                        .updateWaiterCallingTableCall(
                          {
                            is_waiter_calling: true,
                          },
                          state.table.id
                        )
                        .then((response) => {
                          if (response.success) {
                            setIsCalledToWaiter(true);
                          }
                        });
                    },
                  },
                ],
                isCustomer: true,
              });
            }}
            className={isCalledToWaiter ? `${styles.button_active}` : ``}
            disabled={isCalledToWaiter}
          >
            {isCalledToWaiter ? "Called to Waiter" : "Call waiter"}
          </Button>
        </div>
      </div>
      <Box height={20} />
      {state.loading || !state.order ? (
        <div />
      ) : (
        <div className={styles.buttonAndCardWrapper}>
          <div className={styles.buttonContainer}>
            <div className={styles.line}></div>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              className={styles.button}
              onClick={toggleBill}
            >
              <Grid container justify="center">
                <Grid item xs={4}>
                  <div
                    style={{
                      textAlign: "left",
                      textTransform: "capitalize",
                    }}
                  >
                    Table:{" " + state.table.number}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      fontSize: "12px",
                      color: "#5E5873B3",
                      textAlign: "right",
                      textTransform: "capitalize",
                    }}
                  >
                    Waiter:
                    <span
                      style={{
                        color: "#5E5873",
                        textTransform: "capitalize",
                        fontSize: "12px",
                      }}
                    >
                      {" " + (state.order.waiter || "N/A")}
                    </span>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div className={styles.arrowContainer}>
                    <img
                      alt="collapsing_icon"
                      src="/images/collapsing_arrow_icon.svg"
                      width="13"
                      height="7"
                      className={
                        state.collapse_bill
                          ? styles.arrow
                          : styles.arrowCollapsed
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            </Button>
          </div>

          <Collapse in={state.collapse_bill} timeout="auto">
            <Card className={styles.card}>
              <Table className={styles.table}>
                <TableBody className={styles.tableRow}>
                  <TableRow style={{ height: "10px" }}></TableRow>
                  {state.order.order_items.map((item, index) => (
                    <TableRow key={index} className={styles.billItemRowCont}>
                      <TableCell
                        className={styles.tableRowCellBill}
                        align="left"
                      >
                        <div className={styles.tableBillItem}>
                          {!state.order.is_confirmed && (
                            <RemoveCircle
                              onClick={() => onRemoveItem(item)}
                              style={{ marginRight: 10, color: button_red }}
                            />
                          )}
                          <div className={styles.itemQty}>{item.quantity}x</div>
                          <div className={styles.itemSpacer}>
                            {item.item.name}
                          </div>
                        </div>
                        {item.discount ? (
                          <div className={styles.billItemDis}>
                            {`Discount ${
                              item.discount_type === "PERCENTAGE"
                                ? `${item.discount} %`
                                : item.discount_type === "AMOUNT"
                                ? `${commaNumber(item.discount.toFixed(2))} ${
                                    state.restaurant?.currency
                                  }`
                                : ""
                            } per item`}
                          </div>
                        ) : (
                          <div />
                        )}
                      </TableCell>
                      <TableCell
                        className={styles.tableRowCellRight}
                        align="right"
                        scope="row"
                      >
                        <div style={{ marginTop: "0" }}>
                          {commaNumber(
                            (
                              (item.unit_price + item.calculated_discount) *
                              item.quantity
                            ).toFixed(2)
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                  {state.order.total_item_level_discounts > 0 ? (
                    <TableRow>
                      <TableCell className={styles.tableRowCellBillDiscount}>
                        <div>Total item level discount</div>
                      </TableCell>
                      <TableCell className={styles.tableRowCellRightDiscount}>
                        <div>
                          {commaNumber(
                            (
                              state.order.total_item_level_discounts * -1
                            ).toFixed(2)
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <div />
                  )}
                  {state.order.order_discounts.map((discount, index) => (
                    <TableRow key={index} className={styles.billItemRowCont}>
                      <TableCell
                        className={styles.tableRowCellBill}
                        align="left"
                      >
                        <div className={styles.tableBillItem}>
                          <div className={styles.itemQty}>--</div>
                          <div className={styles.itemSpacer}>
                            {discount.charge.name}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className={styles.totalItemDiscountsCell}
                        align="right"
                        scope="row"
                      >
                        <div
                          style={{
                            marginTop: "0",
                            color: "#1dc369",
                            fontWeight: 500,
                          }}
                        >
                          {commaNumber(
                            (discount.calculated_value * -1).toFixed(2)
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                  {state.order.order_charges.map((charge, index) => (
                    <TableRow key={index} className={styles.billItemRowCont}>
                      <TableCell
                        className={styles.tableRowCellBill}
                        align="left"
                      >
                        <div className={styles.tableBillItem}>
                          <div className={styles.itemQty}>--</div>
                          <div className={styles.itemSpacer}>
                            {charge.charge.name}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className={styles.tableRowCellRight}
                        align="right"
                        scope="row"
                      >
                        <div style={{ marginTop: "0" }}>
                          {commaNumber(charge.calculated_value.toFixed(2))}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
          </Collapse>

          <Box height={20} />
          <div className={styles.totCont}>
            <div className={styles.totalRow}>
              <div>Total</div>
              <div className={styles.totalValue}>
                {`${commaNumber(state.order.order_amount.toFixed(2))} ${
                  state.restaurant?.currency
                }`}
              </div>
            </div>

            <div className={styles.fees}>Inclusive of all charges</div>
          </div>
        </div>
      )}
      {state.order &&
        state.order.is_confirmed &&
        state.restaurant?.allow_payments && (
          <Card className={styles.tipContainer}>
            <Tip
              orderAmount={state.order.order_amount}
              tipAmount={state.tip_amount}
              selectedTipCard={state.selected_tip_card}
              onSelectTipCard={onSelectTipCard}
              warning={tipState.warning}
            />
          </Card>
        )}
      {state.order &&
        state.order.is_confirmed &&
        state.restaurant?.allow_payments && (
          <Card className={styles.payContainer}>
            <Grid container className={styles.myTotalPaymentRow}>
              <Grid item xs={6} className={styles.myTotalPaymentLabel}>
                Pay
              </Grid>
              <Grid
                item
                xs={6}
                className={
                  state.order.order_amount > 9000000
                    ? styles.myTotalPaymentValue2
                    : styles.myTotalPaymentValue
                }
              >
                {`${commaNumber(
                  (Number(state.tip_amount) + state.order.order_amount).toFixed(
                    2
                  )
                )} ${state.restaurant?.currency}`}
              </Grid>

              <Grid item xs={12}>
                <div className={styles.tipInPay}>
                  Includes
                  <span style={{ color: "#5E5873" }}>{`${commaNumber(
                    Number(state.tip_amount).toFixed(2)
                  )} ${state.restaurant?.currency}`}</span>
                  in tips
                </div>
              </Grid>
              <Grid />
            </Grid>
          </Card>
        )}
      <Box height={120} />

      <div className={styles.static_outer}>
        {state.order && !state.order.is_confirmed && (
          <div className={styles.static_button_inner}>
            <CustomButton onClick={onConfirmOrderClick} mt={15}>
              <div>Confirm order</div>
            </CustomButton>
          </div>
        )}
        {state.order &&
          state.order.is_confirmed &&
          state.restaurant?.allow_payments && (
            <div className={styles.static_button_inner}>
              <CustomButton onClick={() => onPay()} mt={15}>
                <div>
                  Pay your bill &nbsp;
                  {commaNumber(
                    (
                      Number(state.tip_amount) + state.order.order_amount
                    ).toFixed(2)
                  )}
                  &nbsp; {state.restaurant?.currency}
                </div>
              </CustomButton>
            </div>
          )}

        <div className={styles.static_button_inner}>
          <CustomButton
            onClick={() => {
              history.push(
                `/restaurants/${state.restaurant.code}/tables/${state.table.number}/menu`
              );
            }}
            mt={15}
            variant="outlined"
          >
            {`View Menu ${
              state?.restaurant?.self_ordering ? "/ Order More" : ""
            }`}
          </CustomButton>
        </div>
      </div>

      {state.order && (
        <Dialog
          open={tipState.tipDialog}
          maxWidth="xs"
          classes={{ paper: styles.tipDialogPaper }}
        >
          <div className={styles.tipDialog}>
            <div className={styles.tipDialogCloseButton}>
              <IconButton size="small" onClick={tipPopupClose}>
                <Close fontSize="small" />
              </IconButton>
            </div>
            <Tip
              orderAmount={state.order.order_amount}
              selectedTipCard={state.selected_tip_card}
              onSelectTipCard={onSelectTipCard}
              warning={tipState.warning}
            />
            <CustomButton
              disabled={state.selected_tip_card === null}
              onClick={handleContinueToPay}
              mt={15}
            >
              Continue to pay
            </CustomButton>
          </div>
        </Dialog>
      )}
      <OrderNameUpdate
        open={state.name_popup}
        onClose={() => {
          setState({ ...state, name_popup: false });
        }}
        onSubmit={onOrderUpdate}
        initialName={state.customer?.name}
      />
      <Backdrop
        sx={{ color: "#0000004a", zIndex: (theme) => theme.zIndex.drawer + 10 }}
        open={state?.order?.is_payment_requested || false}
      >
        <Card
          style={{
            width: "60%",
            borderRadius: "50px",
          }}
        >
          <img
            width="100%"
            src="/images/payment_collect.gif"
            alt="server collect money"
          />
        </Card>
      </Backdrop>
    </Layout>
  );
}
