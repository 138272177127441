import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  backdrop: {
    zIndex: 10,
    color: "#fff",
    overflowY: "scroll",
  },
  card: { width: "500px" },
});

export default useStyles;
