import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  inputStyle: {
    align: "center",
    textAlign: "center",
    "& input": {
      textAlign: "center",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    margin: "5px 0px",
    alignItems: "center",
  },
  removeBtn: {
    color: "white !important",
    backgroundColor: "#cb000075 !important",
    padding: "0 !important",
  },
  qty_field: {
    width: "20px",
    padding: 0,
    marginLeft: "5px",
  },
  x: {
    margin: "0px 5px",
    color: "#80808096",
    fontWeight: "bold",
    fontSize: "12px",
  },
  name: {
    width: "100%",
    fontSize: "14px",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  amount: {
    textAlign: "right",
    width: "70px",
    fontSize: "14px",
    fontWeight: "bold",
  },
  doneBtn: {
    color: "white !important",
    backgroundColor: "green !important",
    padding: "0 !important",
  },
  changeCircle: {
    color: "white !important",
    backgroundColor: "grey !important",
    padding: "0 !important",
    marginLeft: "10px !important",
  },
  pendingOuter: {
    padding: "2px",
    marginLeft: "10px",
    borderRadius: "5px",
    border: "2px solid gray",
    height: "28px",
  },
  modifiers: {
    marginLeft: 64,
    fontSize: 13,
    marginTop: -7,
  },
}));

export default useStyles;
