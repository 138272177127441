import { makeStyles } from "@mui/styles";
import { primary_gradient_reverse } from "../../themes/colors";

const useStyles = makeStyles({
  backdrop: {
    zIndex: 10,
    color: "#fff",
    overflow: "unset",
  },
  card: { width: "600px", maxHeight: "90vh" },
  card_header: {
    backgroundImage: primary_gradient_reverse,
    color: "white",
    fontWeight: "bold",
    fontSize: "15px",
    padding: "10px",
  },
  card_body: {
    padding: "20px",
    overflow: "auto",
    maxHeight: "inherit",
  },
  text_field: { width: "100%" },
  button_row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    marginBottom:'30px'
  },
});

export default useStyles;
