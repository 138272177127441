import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap:'5px',
  },
  input: {
    backgroundColor: "white",
    textAlign:'center'
  },
});

export default useStyles;
