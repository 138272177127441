import { InputBase } from "@mui/material";
import React from "react";
import styles from "../styles/customInput.module.scss";

const CustomInput = ({
  mt,
  mb,
  ml,
  mr,
  inputStyle,
  containerStyle,
  fontWeight,
  fontSize = 15,
  ...rest
}) => {
  return (
    <div className={styles.inputContainer} style={containerStyle}>
      <InputBase
        style={{
          marginTop: mt,
          marginBottom: mb,
          marginLeft: ml,
          marginRight: mr,
          fontSize: fontSize,
          fontWeight: fontWeight,
          fontFamily: "Montserrat-Regular",
          ...inputStyle,
        }}
        fullWidth
        color="primary"
        {...rest}
      />
    </div>
  );
};

export default CustomInput;
