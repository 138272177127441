import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";
import Button1 from "../button_1/button_1";
import OrderCard from "../order_card/order_card";
import RestaurantApiService from "../../api_service/restaurant_api_service";
import useStyles from "./orders_tab_styles";
import useWindowSize from "../../utils/use_window_size";

export default function OrdersTab() {
  const classes = useStyles();
  const selectedTable = useStoreState((state) => state.selectedTable);
  const restaurant = useStoreState((state) => state.restaurant);
  const setSelectedOrder = useStoreActions(
    (actions) => actions.setSelectedOrder
  );
  const setSelectedTab = useStoreActions((actions) => actions.setSelectedTab);
  const selectedTableOrders = useStoreState(
    (state) => state.selectedTableOrders
  );
  const addOrderToTable = useStoreActions((actions) => actions.addOrderToTable);
  const { isTab } = useWindowSize();

  const [state, setState] = useState({
    orderAdding: false,
  });

  const addNewOrder = async () => {
    setState({ ...state, orderAdding: true });
    const orderRes = await RestaurantApiService().createOrderCall({
      table_id: selectedTable.id,
    });
    setState({ ...state, orderAdding: false });
    if (orderRes.success) {
      addOrderToTable(orderRes.data);
    }
  };

  useEffect(() => {
    if (selectedTable) {
      RestaurantApiService().getTableOrdersCall(selectedTable.id);
    }
    const intervalId = setInterval(() => {
      if (selectedTable) {
        RestaurantApiService().getTableOrdersCall(selectedTable.id, true);
      }
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, [selectedTable]);

  const goToOrder = (order) => {
    setSelectedOrder(order);
    setSelectedTab("order_detail");
  };

  return (
    <div>
      {selectedTable &&
        !isTab &&
        (!restaurant.one_order_per_table ||
          (restaurant.one_order_per_table &&
            selectedTableOrders.length === 0)) && (
          <div className={classes.add_new_order_outer}>
            <Button1
              title={
                state.orderAdding
                  ? "Adding a new order.."
                  : `Add new order to table : ${selectedTable.number}`
              }
              onClick={addNewOrder}
              disabled={state.orderAdding}
            />
          </div>
        )}
      {selectedTable && (
        <Grid style={{ width: "100%" }} container spacing={1}>
          {selectedTableOrders.map((order, index) => (
            <OrderCard
              order={order}
              key={index}
              index={index}
              onClick={goToOrder}
            />
          ))}
        </Grid>
      )}
    </div>
  );
}
