import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AdminPage from "../pages/restaurant_pages/admin_page/Admin";
import HomePage from "../pages/restaurant_pages/home_page/Home";
import LoginPage from "../pages/restaurant_pages/login_page/login_page";
import RestaurantPayoutsPage from "../pages/restaurant_pages/payouts_page/payouts_page";
import SalesPage from "../pages/restaurant_pages/sales_page/sales_page";
import RestaurantSubscriptionsPage from "../pages/restaurant_pages/subscriptions_page/subscriptions_page";

export default function RestaurantRouter() {
  let match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={`${match.path}`} component={LoginPage} />
        <Route exact path={`${match.path}/home`} component={HomePage} />
        <Route exact path={`${match.path}/admin`} component={AdminPage} />
        <Route exact path={`${match.path}/sales`} component={SalesPage} />
        <Route
          exact
          path={`${match.path}/payouts`}
          component={RestaurantPayoutsPage}
        />
        <Route
          exact
          path={`${match.path}/subscriptions`}
          component={RestaurantSubscriptionsPage}
        />
      </Switch>
    </div>
  );
}
