import React, { useRef } from "react";

import { Grid } from "@mui/material";
import { Edit } from "@mui/icons-material";
import CurrencyInput from "./currency_Input";
import styles from "../styles/tip.module.scss";

const tipPercentages = [0, 5, 10];

function Tip({
  warning,
  selectedTipCard,
  onSelectTipCard,
  orderAmount,
  tipAmount,
}) {
  const cust = useRef(null);

  return (
    <div className={styles.wrapper}>
      <div className={styles.tipTitle}>Tip</div>
      <Grid container>
        <Grid item xs={12}>
          <div
            className={
              warning && selectedTipCard === null
                ? styles.addTipWarning
                : styles.addTipWarningBlack
            }
          >
            Feel free to show appreciation to your server by leaving a tip for excellent service
          </div>
        </Grid>
        <Grid container spacing={1}>
          {tipPercentages.map((percentage, index) => (
            <Grid key={index} item xs={3}>
              <div
                id={`bill-tip-card-${index}`}
                className={
                  selectedTipCard === index
                    ? styles.selectTipMiniCardSelected
                    : styles.selectTipMiniCard
                }
                onClick={() => onSelectTipCard(percentage, index, null)}
              >
                {percentage === 0
                  ? "0"
                  : ((orderAmount * percentage) / 100).toFixed(2)}
                <br />
                <span
                  className={
                    selectedTipCard === index
                      ? styles.selectTipPercentSelected
                      : styles.selectTipPercent
                  }
                  style={{ fontSize: "12px", fontFamily: "Montserrat-Regular" }}
                >
                  {`${percentage}%`}
                </span>
              </div>
            </Grid>
          ))}

          <Grid item xs={3}>
            <div
              id="bill-tip-card-3"
              className={
                selectedTipCard === 3
                  ? styles.selectTipMiniCardSelected
                  : styles.selectTipMiniCard
              }
              onClick={() => {
                onSelectTipCard(null, 3, "");
                setTimeout(() => {
                  cust.current.focus();
                }, [1]);
              }}
            >
              {selectedTipCard === 3 ? (
                <CurrencyInput
                  ref={cust}
                  max={9999.99}
                  value={tipAmount}
                  className={
                    selectedTipCard === 3
                      ? styles.customTip
                      : styles.customTipBlur
                  }
                  onChange={(value) => {
                    if (selectedTipCard === 3) {
                      onSelectTipCard(null, 3, value);
                    }
                  }}
                />
              ) : (
                <Edit className={styles.editIc} />
              )}
              <div className={styles.tipWatermark}>Custom</div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.subTipTag}>*All tip amounts are in LKR</div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Tip;
