import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Button1 from "../../../components/button_1/button_1";
import useStyles from "./header_styles";

export default function Header() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.header}>
      <img src={"/images/logo.png"} alt={"logo"} height={40} />
      <div className={classes.buttons}>
        <Button1 title={"Login"} onClick={() => history.push("/")} />
        <Button1
          title={"Contact Us"}
          onClick={() => history.push("/contact_us")}
        />
      </div>
    </div>
  );
}
