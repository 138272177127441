import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  header: {
    backgroundColor: "white",
    color: "#fff",
    padding: "10px",
    textAlign: "center",
    position: "fixed",
    top: 0,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  buttons: {
    position:'absolute',
    right:20
  }
});

export default useStyles;
