import { makeStyles } from "@mui/styles";
import { primary_color_2 } from "../../themes/colors";

const useStyles = makeStyles({
  outer_card: { padding: "10px", marginTop: "10px" },
  grid: { width: "100%" },
  item_outer: {
    display: "flex",
    flexDirection: "row",
    padding: "5px",
    border: `1px solid grey `,
    transition: "0.5s",
    cursor: "pointer",
    position: "relative",
    "&:hover": {
      border: `1px solid ${primary_color_2}`,
    },
    textAlign: "center"
  },
  name: {
    fontSize: "14px",
    textAlign: "left",
    width: "80%",
  },
  price: {
    fontSize: "14px",
    textAlign: "right",
    width: "20%",
  },
  other_charges: {
    fontSize: "12px",
    marginBottom: "5px",
  },
});

export default useStyles;
