let EscPosEncoder = require("esc-pos-encoder");
const { format } = require("date-fns");
const commaNumber = require("comma-number");
const { getChargeName, padSpaces, divideLines } = require("./printer_helper");

const SERVICE = "000018f0-0000-1000-8000-00805f9b34fb";
const WRITE = "00002af1-0000-1000-8000-00805f9b34fb";

let savedDevice;

export const MTPPrint = async (order, currency, inches) => {
  const orderId = `cey_${order.id}`;
  let encoder = new EscPosEncoder();
  let columnWidths;
  switch (inches) {
    case 2:
      columnWidths = [4, 18, 10];
      break;
    case 3:
      columnWidths = [7, 26, 11];
      break;
    default:
      columnWidths = [4, 18, 10];
      break;
  }
  const totalWidth = columnWidths.reduce((a, b) => a + b);
  const largeName = `\x1B\x61\x31\x1D\x21\x05${divideLines(
    order.restaurant.name,
    36
  )}\n`;

  const commands = [];
  commands.push(new TextEncoder("utf-8").encode(largeName));
  commands.push(
    encoder
      .initialize()
      .align("center")
      .size("normal")
      .bold(false)
      .line(order.restaurant.address, 40)
      .line(
        order.restaurant.mobile_no
          ? `${order.restaurant.mobile_no}${
              order.restaurant.fixed_line
                ? ` / ${order.restaurant.fixed_line}`
                : ""
            }`
          : ""
      )
      .align("left")
      .line("-".repeat(totalWidth))
      .align("center")
      .line("Order Receipt")
      .bold(true)
      .line(format(new Date(order.createdAt), "dd/MM/yyyy hh:mm a"))
      .bold(false)
      .line(`Order Id: ${orderId}`)
      .line(`Table No: ${order.table.number}`)
      .line(
        `Customer: ${order.customer_name || order.customer?.name || "Unknown"}`
      )
      .line(`Currency: ${currency ? `${currency}` : "LKR"}`)
      .align("left")
      .line("-".repeat(totalWidth))
      .bold(true)
      .text(padSpaces("QTY", columnWidths[0], "center"))
      .text(padSpaces("Item", columnWidths[1], "left"))
      .text(padSpaces(`Amount`, columnWidths[2], "right"))
      .bold(false)
      .encode()
  );

  order.order_items.forEach((item) => {
    commands.push(
      encoder
        .initialize()
        .text(padSpaces(item.quantity, columnWidths[0], "center"))
        .text(padSpaces(`${item.item.name}`, columnWidths[1], "left"))
        .text(
          padSpaces(
            commaNumber((item.quantity * item.unit_price).toFixed(2)),
            columnWidths[2],
            "right"
          )
        )
        .encode()
    );
  });

  commands.push(
    encoder.initialize().align("left").line(" ".repeat(totalWidth)).encode()
  );

  order.order_discounts.forEach((discount) => {
    commands.push(
      encoder
        .initialize()
        .text(padSpaces("", columnWidths[0], "center"))
        .text(padSpaces(getChargeName(discount), columnWidths[1], "left"))
        .text(
          padSpaces(
            commaNumber((discount.calculated_value * -1).toFixed(2)),
            columnWidths[2],
            "right"
          )
        )
        .encode()
    );
  });

  commands.push(
    encoder.initialize().align("left").line(" ".repeat(totalWidth)).encode()
  );

  order.order_charges.forEach((charge) => {
    commands.push(
      encoder
        .initialize()
        .text(padSpaces("", columnWidths[0], "center"))
        .text(padSpaces(getChargeName(charge), columnWidths[1], "left"))
        .text(
          padSpaces(
            commaNumber(charge.calculated_value.toFixed(2)),
            columnWidths[2],
            "right"
          )
        )
        .encode()
    );
  });

  commands.push(
    encoder.initialize().align("left").line(" ".repeat(totalWidth)).encode()
  );

  commands.push(
    encoder
      .initialize()
      .bold(true)
      .text(padSpaces("", columnWidths[0], "center"))
      .text(padSpaces("Total Amount", columnWidths[1], "left"))
      .text(
        padSpaces(
          commaNumber(order.order_amount.toFixed(2)),
          columnWidths[2],
          "right"
        )
      )
      .line("")
      .align("left")
      .bold(false)
      .line("-".repeat(totalWidth))
      .align("center")
      .bold(true)
      .line("Thank you. Come Again!")
      .align("center")
      .line("Powered by CeyPay")
      .line("info@ceypayapp.com")
      .line("")
      .line("")
      .encode()
  );

  if (window.flutter_inappwebview?.callHandler) {
    window.flutter_inappwebview?.callHandler("printReceipt", commands);
    return;
  }

  try {
    if (!savedDevice) {
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ services: [SERVICE] }],
      });
      savedDevice = device;
    }

    const server = await savedDevice.gatt.connect();
    const service = await server.getPrimaryService(SERVICE);
    const channel = await service.getCharacteristic(WRITE);
    for (const command of commands) {
      await channel.writeValue(command);
    }
    await savedDevice.gatt.disconnect();
  } catch (error) {
    window.alert(`Error while printing: ${error.message}`);
  }
};
