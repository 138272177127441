import { CssBaseline, ThemeProvider } from "@mui/material";
import { StoreProvider } from "easy-peasy";
import "./App.css";
import AppRouter from "./router/app_router";
import store from "./store/store";
import theme from "./theme";
import "../src/styles/globals.scss";
import "../src/styles/variables.scss";
import Loader from "./components/loader/loader";

function App() {
  return (
      <StoreProvider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppRouter />
          <Loader/>
        </ThemeProvider>
      </StoreProvider>
  );
}


export default App;
