import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MessageBox from "../components/message_box/message_box";
import RestaurantRouter from "./restaurant_router";
import AdminRouter from "./admin_router";
import CustomerRouter from "./customer_router";
import LoginPage from "../pages/restaurant_pages/login_page/login_page";
import TermsConditions from "../pages/public_pages/terms_conditions/terms_conditions";
import PrivacyPolicy from "../pages/public_pages/privacy_policy/privacy_policy";
import ContactUs from "../pages/restaurant_pages/login_page/contact_us";
import RefundPolicy from "../pages/public_pages/refund_policy/refund_policy";

export default function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route path="/terms_conditions" component={TermsConditions} />
        <Route path="/privacy_policy" component={PrivacyPolicy} />
        <Route path="/refund_policy" component={RefundPolicy} />
        <Route path="/contact_us" component={ContactUs} />
        <Route path="/restaurant" component={RestaurantRouter} />
        <Route path="/admin" component={AdminRouter} />
        <Route exact path="/" component={LoginPage} />
        <Route path="/" component={CustomerRouter} />
      </Switch>
      <MessageBox />
    </Router>
  );
}
