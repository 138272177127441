import styled from "styled-components";

export const ItemContainer = styled.div`
  width: 100%;
  padding-top: 16px;
`;

export const ItemSubContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: ${({ pb }) => (pb ? `${pb}px` : `100px`)};
  overflow-y: auto;
  max-height: ${({ height }) => `${height}px`};
`;

export const EachItemContainer = styled.div`
  flex-basis: 25%;
`;

export const CategoryHeader = styled.div`
  padding: 10px 20px;
  text-align: center;
  background-color: #fff;
  width: fit-content;
  border-radius: 40px;
  margin: auto;
  font-weight: 600;
  text-transform: capitalize;
`;
