import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  outer:{
    textAlign: "center",
    padding: "20px",
    maxWidth: '600px',
    margin: 'auto'
  },
  title:{
    fontWeight: "bold",
    fontSize: "1.7rem",
  },
  sub_title:{
    margin: "20px 0px",
  },
  text_field:{
    marginBottom: "10px !important",
  }
});

export default useStyles;
