import { Remove } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import commaNumber from "comma-number";
import { useStoreState } from "easy-peasy";
import React from "react";
import RestaurantApiService from "../../api_service/restaurant_api_service";

import useStyles from "./bill_charge_item_styles";

export default function BillChargeItem({ charge, isDiscount }) {
  const classes = useStyles();
  const isTablePadFullModeActive = useStoreState(
    (state) => state.isTablePadFullModeActive
  );
  const selectedOrder = useStoreState((state) => state.selectedOrder);

  const onRemoveCharge = async () => {
    await RestaurantApiService().removeChargeFromOrderCall(
      charge.order_id,
      charge.id
    );
    RestaurantApiService().getRestaurantOrderByIdCall(selectedOrder.id, true);
  };
  return (
    <div className={classes.row}>
      {!isTablePadFullModeActive && (
        <IconButton onClick={onRemoveCharge} className={classes.removeBtn}>
          <Remove fontSize="small" />
        </IconButton>
      )}

      <Box width={43} />
      <div className={classes.name}>{charge.charge.name}</div>
      <div className={classes.amount}>
        {commaNumber(
          (charge.calculated_value * (isDiscount ? -1 : 1)).toFixed(2)
        )}
      </div>
      <Box width={50} />
    </div>
  );
}
