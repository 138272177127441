import { makeStyles } from "@mui/styles";
import { background_color_2 } from "../../../themes/colors";

const useStyles = makeStyles({
  root: {
    height: "100vh",
    width: "100%",
    backgroundColor: background_color_2,
    display: "flex",
    flexDirection: "row",
  },
  left_bar: {
    backgroundColor: "white",
    height: "100%",
    width: "110px",
    boxShadow: "-2px -8px 8px 0px #88888896",
    overflowY: 'auto'
  },
  logo_outer: {
    // height: "80px",
    width: "80px",
    margin: "auto",
    marginTop: "10px",
    // backgroundColor: "#f87350 !important",
    // borderRadius: "30% !important",
  },
  right_bar: {
    height: "100%",
    width: "100%",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
  },
  main_row: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    zIndex:4
  },
  main_left_container: {
    height: "100%",
    width: "65%",
  },
  main_right_container: {
    height: "90%",
    width: "35%",
    backgroundColor: "#8080802b",
    borderRadius: "5px",
    padding: "10px",
    overflow: "auto",
  },
  add_btn: {
    width: "fit-content",
    alignSelf: "end"
  }
});

export default useStyles;
