import React, { useEffect, useState } from "react";
import useStyles from "./error_log_page_styles";
import {
  Collapse,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from "@mui/system";
import { format } from "date-fns";
import AdminApiService from "../../../api_service/admin_api_service";
import ReactJson from "react-json-view";
import AdminAppBar from "../../../components/admin_app_bar/app_bar";
import { Delete } from "@mui/icons-material";

export default function ErrorLogPage() {
  const classes = useStyles();
  const [state, setState] = useState({
    page: 1,
    limit: 15,
    data: [],
    totalPages: 1,
  });

  const getErrorLog = (blockLoading) => {
    AdminApiService()
      .getErrorLogCall({}, state.page, state.limit, blockLoading)
      .then((response) => {
        if (response.success) {
          setState({
            ...state,
            data: response.data.data,
            totalPages: response.data.pages_count,
            page: state.page,
          });
        }
      });
  };

  const deleteLog = (id) => {
    AdminApiService()
      .deleteErrorLogItem(id)
      .then((response) => {
        if (response.success) {
          getErrorLog();
        }
      });
  };

  useEffect(() => {
    getErrorLog(false);
  }, [state.page, state.limit]); // eslint-disable-line

  return (
    <div className={classes.root}>
      <div className={classes.right_bar}>
        <AdminAppBar title={"Error Log"} />
        <div className={classes.main_row}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell style={{ minWidth: 170 }} align="left">
                    Date
                  </TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="left">Type</TableCell>
                  <TableCell style={{ minWidth: 80 }}  align="center">Type Id</TableCell>
                  <TableCell align="left">Method</TableCell>
                  <TableCell align="left">Path</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.data.map((error) => (
                  <Row key={error.id} error={error} deleteLog={deleteLog} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div
          style={{
            width: "100%",
            justifyContent: "right",
            display: "flex",
            margin: "20px 20px 0 0",
          }}
        >
          <Pagination
            count={state.totalPages}
            page={state.page}
            onChange={(event, newPage) => {
              setState({ ...state, page: newPage });
            }}
          />
        </div>
      </div>
    </div>
  );
}

function Row({ error, deleteLog }) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand order"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          {format(new Date(error.createdAt), "yyyy-MM-dd hh:mm aaa")}
        </TableCell>
        <TableCell
          align="center"
          style={{
            fontWeight: "bold",
            color:
              error.status >= 400 && error.status < 500
                ? "orange"
                : error.status >= 500 && error.status < 600
                ? "red"
                : "black",
          }}
        >
          {error.status}
        </TableCell>
        <TableCell align="left">{error.type}</TableCell>
        <TableCell align="center">{error.type_id || "-"}</TableCell>
        <TableCell align="left">{error.method}</TableCell>
        <TableCell align="left">{error.path}</TableCell>
        <TableCell align="center">
          <IconButton
            onClick={() => {
              deleteLog(error.id);
            }}
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Request
              </Typography>
              <ReactJson
                displayDataTypes={false}
                quotesOnKeys={false}
                collapsed={2}
                src={error.request}
              />
              <Typography variant="h6" gutterBottom component="div">
                Response
              </Typography>
              <ReactJson
                displayDataTypes={false}
                quotesOnKeys={false}
                collapsed={2}
                src={error.response}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
