import { Card, Grid, Avatar } from "@mui/material";
import styles from "../../../styles/accountDetails.module.scss";
import { useEffect, useState } from "react";
import CustomInput from "../../../../src/components/custom_input";
import CustomButton from "../../../../src/components/custom_button";
import Layout from "../../../../src/components/layout";
import {
  getInitialsByName,
  getFirstTwoNamesFromName,
  getImageUrl,
} from "../../../utils/helpers";
import CustomerApiService from "../../../api_service/customer_api_service";
import UserHooks from "../../../utils/user_hooks";
import { DoneAllRounded, Warning } from "@mui/icons-material";

export default function ProfilePage() {
  const { getUser, setUser } = UserHooks();
  const [user, setStateUser] = useState();
  const getUserFromLocalStorage = async () => {
    const user = await getUser();
    setStateUser(user);
    setState({
      ...state,
      name: user.name,
      email: user.email,
      image: user.image,
    });
  };
  useEffect(() => {
    getUserFromLocalStorage();
  }, []); // eslint-disable-line

  const [state, setState] = useState({
    name: "",
    email: "",
    image: "",
    nameChange: false,
    emailChange: false,
    imageChange: false,
  });

  const [alert, setAlert] = useState({ open: false, type: "success" });
  const [imageFile, setImageFile] = useState(null);

  const handleImageChange = (e) => {
    setState({ ...state, imageChange: true });
    setImageFile(e.target.files[0]);
  };

  const setAlertWithTimeOut = (type) => {
    setAlert({
      open: true,
      type: type,
    });
    setTimeout(() => {
      setAlert({
        open: false,
        type: type,
      });
    }, 5000);
  };

  const submitData = async () => {
    const formData = new FormData();
    if (state.nameChange) {
      formData.append("name", state.name);
    }
    if (state.emailChange) {
      formData.append("email", state.email);
    }
    if (state.imageChange) {
      formData.append("file", imageFile);
    }
    const response = await CustomerApiService().updateProfileCall(formData);
    if (response.success) {
      setAlertWithTimeOut("success");
      setUser(response.data);
      setState({
        ...state,
        nameChange: false,
        emailChange: false,
        imageChange: false,
      });
    } else {
      setAlertWithTimeOut("error");
    }
  };

  const getImage = () => {
    if (imageFile)
      return (
        <Avatar
          style={{ height: 70, width: 70 }}
          src={URL.createObjectURL(imageFile)}
        />
      );
    if (state.image)
      return (
        <Avatar
          style={{ height: 70, width: 70 }}
          src={getImageUrl(state.image)}
        />
      );
    if (state.name)
      return (
        <div className={styles.avatarImage}>
          <Avatar style={{ height: 70, width: 70 }}>
            {getInitialsByName(state.name)}
          </Avatar>
        </div>
      );
    return (
      <img
        alt="profile pict"
        src={"/images/avatar.svg"}
        width="70"
        height="70"
        className={styles.image}
      />
    );
  };

  return (
    <>
      <Layout navbar={user} user={user}>
        <div className={styles.profileHeaderContainer}>Profile</div>

        <Grid item xs={12}>
          {alert.open && (
            <Card
              style={{
                padding: "15px 15px",
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <div className={styles.greenTick}>
                {alert.type === "error" ? (
                  <Warning color="error" />
                ) : (
                  <DoneAllRounded color="success" />
                )}
              </div>
              <div className={styles.bannerText}>
                {alert.type === "error"
                  ? "Unable to update Profile!"
                  : "Your profile details have been updated"}
              </div>
            </Card>
          )}
          <Card style={{ padding: "46px 25px 30px 25px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent={"center"}>
                  <div className={styles.imageContainer}>
                    {getImage()}
                    <input
                      className={styles.imageInput}
                      type="file"
                      capture={"user"}
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.userName}>
                  {getFirstTwoNamesFromName(state.name)}
                </div>
                <div className={styles.userEmail}>{state.email}</div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.fieldLabel}> Full Name</div>
                <CustomInput
                  id="account-full-name"
                  onChange={(event) => {
                    setState({
                      ...state,
                      name: event.target.value,
                      nameChange: true,
                    });
                  }}
                  value={state.name}
                />
              </Grid>

              <Grid item xs={12}>
                <div className={styles.fieldLabel}> Email</div>
                <CustomInput
                  id="account-email"
                  onChange={(event) => {
                    setState({
                      ...state,
                      email: event.target.value,
                      emailChange: true,
                    });
                  }}
                  value={state.email}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomButton
                  onClick={submitData}
                  disabled={
                    !state.nameChange &&
                    !state.emailChange &&
                    !state.imageChange
                  }
                >
                  Save Changes
                </CustomButton>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Layout>
    </>
  );
}
