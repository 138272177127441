import axios from "axios";
import { api_base_url } from "../config/config";
import store from "../store/store";
import { getClientTimeZone } from "../utils/get_time_zone";
import { createLogCall } from "./logger_api";
import queryString from "query-string";
import { showAlert } from "../components/message_box/show_alert";
import { setLoading } from "../components/loader/loader";

const actions = store.getActions();

const handleApiCall = async (method, url, body, blockLoading) => {
  if (!blockLoading) {
    setLoading(true);
  }
  const token = localStorage.getItem("sn_rest_admin_access_token") || "";
  try {
    const response = await axios({
      baseURL: api_base_url,
      url: url,
      timeout: 60000,
      method: method,
      data: body,
      headers: {
        "Content-Type": "application/json",
        "Bypass-Tunnel-Reminder": "yes",
        Authorization: token,
        "Client-Timezone": getClientTimeZone(),
      },
    });
    setLoading(false);
    return response.data;
  } catch (error) {
    if (error.response) {
      setLoading(false);
      createLogCall(
        "ADMIN_FE",
        null,
        error.response.status,
        method,
        url,
        body,
        error.response.data
      );
      switch (error.response.status) {
        case 401:
          localStorage.removeItem("sn_rest_admin_access_token");
          window.location.href = "/admin";
          showAlert({
            head: "Not authorized",
            body: "Please log in again to continue!",
          });
          return {
            alert_showed: true,
            success: false,
            data: null,
            message: "not authorized",
          };
        case 422:
          showAlert({
            head: "Unable to proceed with validation errors",
            body: error.response.data.data[0]
              ? error.response.data.data[0].message
              : "Please check form fields and try again",
          });
          return {
            alert_showed: true,
            success: false,
            data: null,
            message: "Validation Errors",
          };
        case 500:
          showAlert({
            head: "Something went wrong",
            body: "Please contact system admin",
          });
          console.log(error.response.data);
          return {
            success: false,
            data: null,
            message: "Internal Server Error",
          };
        default:
          showAlert({
            head: "Something went wrong",
            body: "Please contact system admin",
          });
          console.log("unhandled status code", error.response.data);
          return {
            success: false,
            data: null,
            message: "Internal Server Error",
          };
      }
    } else {
      createLogCall("ADMIN_FE", null, 500, method, url, body, error);
      console.log("no response from server", error);
      window.location.href = "/admin";
      showAlert({
        head: "Something went wrong",
        body: "Please contact system admin",
      });
      return {
        success: false,
        data: null,
        message: "no response from server",
      };
    }
  }
};

const loginCall = async (email, password) => {
  const response = await handleApiCall("POST", "/auth/admin/login", {
    email: email,
    password: password,
  });
  if (response.success) {
    localStorage.setItem(
      "sn_rest_admin_access_token",
      response.data.access_token
    );
  }
  return response;
};

const getAllRestaurantsCall = async () => {
  const response = await handleApiCall("GET", "/api/admin/restaurants");
  if (response.success) {
    actions.setAdminRestaurants(response.data);
  }
  return response;
};

const getSpecificRestaurantCall = async (id) => {
  const response = await handleApiCall("GET", `/api/admin/restaurants/${id}`);
  if (response.success) {
    actions.setRestaurantToEdit(response.data);
  }
  return response;
};

const createRestaurantCall = async (data) => {
  const response = await handleApiCall("POST", `/api/admin/restaurants`, data);
  return response;
};

const updateRestaurantCall = async (data, id) => {
  const response = await handleApiCall(
    "PUT",
    `/api/admin/restaurants/${id}`,
    data
  );
  return response;
};

const updateRestaurantStatusCall = async (data, id) => {
  const response = await handleApiCall(
    "PUT",
    `/api/admin/restaurant_status/${id}`,
    data
  );
  return response;
};

const getOrdersList = async (filter, page, limit) => {
  const response = await handleApiCall(
    "POST",
    `/api/admin/orders?page=${page}&limit=${limit}`,
    filter
  );
  return response;
};

const getErrorLogCall = async (filter, page, limit, blockLoading) => {
  const response = await handleApiCall(
    "POST",
    `/api/admin/error_log?page=${page}&limit=${limit}`,
    filter,
    blockLoading
  );
  return response;
};

const deleteErrorLogItem = async (id) => {
  const response = await handleApiCall(
    "DELETE",
    `/api/admin/error_log/${id}`,
    {}
  );
  return response;
};

const updateConfig = async (data) => {
  const response = await handleApiCall("PUT", `/api/admin/config`, data);
  return response;
};

const getConfig = async () => {
  const response = await handleApiCall("GET", `/api/admin/config`);
  return response;
};

const getCurrencies = async () => {
  const response = await handleApiCall("GET", `/api/admin/currencies`);
  return response;
};

const getPayoutsList = async (filter, page, limit) => {
  const query = queryString.stringify({ ...filter, page, limit });
  const response = await handleApiCall("GET", `/api/admin/payouts?${query}`);
  return response;
};

const searchPayout = async (body) => {
  const response = await handleApiCall(
    "POST",
    `/api/admin/payouts/filter`,
    body
  );
  return response;
};

const createPayout = async (body) => {
  const response = await handleApiCall("POST", `/api/admin/payouts`, body);
  return response;
};

const payPayout = async (payoutId, body) => {
  const response = await handleApiCall(
    "POST",
    `/api/admin/payouts/${payoutId}/pay`,
    body
  );
  return response;
};

const getAdminSubscriptions = async (restaurantId) => {
  const response = await handleApiCall(
    "GET",
    `/api/admin/subscriptions/restaurants/${restaurantId}/details`
  );
  return response;
};

const updateAdminSubscriptionDetails = async (restaurantId, data) => {
  const response = await handleApiCall(
    "PUT",
    `/api/admin/subscriptions/restaurants/${restaurantId}/details`,
    data
  );
  return response;
};

const approveAdminSubscription = async (subscriptionId, restaurantId) => {
  const response = await handleApiCall(
    "PATCH",
    `/api/admin/subscriptions/${subscriptionId}/restaurants/${restaurantId}/approve`
  );
  return response;
};

export default function AdminApiService() {
  return {
    loginCall: loginCall,
    getAllRestaurantsCall: getAllRestaurantsCall,
    getSpecificRestaurantCall: getSpecificRestaurantCall,
    createRestaurantCall: createRestaurantCall,
    updateRestaurantCall: updateRestaurantCall,
    updateRestaurantStatusCall: updateRestaurantStatusCall,
    getOrdersList: getOrdersList,
    getErrorLogCall: getErrorLogCall,
    deleteErrorLogItem: deleteErrorLogItem,
    updateConfig: updateConfig,
    getConfig: getConfig,
    getCurrencies: getCurrencies,
    getPayoutsList: getPayoutsList,
    searchPayout: searchPayout,
    createPayout: createPayout,
    payPayout: payPayout,
    getAdminSubscriptions: getAdminSubscriptions,
    updateAdminSubscriptionDetails: updateAdminSubscriptionDetails,
    approveAdminSubscription: approveAdminSubscription,
  };
}
