import { Avatar, IconButton } from "@mui/material";
import {
  Delete,
  Edit,
  RestoreFromTrash,
  SettingsOutlined,
} from "@mui/icons-material";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useRef, useState } from "react";
import AdminPopup from "./admin_popup";
import useStyles from "./app_bar_styles";
import SettingsMenu from "./settings_menu";
import useWindowSize from "../../utils/use_window_size";
import RestaurantApiService from "../../api_service/restaurant_api_service";
import { getImageUrl } from "../../utils/helpers";

export default function AppBar({ type }) {
  const classes = useStyles();
  const restaurant = useStoreState((state) => state.restaurant);
  const selectedItemCategory = useStoreState(
    (state) => state.selectedItemCategory
  );
  const isAdminView = useStoreState((state) => state.currentView === "ADMIN");

  const setOpenEditCategory = useStoreActions(
    (actions) => actions.setOpenEditCategory
  );
  const setCategoryToEdit = useStoreActions(
    (actions) => actions.setCategoryToEdit
  );
  const setOpenRestaurantDetailsModal = useStoreActions(
    (actions) => actions.setOpenRestaurantDetailsModal
  );

  const [state, setState] = useState({
    openMenu: false,
    openAdminPopup: false,
  });

  const anchorRef = useRef(null);

  const { isTab } = useWindowSize();

  const handleToggle = () => {
    setState({ ...state, openMenu: !state.openMenu });
  };

  const showAdminPopup = () => {
    setState({ ...state, openAdminPopup: true });
  };

  const onEditCategory = () => {
    setCategoryToEdit(selectedItemCategory);
    setOpenEditCategory(true);
  };

  const onDeleteCategory = () => {
    RestaurantApiService().updateItemCategoryCall(
      { is_active: !selectedItemCategory.is_active },
      selectedItemCategory.id
    );
  };

  const openRestaurantDetailsModal = () => {
    setOpenRestaurantDetailsModal(true);
  };

  return restaurant ? (
    <div className={classes.root}>
      <div style={{ width: "100%" }}>
        {isTab ? (
          <div className={classes.main_text}>{restaurant.name}</div>
        ) : (
          <div className={classes.main_text}>
            {selectedItemCategory ? selectedItemCategory.name : restaurant.name}
            {selectedItemCategory && isAdminView && (
              <>
                <IconButton
                  onClick={onEditCategory}
                  style={{ marginLeft: "10px" }}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={onDeleteCategory}
                  style={{ marginLeft: "10px" }}
                >
                  {selectedItemCategory.is_active ? (
                    <Delete />
                  ) : (
                    <RestoreFromTrash />
                  )}
                </IconButton>
              </>
            )}
          </div>
        )}
        {!isTab && (
          <div className={classes.sub_text}>
            {selectedItemCategory
              ? selectedItemCategory.description
              : restaurant.address}
          </div>
        )}
      </div>
      <div
        className={
          isTab
            ? classes.restaurant_name_card_mobile
            : classes.restaurant_name_card
        }
        onClick={openRestaurantDetailsModal}
      >
        <Avatar src={getImageUrl(restaurant.logo)} />
        {!isTab && (
          <div className={classes.restNameContainer}>{restaurant.name}</div>
        )}
      </div>
      {
        <IconButton
          ref={anchorRef}
          onClick={handleToggle}
          className={classes.setting_button}
        >
          <SettingsOutlined className={classes.setting_icon} />
        </IconButton>
      }
      {
        <SettingsMenu
          open={state.openMenu}
          anchorRef={anchorRef}
          setOpenMenu={(value) => {
            setState({ ...state, openMenu: value });
          }}
          showAdminPopup={showAdminPopup}
          type={type}
        />
      }
      <AdminPopup
        open={state.openAdminPopup}
        setOpen={(value) => {
          setState({ ...state, openAdminPopup: value });
        }}
      />
    </div>
  ) : (
    <div />
  );
}
