import React from "react";

const CurrencyInput = React.forwardRef(({ value, max, onChange, className, ...rest }, ref) => {
  // regex filters
  const regexFloat = /(^\d+\.\d{1,2}$|^\d+\.$)/; // 123. or 123.1 or 123.12
  const regexFloatNoDecimal = /^\d+\.$/; // 1234.
  const regexFloatSingleDecimal = /^\d+\.\d$/; // 123.1
  const regexInt = /(^\d+$|^$)/; // 1212 or empty string

  // check whether valid input ( 1234 or 1234.12)
  const isValid = (value) => {
    if (value.includes(".")) return regexFloat.test(value);
    else return regexInt.test(value);
  };

  const handleOnChange = (e) => {
    const value = e.target.value;
    if (!isValid(value)) return;
    if (max && Number(value) > max) return;
    onChange(value);
  };

  // format the input value
  const handleOnBlur = (e) => {
    let value = e.target.value;
    if (!value) {
      value = "0.00";
    } else if (regexInt.test(value)) {
      value = `${value}.00`;
    } else if (regexFloatSingleDecimal.test(value)) {
      value = `${value}0`;
    } else if (regexFloatNoDecimal.test(value)) {
      value = `${value}00`;
    }

    onChange(value);
  };

  return (
    <input
      value={value}
      ref={ref}
      type="text"
      inputMode="decimal"
      className={className}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      {...rest}
    />
  );
});

export default CurrencyInput;
