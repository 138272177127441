import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import QRScanPage from "../pages/customer_pages/qr_scan_page/qr_scan_page";
import LandingPage from "../pages/customer_pages/landing_page/landing_page";
import EditTablePage from "../pages/customer_pages/edit_table_page/edit_table_page";
import RestaurantInformation from "../pages/customer_pages/restaurant_information/restaurant_information";
import OrderPage from "../pages/customer_pages/order_page/order_page";
import MenuPage from "../pages/customer_pages/menu_page/menu_page";
import CheckPaymentPage from "../pages/customer_pages/order_page/check_payment";
import PaymentSuccessPage from "../pages/customer_pages/order_page/payment_success";
import OrderHistoryPage from "../pages/customer_pages/order_history/order_history_page";
import ProfilePage from "../pages/customer_pages/profile_page/profile_page";
import BillInfoPage from "../pages/customer_pages/bill_info_page/bill_info_page";
import MenuOnlyPage from "../pages/customer_pages/menu_only_page/menu_only_page";

export default function CustomerRouter() {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}customer/order_history`}
        component={OrderHistoryPage}
      />
      <Route exact path={`${match.path}customer/scan_qr`} component={QRScanPage} />
      <Route exact path={`${match.path}customer/profile`} component={ProfilePage} />
      <Route
        exact
        path={`${match.path}restaurants/:restaurant_code/tables/:table_no/landing`}
        component={LandingPage}
      />
      <Route
        exact
        path={`${match.path}restaurants/:restaurant_code/tables/:table_no/menu`}
        component={MenuPage}
      />
      <Route
        exact
        path={`${match.path}restaurants/:restaurant_code/menu`}
        component={MenuOnlyPage}
      />
      <Route
        exact
        path={`${match.path}restaurants/:restaurant_id/tables/:table_id/orders/:order_id`}
        component={OrderPage}
      />
      <Route
        exact
        path={`${match.path}restaurants/:restaurant_id/tables/:table_id/orders/:order_id/payment_pending`}
        component={CheckPaymentPage}
      />
      <Route
        exact
        path={`${match.path}restaurants/:restaurant_id/tables/:table_id/orders/:order_id/success`}
        component={PaymentSuccessPage}
      />
      <Route
        exact
        path={`${match.path}restaurants/:restaurant_code/change_table`}
        component={EditTablePage}
      />
      <Route
        exact
        path={`${match.path}restaurants/:restaurant_code/information`}
        component={RestaurantInformation}
      />
      <Route
        exact
        path={`${match.path}restaurants/:restaurant_id/tables/:table_id/orders/:order_id/info`}
        component={BillInfoPage}
      />
    </Switch>
  );
}
