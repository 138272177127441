import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  outer: {
    display: "flex",
    flexDirection: "row",
    padding: "2px 10px",
    gap: 20,
    alignItems: "center",
  },
  outer_warning: {
    backgroundColor: "rgba(255, 215, 157, 1)",
    color: "rgba(145, 106, 0, 1)",
  },
  outer_critical: {
    backgroundColor: "rgba(254, 211, 209, 1)",
    color: "rgba(215, 44, 13, 1)",
  },
  message: {
    width: "100%",
  },
  button_warning: {
    color: "white !important",
    backgroundColor: "rgba(145, 106, 0, 1) !important",
    "&:hover": {
      backgroundColor: "rgba(145, 106, 0, 1) !important",
    },
  },
  button_critical: {
    color: "white !important",
    backgroundColor: "rgba(215, 44, 13, 1) !important",
    "&:hover": {
      backgroundColor: "rgba(215, 44, 13, 1) !important",
    },
  },
});

export default useStyles;
