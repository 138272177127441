import React from "react";
import Charges from "../charges/charges";
import OrdersTab from "../orders_tab/orders_tab";
import OrderDetails from "../order_details/order_details";
import TablesTab from "../tables_tab/tables_tab";
import TabView from "../tab_view/tab_view";
import * as Styled from "../../pages/restaurant_pages/home_page/home.styled";
import BriefNavBar from "../restuarant/common/BriefNavBar";
import Discounts from "../discounts/discounts";

export default function MobileView() {
  return (
    <div
      style={{
        padding: 5,
        backgroundColor:'white',
        height:'100vh',
        overflowY:'auto'
      }}
    >
      <Styled.RightHeaderContainer>
        <BriefNavBar />
      </Styled.RightHeaderContainer>
      <TabView
        tables={
          <div style={{ padding: 15 }}>
            <TablesTab />
          </div>
        }
        orders={<OrdersTab />}
        orderDetails={<OrderDetails />}
        charges={<Charges />}
        discounts={<Discounts />}
      />
    </div>
  );
}
