import clsx from "clsx";
import { useStoreActions } from "easy-peasy";
import React, { useState } from "react";
import { getImageUrl } from "../../utils/helpers";
import FadeInAnimatedDiv from "../animated_div/fade_in_animated_div";
import useStyles from "./item_category_styles";

export default function ItemCategory({ category, isSelected }) {
  const classes = useStyles();
  const [isHover, setHover] = useState(false);
  const setSelectedItemCategory = useStoreActions(
    (actions) => actions.setSelectedItemCategory
  );

  const handleTruncateLongNames = (name) => {
    if (name.length < 20) return name;

    return `${name.substring(0, 20)}...`;
  };
  return (
    <div
      onClick={() => setSelectedItemCategory(category)}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={clsx(
        classes.outer,
        (isHover || isSelected) && classes.outer_hover
      )}
    >
      <div
        className={classes.item_category}
        style={{
          backgroundImage: category.is_active
            ? `url(${getImageUrl(category.image)})`
            : `url(/images/inactive.png)`,
        }}
      >
        <FadeInAnimatedDiv>
          <div className={classes.category_name}>
            {handleTruncateLongNames(category.name)}
          </div>
        </FadeInAnimatedDiv>
      </div>
    </div>
  );
}
