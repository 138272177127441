import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import React from "react";
import useStyles from "./quantity_input_styles";

export default function QuantityInput({ value, onChange }) {
  const classes = useStyles();
  return (
    <div className={classes.row}>
      <Button
        onClick={() => {
          if (value > 1) {
            onChange(value - 1);
          }
        }}
      >
        <RemoveCircle fontSize="large" />
      </Button>
      <TextField
        type="text"
        inputProps={{
          readOnly: true,
          style: { textAlign: "center", fontWeight: "bold", fontSize: "20px", width:60, padding:'5px'},
        }}
        value={value}
        className={classes.input}
      />
      <Button
        onClick={() => {
          onChange(value + 1);
        }}
      >
        <AddCircle fontSize="large" />
      </Button>
    </div>
  );
}
