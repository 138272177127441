import { Card, Grid } from "@mui/material";
import commaNumber from "comma-number";
import { useStoreActions } from "easy-peasy";
import React, { useState } from "react";
import ZoomInAnimatedDiv from "../../../components/animated_div/zoom_in_animated_div";
import { getImageUrl } from "../../../utils/helpers";
import useWindowSize from "../../../utils/use_window_size";
import useStyles from "./item_styles";

export default function ItemsTab({
  category,
  children,
  value,
  index,
  restaurant_code,
  table_no,
  currency,
  ...other
}) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{
        height: "calc(100vh - 265px)",
      }}
    >
      {value === index && (
        <Grid container style={{ width: "100%", overflow: "clip", paddingBottom:60 }}>
          {category.items.map((item, index) => (
            <CustomerItem
              restaurant_code={restaurant_code}
              table_no={table_no}
              key={item.id}
              item={item}
              index={index}
              currency={currency}
            />
          ))}
        </Grid>
      )}
    </div>
  );
}

function CustomerItem({ item, index, restaurant_code, table_no, currency }) {
  const classes = useStyles();
  const [isHover, setHover] = useState(false);
  const { width } = useWindowSize();
  const setItem = useStoreActions((actions) => actions.setCustomerItemToAdd);
  const setOpen = useStoreActions(
    (actions) => actions.setCustomerOpenAddItemPopup
  );
  const addItemToOrder = () => {
    setItem({
      item: item,
      restaurant_code: restaurant_code,
      table_no: table_no,
      currency: currency,
    });
    setOpen(true);
  };

  return (
    <Grid
      style={{ backgroundColor: "transparent" }}
      item
      xs={width <= 400 ? 12 : width <= 600 ? 6 : 4}
    >
      <ZoomInAnimatedDiv delay={index} show={item.id}>
        <Card
          onClick={addItemToOrder}
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          elevation={isHover ? 15 : 10}
          className={classes.item_outer}
          style={{
            backgroundImage: `url(${getImageUrl(item.image)})`,

            margin: "10px",
          }}
        >
          <PriceTag item={item} currency={currency} />
          <div className={classes.name}>{item.name}</div>
        </Card>
      </ZoomInAnimatedDiv>
    </Grid>
  );
}

const PriceTag = ({ item, currency }) => {
  const classes = useStyles();
  const getDiscountText = () => {
    if (item.discount_type === "PERCENTAGE") {
      return `${item.discount}% off`;
    }
    return `${item.discount.toFixed(2)} ${currency} off`;
  };
  return (
    <div>
      <div className={classes.price}>
        <span>{`${commaNumber(item.unit_price.toFixed(2))} ${currency}`}</span>{" "}
      </div>
      {item.discount ? (
        <div className={classes.discount}>
          <span>{getDiscountText()}</span>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};
