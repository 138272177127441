import { Button, Card, Collapse, MenuItem, TextField } from "@mui/material";
import { format, min } from "date-fns";
import React, { useEffect, useState } from "react";
import AdminApiService from "../../api_service/admin_api_service";
import { PayoutsStartDate, PayoutStatuses } from "../../utils/constants";
import useStyles from "./payouts_filter_styles";
import useWindowSize from "../../utils/use_window_size";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export default function PayoutsFilter({ restaurant, onFilterChanged, filter }) {
  const classes = useStyles();

  const { isTab } = useWindowSize();
  const [openFilter, setOpenFilter] = useState(false);
  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const [restaurants, setRestaurants] = useState([]);

  const getAllRestaurants = async () => {
    const response = await AdminApiService().getAllRestaurantsCall();
    if (response.success) {
      setRestaurants(response.data);
    }
  };

  useEffect(() => {
    if (restaurant) {
      getAllRestaurants();
    }
  }, []); // eslint-disable-line

  return (
    <Card className={classes.outer}>
      {isTab && (
        <div className={classes.filter_header} onClick={toggleFilter}>
          Payouts Filters
          <Button>{openFilter ? <ExpandLess /> : <ExpandMore />}</Button>
        </div>
      )}
      <Collapse in={openFilter || !isTab} timeout="auto" unmountOnExit>
        <div className={isTab ? classes.col : classes.row}>
          <TextField
            style={{
              minWidth: "150px",
            }}
            name="from"
            type="date"
            label="From"
            InputLabelProps={{ shrink: true }}
            value={filter.from}
            onChange={(event) => {
              onFilterChanged("from", event.target.value);
            }}
            inputProps={{
              max: format(
                filter.to ? min([new Date(), new Date(filter.to)]) : new Date(),
                "yyyy-MM-dd"
              ),
              min: format(PayoutsStartDate, "yyyy-MM-dd"),
            }}
          />
          <TextField
            style={{
              minWidth: "150px",
            }}
            name="to"
            type="date"
            label="To"
            InputLabelProps={{ shrink: true }}
            value={filter.to}
            onChange={(event) => {
              onFilterChanged("to", event.target.value);
            }}
            inputProps={{
              min: filter.from,
              max: format(new Date(), "yyyy-MM-dd"),
            }}
          />
          {restaurant && (
            <TextField
              style={{
                minWidth: "200px",
              }}
              select
              value={filter.restaurant_id}
              label="Restaurant"
              onChange={(event) => {
                onFilterChanged("restaurant_id", event.target.value);
              }}
            >
              <MenuItem value={-1}>All Restaurants</MenuItem>
              {restaurants.map((restaurant) => (
                <MenuItem value={restaurant.id} key={restaurant.id}>
                  {restaurant.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          <TextField
            style={{
              minWidth: "150px",
            }}
            select
            value={filter.status}
            label="Payout Status"
            onChange={(event) => {
              onFilterChanged("status", event.target.value);
            }}
          >
            <MenuItem value={-1}>Any Status</MenuItem>
            {Object.values(PayoutStatuses).map((status, index) => (
              <MenuItem value={status} key={index}>
                {status}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </Collapse>
    </Card>
  );
}
