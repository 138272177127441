import { useRef } from "react";
import { Card } from "@mui/material";
import styles from "../../../styles/orderHistory.module.scss";
import { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import Layout from "../../../components/layout";
import { useHistory } from "react-router-dom";
import CustomerApiService from "../../../api_service/customer_api_service";
import { Box } from "@mui/system";
import UserHooks from "../../../utils/user_hooks";
import { ArrowForwardIos } from "@mui/icons-material";
import { getImageUrl } from "../../../utils/helpers";
const { format } = require("date-fns");

export default function OrderHistoryPage() {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [user, setUser] = useState();

  const paginationRef = useRef({ currentPage: 0, total: 0 });
  const loadingRef = useRef(false);

  const { getUser } = UserHooks();

  const updateLoading = (value) => {
    setLoading(value);
    loadingRef.current = value;
  };

  const fetchReviews = async (page) => {
    const user = await getUser();
    setUser(user);
    updateLoading(true);
    const res = await CustomerApiService().getOrderHistoryCall(page);
    if (res.success) {
      setOrders((state) => [...state, ...res.data.orders]);
      paginationRef.current = {
        currentPage: res.data.page,
        total: res.data.pages_count,
      };
    }
    updateLoading(false);
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      paginationRef.current.currentPage === paginationRef.current.total ||
      loadingRef.current
    )
      return;
    fetchReviews(paginationRef.current.currentPage + 1);
  };

  useEffect(() => {
    fetchReviews(1);
  }, []); // eslint-disable-line

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []); // eslint-disable-line

  return (
    <Layout footer user={user} navbar={user}>
      {/* <CustomButton
        onClick={() => {
          history.push("/customer/scan_qr");
        }}
        mt={15}
        variant="outlined"
      >
        Scan QR Code and Pay
      </CustomButton> */}
      <Box height={10} />
      {orders.length > 0 && (
        <div className={styles.orderHistryHeader}>Order history</div>
      )}
      {orders.length === 0 && !loading && (
        <div className={styles.emptyOrdersTitle}>
          Please make your payment soon..
        </div>
      )}
      <Card>
        {orders.map((order, key) => {
          return <OrderCard key={key} order={order} />;
        })}
      </Card>
      <div className={styles.loading}>
        <BeatLoader loading={loading} />
      </div>
    </Layout>
  );
}

function OrderCard({ order }) {
  const history = useHistory();

  const showBill = (order) => {
    history.push(
      `/restaurants/${order.restaurant.id}/tables/${order.table.id}/orders/${order.id}/info`
    );
  };

  return (
    <div className={styles.orderCard} onClick={() => showBill(order)}>
      <div className={styles.textContainer}>
        <div className={styles.priceContainer}>
          {`${(order.order_amount + order.tip_amount).toFixed(2)} ${
            order.restaurant.currency
          }`}
        </div>
        <div className={styles.dateContainer}>
          {format(Date.parse(order.date), "d MMM yyyy")}
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.logoContainer}>
          <img
            src={getImageUrl(order.restaurant.logo)}
            alt="restaurant_logo"
            height={50}
          />
        </div>

        <div className={styles.arrowContainer}>
          <ArrowForwardIos color="disabled"/>
        </div>
      </div>
    </div>
  );
}
