import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  backdrop: {
    zIndex: 10,
    background: "#000000c7 !important",
  },
  loader_outer: {
    backgroundColor: "#00000033",
    zIndex: 11,
    height: 70,
    width: 70,
    padding: 10,
    borderRadius: "50%",
  },
});

export default useStyles;
