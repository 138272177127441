import { useEffect, useState } from "react";

const tabWidth = 800;

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    isTab: false,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isTab: window.innerWidth < tabWidth,
      });
    }
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize.height
    ? windowSize
    : {
        width: 400,
        height: 600,
        isTab: false,
      };
}
