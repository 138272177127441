import styled from "styled-components";

export const MainContainer = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AvatarContainer = styled.div`
  /* position: absolute;
  left: 10px; */
`;

export const RestNameContainer = styled.div`
  color: black;
  font-size: 24px;
  cursor: pointer;
  // text-align: center;
`;

export const ControlContainer = styled.div`
  /* background-color: red;
  position: absolute;
  right: 10px; */
`;
