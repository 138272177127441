import { Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { QrReader } from "react-qr-reader";
import { useHistory } from "react-router-dom";
import useStyles from "./qr_scan_page_styles";
import styles from "../../../styles/scanQr.module.scss";
import { Box } from "@mui/system";
import CustomButton from "../../../components/custom_button";

export default function QRScanPage() {
  const history = useHistory();
  const classes = useStyles();
  const onQrError = (err) => {};
  const onQrScan = (result) => {
    if (
      result.includes("/restaurants/") &&
      result.includes("/tables/") &&
      result.includes("/landing")
    ) {
      window.open(result, "_self");
    }
  };

  const [state, setState] = useState({
    restaurantCode: "",
    tableNo: "",
  });

  const onSubmit = () => {
    if (state.restaurantCode && state.tableNo) {
      history.push(
        `/restaurants/${state.restaurantCode}/tables/${state.tableNo}/landing`
      );
    }
  };

  return (
    <div className={classes.outer}>
      <div className={classes.title}>Welcome to CeyPay Restaurants</div>
      <div>
        <div className={classes.sub_title}>Please scan QR code on Table</div>
        <QrReader
          scanDelay={300}
          onResult={(result, error) => {
            if (!!result) {
              onQrScan(result.getText());
            }
            if (!!error) {
              onQrError(error);
            }
          }}
          style={{ width: "100%" }}
          constraints={{
            facingMode: "environment",
          }}
        />
      </div>
      <Box height={20} />
      <Grid item xs={12}>
        <div className={styles.OrCont}>
          <div className={styles.lineAlt}></div>
          <div className={styles.midText}>OR</div>
          <div className={styles.lineAlt}></div>
        </div>
      </Grid>
      <div>
        <div className={classes.sub_title}>Enter Details Manually</div>
        <div>
          <TextField
            fullWidth
            className={classes.text_field}
            label="Restaurant Code"
            value={state.restaurantCode}
            onChange={(event) => {
              setState({ ...state, restaurantCode: event.target.value });
            }}
          />
        </div>
        <div>
          <TextField
            fullWidth
            className={classes.text_field}
            label="Table Number"
            value={state.tableNo}
            onChange={(event) => {
              setState({ ...state, tableNo: event.target.value });
            }}
          />
        </div>

        <CustomButton onClick={onSubmit}>Submit</CustomButton>
        <Grid item xs={12}>
          <div className={styles.OrCont}>
            <div className={styles.lineAlt}></div>
            <div className={styles.midText}>OR</div>
            <div className={styles.lineAlt}></div>
          </div>
        </Grid>
        <CustomButton
          variant="outlined"
          onClick={() => {
            history.push("/customer/order_history");
          }}
        >
          My Order History
        </CustomButton>
      </div>
    </div>
  );
}
