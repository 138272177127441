import { useEffect } from "react";
import Layout from "../../../components/layout";
import { useHistory } from "react-router-dom";
import CustomerApiService from "../../../api_service/customer_api_service";
import RingLoader from "react-spinners/RingLoader";
import { Box } from "@mui/system";
import { showAlert } from "../../../components/message_box/show_alert";

export default function CheckPaymentPage({ match }) {
  const history = useHistory();
  const { restaurant_id, table_id, order_id } = match.params;

  const checkPaymentStatus = async () => {
    const response = await CustomerApiService().checkPaymentStatusCall(
      restaurant_id,
      order_id,
      true
    );
    if (response.success) {
      history.replace(
        `/restaurants/${restaurant_id}/tables/${table_id}/orders/${order_id}/success`
      );
    } else {
      if (response.data?.status === "PENDING") {
        return;
      } else {
        showAlert({ head: "Info", body: response.message, isCustomer: true });
        history.replace(
          `/restaurants/${restaurant_id}/tables/${table_id}/orders/${order_id}`
        );
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      checkPaymentStatus();
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  }, []); // eslint-disable-line

  return (
    <Layout footer>
      <Box height={20} />
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <RingLoader />
        <img src={"/images/payhere_logo.png"} height={150} alt="Payhere" />
      </div>
    </Layout>
  );
}
