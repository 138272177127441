import { Backdrop, Box, Card, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./subscription_create_modal_styles";
import ModelHeader from "../model_header/model_header";
import ImagePicker from "../image_picker/image_picker";
import Button1 from "../button_1/button_1";
import RestaurantApiService from "../../api_service/restaurant_api_service";
import { showAlert } from "../message_box/show_alert";

const initialData = { amount: 0, note: "", receipt: "", receipt_image: null };

export default function SubscriptionCreateModal({ open, onClose, onRefresh }) {
  const classes = useStyles();

  const [state, setState] = useState(initialData);

  const changeState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const setInitialData = () => {
    setState({
      ...state,
      ...initialData,
    });
  };
  useEffect(() => {
    if (open) {
      setInitialData();
    }
  }, [open]); // eslint-disable-line

  const onSubmit = () => {
    const formData = new FormData();
    if (!state.amount) {
      return showAlert({
        head: "Please be careful!",
        body: "Amount is required to submit the subscription",
      });
    }
    if (!state.receipt_image) {
      return showAlert({
        head: "Please be careful!",
        body: "Receipt image is required to submit the subscription",
      });
    }

    formData.append("amount", state.amount);
    formData.append("note", state.note);
    formData.append("file", state.receipt_image);

    RestaurantApiService()
      .createSubscription(formData)
      .then((response) => {
        if (response.success) {
          onRefresh();
          onClose();
        }
      });
  };

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <Card className={classes.card}>
        <ModelHeader title={"Upload payment receipt"} onClose={onClose} />
        <div style={{ padding: "20px" }}>
          <Box height={10} />
          <TextField
            className={classes.text_field}
            style={{ width: "100%" }}
            name="amount"
            type="number"
            label="Amount"
            InputLabelProps={{ shrink: true }}
            value={state.amount}
            onChange={(event) => {
              changeState("amount", event.target.value);
            }}
            inputProps={{
              min: 0,
            }}
          />
          <Box height={20} />
          <TextField
            className={classes.text_field}
            style={{ width: "100%" }}
            name="note"
            type="text"
            label="Note (If any)"
            InputLabelProps={{ shrink: true }}
            value={state.note}
            onChange={(event) => {
              changeState("note", event.target.value);
            }}
          />
          <Box height={20} />
          <ImagePicker
            imageUrl={state.receipt}
            setFile={(file) => {
              changeState("receipt_image", file);
            }}
            file={state.receipt_image}
            title={"Receipt"}
            width={"100%"}
            height={500}
          />
          <Box height={40} />
          <div className={classes.button_row}>
            <Button1 onClick={onSubmit} isSelected title="Submit" />
          </div>
        </div>
      </Card>
    </Backdrop>
  );
}
