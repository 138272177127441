import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  table_selector: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  add_new_order_outer: { width: "100%", textAlign: "center" },
});

export default useStyles;
