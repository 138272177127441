import { IconButton } from "@mui/material";
import { SettingsOutlined } from "@mui/icons-material";
import React, { useRef, useState } from "react";
import useStyles from "./app_bar_styles";
import AdminSettingsMenu from "./settings_menu";
import useWindowSize from "../../utils/use_window_size";

export default function AdminAppBar({ title }) {
  const classes = useStyles();

  const [openMenu, setOpenMenu] = useState(false);
  const anchorRef = useRef(null);
  const { isTab } = useWindowSize();

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  return (
    <div className={classes.root}>
      <div style={{ width: "100%" }}>
        <div className={classes.main_text}>
          {`${isTab ? "" : "CeyPay Admin - "}${title}`}
        </div>
      </div>
      <IconButton
        ref={anchorRef}
        onClick={handleToggle}
        className={classes.setting_button}
      >
        <SettingsOutlined className={classes.setting_icon} />
      </IconButton>
      <AdminSettingsMenu
        open={openMenu}
        anchorRef={anchorRef}
        setOpenMenu={setOpenMenu}
      />
    </div>
  );
}
