import {
  Backdrop,
  Box,
  Card,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./restaurant_details_modal_styles";
import { useStoreActions, useStoreState } from "easy-peasy";
import Button1 from "../button_1/button_1";
import ImagePicker from "../image_picker/image_picker";
import ModelHeader from "../model_header/model_header";
import RestaurantApiService from "../../api_service/restaurant_api_service";
import SNQrCodeMainDoor from "../edit_table/qr_code main";
import html2canvas from "html2canvas";
import { showAlert } from "../message_box/show_alert";

export default function RestaurantEditRestaurant() {
  const classes = useStyles();
  const openRestaurantDetailsModal = useStoreState(
    (state) => state.openRestaurantDetailsModal
  );
  const itemToEdit = useStoreState((state) => state.restaurant);
  const setOpenRestaurantDetailsModal = useStoreActions(
    (actions) => actions.setOpenRestaurantDetailsModal
  );
  const isAdminView = useStoreState((state) => state.currentView === "ADMIN");

  const [qr, setQr] = useState(null);

  const [state, setState] = useState({
    logo_image: null,
    logo: itemToEdit?.logo || "",
    background: itemToEdit?.background || "",
    code: itemToEdit?.code || "",
    name: itemToEdit?.name || "",
    address: itemToEdit?.address || "",
    user_name: itemToEdit?.user_name || "",
    password: "",
    admin_password: "",
    mobile_no: itemToEdit?.mobile_no || "",
    fixed_line: itemToEdit?.fixed_line || "",
    web_site_url: itemToEdit?.web_site_url || "",
    trip_advisor_url: itemToEdit?.trip_advisor_url || "",
    google_map_url: itemToEdit?.google_map_url || "",
    facebook_url: itemToEdit?.facebook_url || "",
    instagram_url: itemToEdit?.instagram_url || "",
    twitter_url: itemToEdit?.twitter_url || "",
    change_password: false,
    change_admin_password: false,
    repeat_password: "",
    repeat_admin_password: "",
    currency: itemToEdit?.currency || "LKR",
    isGenerateClicked: false,
    self_ordering: itemToEdit?.self_ordering || false,
  });

  const changeState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const setInitialData = () => {
    setState({
      ...state,
      logo_image: null,
      logo: itemToEdit?.logo || "",
      background: itemToEdit?.background || "",
      code: itemToEdit?.code || "",
      name: itemToEdit?.name || "",
      address: itemToEdit?.address || "",
      user_name: itemToEdit?.user_name || "",
      password: "",
      admin_password: "",
      mobile_no: itemToEdit?.mobile_no || "",
      fixed_line: itemToEdit?.fixed_line || "",
      web_site_url: itemToEdit?.web_site_url || "",
      trip_advisor_url: itemToEdit?.trip_advisor_url || "",
      google_map_url: itemToEdit?.google_map_url || "",
      facebook_url: itemToEdit?.facebook_url || "",
      instagram_url: itemToEdit?.instagram_url || "",
      twitter_url: itemToEdit?.twitter_url || "",
      change_password: false,
      change_admin_password: false,
      repeat_password: "",
      repeat_admin_password: "",
      currency: itemToEdit?.currency || "LKR",
      isGenerateClicked: false,
      self_ordering: itemToEdit?.self_ordering || false,
    });
  };
  useEffect(() => {
    if (openRestaurantDetailsModal) {
      setInitialData();
    }
  }, [openRestaurantDetailsModal]); // eslint-disable-line

  useEffect(() => {
    setQr(null);
    changeState("isGenerateClicked", false);
  }, []); // eslint-disable-line

  const onClose = () => {
    setOpenRestaurantDetailsModal(false);
  };

  const setFile = (file, key) => {
    changeState(key, file);
  };

  const onSubmit = () => {
    const formData = new FormData();
    if (state.logo_image) {
      formData.append("file", state.logo_image);
    }
    if (!state.name) {
      return showAlert({
        head: "Please be careful!",
        body: "Please enter the name of your 'Restaurant'",
      });
    }
    formData.append("name", state.name);
    if (!state.address) {
      return showAlert({
        head: "Please be careful!",
        body: "Please enter the address of your 'Restaurant'",
      });
    }
    formData.append("address", state.address);

    if (
      state.change_password &&
      state.password !== "" &&
      state.repeat_password !== ""
    ) {
      if (
        state.password === itemToEdit.password &&
        state.repeat_password !== ""
      ) {
        formData.append("password", state.repeat_password);
      } else {
        return showAlert({
          head: "Please be careful!",
          body: "Entered Old password is not matched with your old password!",
        });
      }
    }

    if (
      state.change_admin_password &&
      state.admin_password !== "" &&
      state.repeat_admin_password !== ""
    ) {
      if (
        state.admin_password === itemToEdit.admin_password &&
        state.repeat_admin_password !== ""
      ) {
        formData.append("admin_password", state.repeat_admin_password);
      } else {
        return showAlert({
          head: "Please be careful!",
          body: "Entered Old admin password is not matched with your old admin password!",
        });
      }
    }

    formData.append("mobile_no", state.mobile_no);
    formData.append("fixed_line", state.fixed_line);
    formData.append("web_site_url", state.web_site_url);
    formData.append("trip_advisor_url", state.trip_advisor_url);
    formData.append("google_map_url", state.google_map_url);
    formData.append("facebook_url", state.facebook_url);
    formData.append("instagram_url", state.instagram_url);
    formData.append("twitter_url", state.twitter_url);
    formData.append("self_ordering", state.self_ordering);

    RestaurantApiService()
      .updateRestaurantCall(formData)
      .then((response) => {
        if (response.success) {
          onClose();
        }
      });
  };

  const generateQrCode = async () => {
    const response = await RestaurantApiService().generateMainQrUrlCall();
    if (response.success) {
      setQr(response.data);
    }
    changeState("isGenerateClicked", true);
  };

  const downloadQr = async () => {
    const hiddenQRdownload = document.getElementById("qr_generator_main");
    const canvas = await html2canvas(hiddenQRdownload);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${qr.restaurant_code}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Backdrop className={classes.backdrop} open={openRestaurantDetailsModal}>
      <Card
        className={
          state.isGenerateClicked ? classes.card_expaned : classes.card
        }
      >
        <ModelHeader title={"Restaurant Details"} onClose={onClose} />
        <div style={{ padding: "20px" }}>
          <Box height={10} />
          <div style={{ display: "flex" }}>
            <ImagePicker
              readOnly={!isAdminView}
              imageUrl={state.logo}
              setFile={(file) => {
                setFile(file, "logo_image");
              }}
              file={state.logo_image}
              title={"Restaurant Logo"}
            />
          </div>
          <Box height={40} />
          <span>General Information</span>
          <Box height={40} />
          <TextField
            className={classes.text_field}
            label="Restaurant Name"
            value={state.name}
            onChange={(event) => {
              changeState("name", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            disabled={!isAdminView}
          />
          <Box height={40} />
          <TextField
            className={classes.text_field}
            label="Restaurant Code"
            value={state.code}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            disabled
          />
          <Box height={40} />
          <TextField
            className={classes.text_field}
            label="Restaurant Currency"
            value={state.currency}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            disabled
          />
          <Box height={40} />
          <span>Configurations</span>
          <Box height={40} />
          <FormControlLabel
            disabled={!isAdminView}
            style={{ width: "100%" }}
            control={
              <Switch
                checked={state.self_ordering}
                onChange={(event) => {
                  changeState("self_ordering", event.target.checked);
                }}
              />
            }
            label="Self ordering"
          />
          {isAdminView && (
            <div>
              <Box height={40} />
              <span>Security Information</span>
              <Box height={40} />
              <TextField
                className={classes.text_field}
                label="Restaurant User Name"
                value={state.user_name}
                onChange={(event) => {
                  changeState("user_name", event.target.value);
                }}
                InputLabelProps={{ shrink: true }}
                disabled
              />

              <Box height={40} />

              {!state.change_password && (
                <Button1
                  title={"Change Password"}
                  onClick={() => {
                    setState({ ...state, change_password: true });
                  }}
                />
              )}

              {state.change_password && (
                <div>
                  <TextField
                    type="password"
                    className={classes.text_field}
                    style={{ width: "45%", marginRight: "10%" }}
                    label="Restaurant Old Password"
                    value={state.password}
                    onChange={(event) => {
                      changeState("password", event.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                    disabled={!isAdminView}
                  />
                  <TextField
                    type="password"
                    className={classes.text_field}
                    style={{ width: "45%" }}
                    label="Restaurant New Password"
                    value={state.repeat_password}
                    onChange={(event) => {
                      changeState("repeat_password", event.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                    disabled={!isAdminView}
                  />
                </div>
              )}
              {(state.change_password || state.change_admin_password) && (
                <Box height={40} />
              )}
              {!state.change_admin_password && (
                <Button1
                  title={"Change Admin Password"}
                  onClick={() => {
                    setState({ ...state, change_admin_password: true });
                  }}
                />
              )}
              {state.change_admin_password && (
                <div>
                  <TextField
                    type="password"
                    className={classes.text_field}
                    style={{ width: "45%", marginRight: "10%" }}
                    label="Admin Old Password"
                    value={state.admin_password}
                    onChange={(event) => {
                      changeState("admin_password", event.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                    disabled={!isAdminView}
                  />
                  <TextField
                    type="password"
                    className={classes.text_field}
                    style={{ width: "45%" }}
                    label="Admin New Password"
                    value={state.repeat_admin_password}
                    onChange={(event) => {
                      changeState("repeat_admin_password", event.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                    disabled={!isAdminView}
                  />
                </div>
              )}
            </div>
          )}
          <Box height={40} />
          <span>Contact Details</span>
          <Box height={40} />
          <TextField
            className={classes.text_field}
            label="Restaurant Address"
            value={state.address}
            onChange={(event) => {
              changeState("address", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            disabled={!isAdminView}
          />
          <Box height={40} />
          <TextField
            type="text"
            className={classes.text_field}
            style={{ width: "45%", marginRight: "10%" }}
            label="Restaurant Mobile No"
            value={state.mobile_no}
            onChange={(event) => {
              changeState("mobile_no", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            disabled={!isAdminView}
          />
          <TextField
            type="text"
            className={classes.text_field}
            style={{ width: "45%" }}
            label="Restaurant Fixed Line"
            value={state.fixed_line}
            onChange={(event) => {
              changeState("fixed_line", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            disabled={!isAdminView}
          />
          <Box height={40} />
          <TextField
            className={classes.text_field}
            label="Restaurant Web Site Url"
            value={state.web_site_url}
            onChange={(event) => {
              changeState("web_site_url", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            disabled={!isAdminView}
          />
          <Box height={40} />
          <TextField
            className={classes.text_field}
            label="Restaurant Facebook Url"
            value={state.facebook_url}
            onChange={(event) => {
              changeState("facebook_url", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            disabled={!isAdminView}
          />
          <Box height={40} />
          <TextField
            className={classes.text_field}
            label="Restaurant Instagram Url"
            value={state.instagram_url}
            onChange={(event) => {
              changeState("instagram_url", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            disabled={!isAdminView}
          />
          <Box height={40} />
          <TextField
            className={classes.text_field}
            label="Restaurant Twitter Url"
            value={state.twitter_url}
            onChange={(event) => {
              changeState("twitter_url", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            disabled={!isAdminView}
          />
          <Box height={40} />
          <TextField
            className={classes.text_field}
            label="Trip Advisor Url"
            value={state.trip_advisor_url}
            onChange={(event) => {
              changeState("trip_advisor_url", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            disabled={!isAdminView}
          />
          <Box height={40} />
          <TextField
            className={classes.text_field}
            label="Google Map Url"
            value={state.google_map_url}
            onChange={(event) => {
              changeState("google_map_url", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            disabled={!isAdminView}
          />
          <Box height={40} />
          {qr && state.isGenerateClicked ? (
            <div style={{ textAlign: "center" }}>
              <SNQrCodeMainDoor qr={qr} id="qr_generator_main" />
              <Button1 width={"200px"} onClick={downloadQr} title="Download" />
            </div>
          ) : itemToEdit ? (
            <Button1
              width={"100%"}
              onClick={generateQrCode}
              title="Generate Main QR Code"
            />
          ) : (
            <div />
          )}
          {isAdminView && (
            <div className={classes.button_row}>
              <Button1 onClick={onSubmit} isSelected title="submit" />
            </div>
          )}
        </div>
      </Card>
    </Backdrop>
  );
}
