import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  backdrop: {
    zIndex: 15,
    color: "#fff",
    backgroundColor: "#000000db !important",
  },
  card: {
    minWidth: 400,
    maxWidth: 600,
    margin: "10vh auto",
    maxHeight: "80vh",
    display: "flex",
    flexDirection: "column",
    borderRadius: 5,
    backgroundSize: "cover",
  },
  text_field: { width: "100%" },
  button_row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  full_width: {
    width: "100%",
  },
  card_inner: {
    backgroundColor: "#ffffffdf",
    padding: "15px",
    borderRadius: "5px",
  },
  qtyOuter: {
    display: "flex",
    justifyContent: "center",
  },
  description_row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    gap: "7px",
  },
  description_text: {
    width: "100%",
    textAlign: "justify",
    fontSize: "12px",
    color: "gray",
  },
  modal_body: {
    overflow: "auto",
  },
  bottom_section: { padding: 10, backgroundColor: "#edededed" },
});

export default useStyles;
