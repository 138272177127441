import { Add, Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Chip,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import { ModifierTypes } from "../../utils/constants";
import useStyles from "./edit_modifier_styles";

export default function EditModifiers({ modifiers, setModifiers }) {
  const classes = useStyles();

  const setModifier = (index, attribute, value) => {
    modifiers[index][attribute] = value;
    setModifiers(modifiers);
  };
  const addModifier = () => {
    modifiers.push({
      name: "",
      type: ModifierTypes.SINGLE_CHOICE,
      values: [],
    });
    setModifiers(modifiers);
  };
  const deleteModifier = (index) => {
    modifiers.splice(index, 1);
    setModifiers(modifiers);
  };
  return (
    <div className={classes.outer}>
      <div className={classes.header}>
        <span>Modifiers</span>
        <Tooltip title="Add new modifier">
          <Button onClick={addModifier}>
            <Add />
          </Button>
        </Tooltip>
      </div>
      <div className={classes.modifiers_col}>
        {modifiers?.map((modifier, index) => (
          <ModifierItem
            key={index}
            index={index}
            modifier={modifier}
            setModifier={setModifier}
            deleteModifier={deleteModifier}
          />
        ))}
      </div>
    </div>
  );
}

function ModifierItem({ modifier, setModifier, deleteModifier, index }) {
  const classes = useStyles();
  return (
    <div className={classes.modifier_outer}>
      <div className={classes.input_row}>
        <TextField
          label="Modifier Name"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={modifier.name || ''}
          onChange={(event) => {
            setModifier(index, "name", event.target.value);
          }}
        />
        <TextField
          label="type"
          select
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={modifier.type || ModifierTypes.SINGLE_CHOICE}
          onChange={(event) => {
            setModifier(index, "type", event.target.value);
          }}
        >
          {Object.values(ModifierTypes).map((type, index) => (
            <MenuItem key={index} value={type}>
              {type.split("_").join(" ")}
            </MenuItem>
          ))}
        </TextField>
        <Tooltip title="Delete this modifier">
          <Button
            variant="outlined"
            color="error"
            onClick={() => deleteModifier(index)}
          >
            <Delete />
          </Button>
        </Tooltip>
      </div>
      <Autocomplete
        clearIcon={false}
        options={[]}
        freeSolo
        multiple
        renderTags={(value, props) =>
          value.map((option, index) => (
            <Chip label={option} {...props({ index })} />
          ))
        }
        value={modifier.values || []}
        onChange={(event, values) => {
          setModifier(index, "values", values);
        }}
        renderInput={(params) => (
          <TextField
            label="Modifier values"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...params}
          />
        )}
      />
    </div>
  );
}
