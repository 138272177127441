import { IconButton } from "@mui/material";
import React from "react";
import useStyles from "./model_header_styles";

export default function ModelHeader({ title, onClose, customer }) {
  const classes = useStyles();
  return (
    <div
      className={customer ? classes.card_header_cusomer : classes.card_header}
    >
      {title}
      <IconButton onClick={onClose} className={classes.close_btn}>
        x
      </IconButton>
    </div>
  );
}
