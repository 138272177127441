import styles from "../styles/layout.module.scss";
import Footer from "./footer";
import Navbar from "./navbar";
import CeypayLogo from "./ceypay_logo";
const Layout = ({ footer, navbar, logo, children, className, containerClassName, user }) => {
  return (
    <div className={className}>
      {navbar && <Navbar user={user} />}
      {logo && <CeypayLogo />}
      <div className={`${styles.mainContainer} ${containerClassName}`}>{children}</div>
      {footer && <Footer />}
    </div>
  );
};

export default Layout;
