import { action, createStore } from "easy-peasy";

const store = createStore({
  restaurant: JSON.parse(localStorage.getItem("sn_rest_restaurant") || null),
  setRestaurant: action((state, payload) => {
    state.restaurant = payload;
    localStorage.setItem("sn_rest_restaurant", JSON.stringify(payload));
  }),

  itemCategories: [],
  setItemCategories: action((state, payload) => {
    state.itemCategories = payload;
  }),
  addItemCategory: action((state, payload) => {
    state.itemCategories.push(payload);
  }),
  updateItemCategory: action((state, payload) => {
    const index = state.itemCategories.findIndex(
      (item) => item.id === payload.id
    );
    if (index >= 0) {
      state.itemCategories[index] = payload;
    }
    if (
      state.selectedItemCategory &&
      state.selectedItemCategory.id === payload.id
    ) {
      state.selectedItemCategory = payload;
    }
  }),
  updateItem: action((state, payload) => {
    const index = state.itemCategories.findIndex(
      (item) => item.id === payload.item_category_id
    );
    if (index >= 0) {
      const itemIndex = state.itemCategories[index].items.findIndex(
        (item) => item.id === payload.id
      );
      if (itemIndex >= 0) {
        state.itemCategories[index].items[itemIndex] = payload;
      }
    }
    if (
      state.selectedItemCategory &&
      state.selectedItemCategory.id === payload.item_category_id
    ) {
      const itemIndex = state.selectedItemCategory.items.findIndex(
        (item) => item.id === payload.id
      );
      if (itemIndex >= 0) {
        state.selectedItemCategory.items[itemIndex] = payload;
      }
    }
  }),
  addItem: action((state, payload) => {
    const index = state.itemCategories.findIndex(
      (item) => item.id === payload.item_category_id
    );
    if (index >= 0) {
      state.itemCategories[index].items.push(payload);
    }
    if (
      state.selectedItemCategory &&
      state.selectedItemCategory.id === payload.item_category_id
    ) {
      state.selectedItemCategory.items.push(payload);
    }
  }),

  selectedItemCategory: null,
  setSelectedItemCategory: action((state, payload) => {
    state.selectedItemCategory = payload;
  }),

  openEditCategory: false,
  setOpenEditCategory: action((state, payload) => {
    state.openEditCategory = payload;
    if (payload === false) {
      state.categoryToEdit = null;
    }
  }),
  categoryToEdit: null,
  setCategoryToEdit: action((state, payload) => {
    state.categoryToEdit = payload;
  }),

  openEditItem: false,
  setOpenEditItem: action((state, payload) => {
    state.openEditItem = payload;
    if (payload === false) {
      state.itemToEdit = null;
    }
  }),
  itemToEdit: null,
  setItemToEdit: action((state, payload) => {
    state.itemToEdit = payload;
  }),

  tables: [],
  setTables: action((state, payload) => {
    const tableStatuses = state.tables.map((t) => {
      return { id: t.id, updated: t.updated };
    });
    tableStatuses.forEach((oldTable) => {
      if (oldTable.updated) {
        const newTable = payload.find((t) => t.id === oldTable.id);
        if (newTable) {
          newTable.updated = true;
        }
      }
    });
    state.tables = payload;
  }),
  addTable: action((state, payload) => {
    state.tables.push(payload);
  }),
  updateTable: action((state, payload) => {
    const index = state.tables.findIndex((item) => item.id === payload.id);
    if (index >= 0) {
      state.tables[index] = payload;
    }
  }),
  changeTableUpdatedStatus: action((state, payload) => {
    const table = state.tables.find((item) => item.id === payload.id);
    if (table) {
      table.updated = payload.status;
    }
  }),
  addOrderToTable: action((state, payload) => {
    if (state.selectedTable.id === payload.table_id) {
      state.selectedTableOrders.push(payload);
      state.selectedOrder = payload;
      state.selectedTab = "order_detail";
    }
  }),

  selectedTab: "tables",
  oldTab: "",
  setSelectedTab: action((state, payload) => {
    state.oldTab = state.selectedTab;
    state.selectedTab = payload;
  }),

  selectedTable: null,
  setSelectedTable: action((state, payload) => {
    state.selectedTable = payload;
  }),

  selectedTableOrders: [],
  setSelectedTableOrders: action((state, payload) => {
    state.selectedTableOrders = payload;
  }),

  selectedOrder: null,
  setSelectedOrder: action((state, payload) => {
    state.selectedOrder = payload;
  }),
  updateSelectedOrder: action((state, payload) => {
    state.selectedOrder.customer_name = payload.customer_name;
    state.selectedOrder.waiter = payload.waiter;
  }),
  addItemToOrder: action((state, payload) => {
    if (state.selectedOrder && state.selectedOrder.id === payload.order_id) {
      payload.newlyAdded = true;
      state.selectedOrder.order_items.push(payload);
    }
  }),
  removeItemFromOrder: action((state, payload) => {
    if (state.selectedOrder && state.selectedOrder.id === payload.order_id) {
      const index = state.selectedOrder.order_items.findIndex(
        (item) => item.id === payload.id
      );
      if (index >= 0) {
        state.selectedOrder.order_items.splice(index, 1);
      }
    }
  }),
  updateItemQuantity: action((state, payload) => {
    if (state.selectedOrder && state.selectedOrder.id === payload.order_id) {
      const index = state.selectedOrder.order_items.findIndex(
        (item) => item.id === payload.id
      );
      if (index >= 0) {
        state.selectedOrder.order_items[index].quantity = payload.quantity;
      }
    }
  }),
  setOrderById: action((state, payload) => {
    if (state.selectedOrder && state.selectedOrder.id === payload.id) {
      state.selectedOrder = payload;
    }
    const index = state.selectedTableOrders.findIndex(
      (order) => order.id === payload.id
    );
    if (index >= 0) {
      state.selectedTableOrders[index] = payload;
    }
  }),
  addChargeToOrder: action((state, payload) => {
    if (state.selectedOrder && state.selectedOrder.id === payload.order_id) {
      state.selectedOrder.order_charges.push(payload);
    }
  }),
  removeChargeFromOrder: action((state, payload) => {
    if (state.selectedOrder && state.selectedOrder.id === payload.order_id) {
      const index = state.selectedOrder.order_charges.findIndex(
        (charge) => charge.id === payload.id
      );
      if (index >= 0) {
        state.selectedOrder.order_charges.splice(index, 1);
      }
    }
  }),
  openPaymentPopup: false,
  setOpenPaymentPopup: action((state, payload) => {
    state.openPaymentPopup = payload;
  }),
  orderPay: action((state, payload) => {
    if (state.selectedOrder && state.selectedOrder.id === payload.id) {
      state.openPaymentPopup = false;
      state.selectedTab = "orders";
      state.selectedOrder = null;
      // state.selectedOrder.payment_status = payload.payment_status
      // state.selectedOrder.is_closed = payload.is_closed
    }
  }),
  serveItemOnOrder: action((state, payload) => {
    if (state.selectedOrder) {
      const index = state.selectedOrder.order_items.findIndex(
        (item) => item.id === payload.id
      );
      if (index >= 0) {
        state.selectedOrder.order_items[index].served = payload.status;
      }
    }
  }),

  openEditTable: false,
  setOpenEditTable: action((state, payload) => {
    state.openEditTable = payload;
    if (payload === false) {
      state.tableToEdit = null;
    }
  }),
  tableToEdit: null,
  setTableToEdit: action((state, payload) => {
    state.tableToEdit = payload;
  }),

  charges: [],
  setCharges: action((state, payload) => {
    state.charges = payload;
  }),
  updateCharge: action((state, payload) => {
    const index = state.charges.findIndex((charge) => charge.id === payload.id);
    if (index >= 0) {
      state.charges[index] = payload;
    }
  }),
  createCharge: action((state, payload) => {
    state.charges.push(payload);
  }),

  openEditCharge: false,
  setOpenEditCharge: action((state, payload) => {
    state.openEditCharge = payload;
    if (payload === false) {
      state.chargeToEdit = null;
    }
  }),
  chargeToEdit: null,
  setChargeToEdit: action((state, payload) => {
    state.chargeToEdit = payload;
  }),

  openEditDiscount: false,
  setOpenEditDiscount: action((state, payload) => {
    state.openEditDiscount = payload;
    if (payload === false) {
      state.discountToEdit = null;
    }
  }),
  discountToEdit: null,
  setDiscountToEdit: action((state, payload) => {
    state.discountToEdit = payload;
  }),

  // isAdminView:
  //   sessionStorage.getItem("cey_pay_admin_view") === "1" ? true : false,
  // setAdminView: action((state, payload) => {
  //   state.isAdminView = payload;
  //   if (payload) {
  //     state.selectedOrder = null;
  //     state.selectedTab = "tables";
  //   }
  //   sessionStorage.setItem("cey_pay_admin_view", payload ? "1" : "0");
  // }),

  currentView: sessionStorage.getItem("cey_pay_current_view") || "CASHIER",
  setCurrentView: action((state, payload) => {
    state.currentView = payload;
    state.requestedView = null;
    state.selectedOrder = null;
    state.selectedTab = "tables";
    state.selectedTable = null;
    state.selectedItemCategory = null;
    sessionStorage.setItem("cey_pay_current_view", payload);
  }),

  requestedView: null,
  setRequestedView: action((state, payload) => {
    state.requestedView = payload;
  }),

  adminRestaurants: [],
  setAdminRestaurants: action((state, payload) => {
    state.adminRestaurants = payload;
  }),
  openRestaurantEditModal: false,
  setOpenRestaurantEditModal: action((state, payload) => {
    state.openRestaurantEditModal = payload;
  }),
  restaurantToEdit: null,
  setRestaurantToEdit: action((state, payload) => {
    state.restaurantToEdit = payload;
  }),

  customerItemToAdd: null,
  setCustomerItemToAdd: action((state, payload) => {
    state.customerItemToAdd = payload;
  }),
  customerOpenAddItemPopup: false,
  setCustomerOpenAddItemPopup: action((state, payload) => {
    state.customerOpenAddItemPopup = payload;
  }),

  openRestaurantDetailsModal: false,
  setOpenRestaurantDetailsModal: action((state, payload) => {
    state.openRestaurantDetailsModal = payload;
  }),

  customerInteracted: false,
  setCustomerInteraction: action((state, payload) => {
    state.customerInteracted = payload;
  }),

  restaurantSelectItemPopupOpen: false,
  restaurantSelectedItem: null,
  setRestaurantSelectItemPopup: action((state, payload) => {
    state.restaurantSelectItemPopupOpen = payload;
  }),
  setRestaurantSelectedItem: action((state, payload) => {
    state.restaurantSelectedItem = payload;
  }),

  isCalledToWaiter: false,
  setIsCalledToWaiter: action((state, payload) => {
    state.isCalledToWaiter = payload;
  }),

  isTablePadFullModeActive: false,
  setIsTablePadFullModeActive: action((state, payload) => {
    state.isTablePadFullModeActive = payload;
  }),

  subscriptionStatus: null,
  setSubscriptionStatus: action((state, payload) => {
    state.subscriptionStatus = payload;
  }),

  alert: {
    open: false,
    head: "",
    body: "",
    actions: [],
    isCustomer: false,
  },
  setAlert: action((state, payload) => {
    state.alert = payload;
  }),

  loading: false,
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),

  openDownloadAllTables: false,
  setOpenDownloadAllTables: action((state, payload) => {
    state.openDownloadAllTables = payload;
  }),
});

export default store;
