import { makeStyles } from "@mui/styles";
import { primary_color_2 } from "../../themes/colors";

const useStyles = makeStyles({
  item_outer: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "10px",
    border: `4px solid ${primary_color_2}66 `,
    transition: "0.5s",
    cursor: "pointer",
    position: "relative",
    "&:hover": {
      border: `4px solid ${primary_color_2}`,
    },
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "100px !important",
  },
  icon: {
    alignItems: "center",
    display: "flex",
    width: "50px",
  },
  amount: {
    width: "100%",
    textAlign: "right",
    fontWeight: "bold",
    color: "green",
  },
  names: {
    marginLeft: "10px",
    width: "100%",
  },
  name_row: {
    alignItems: "center",
    display: "flex",
  },
  customer_name: {
    fontWeight: "bold",
    color: "gray",
    marginLeft: "3px",
    width: "150px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign:'left'
  },
  order_id: {
    color: "gray",
    fontSize: "16px",
    marginLeft: "3px",
    width: "150px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign:'left'
  },
});

export default useStyles;
