import { Card, Grid } from "@mui/material";
import { Place, LocalPhone, Restaurant } from "@mui/icons-material";
import styles from "../../../styles/information.module.scss";
import BackArrow from "../../../components/back_arrow";
import { useEffect, useState } from "react";

import RestaurantLogo from "../../../components/restaurant_logo";
import Layout from "../../../components/layout";
import { useHistory } from "react-router-dom";
import CustomerApiService from "../../../api_service/customer_api_service";

export default function RestaurantInformation({ match }) {
  const { restaurant_code } = match.params;
  const history = useHistory();
  const [state, setState] = useState({
    name: "",
    logo: "",
    address: "",
    mobile_no: "",
    fixed_line: "",
    facebook_url: "",
    instagram_url: "",
    twitter_url: "",
    web_site_url: "",
    trip_advisor_url: "",
    google_map_url: "",
    loading: true,
  });

  useEffect(() => {
    fetchData();
  }, []); //eslint-disable-line

  const fetchData = async () => {
    const restaurantRes = await CustomerApiService().getRestaurantDetailsCall(
      restaurant_code
    );
    setState({ ...state, loading: false });
    if (restaurantRes.success) {
      setState(restaurantRes.data.restaurant);
    } else {
      history.push("/customer/order_history");
    }
  };

  const navigateToLink = (address) => {
    window.open(address, "_blank");
  };

  const addressFormat = () => {
    let length = state.address.split(",").length;

    let addr = state.address.split(",").map((item, index) => {
      return (
        <span key={index} style={{ display: "block" }}>
          {index + 1 === length ? item : item + ","}
        </span>
      );
    });

    return addr;
  };

  return state.loading ? (
    <div />
  ) : (
    <Layout footer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BackArrow />
        </Grid>

        <Grid item xs={12}>
          <RestaurantLogo url={state.logo} />
        </Grid>

        <Grid item xs={12}>
          <Card style={{ padding: "0 24px" }}>
            <Grid container justify="center">
              <Grid item xs={12}>
                <div className={styles.textHeader}>More Information</div>
                <div className={styles.line}></div>
              </Grid>

              <div className={styles.hours}>
                <Restaurant style={{ fill: "#5E5873" }} />{" "}
                <div style={{ paddingLeft: "12px", fontSize: "14px" }}>
                  {state.name}
                </div>
              </div>

              <Grid item xs={12}>
                <div className={styles.address}>
                  <Place style={{ fill: "#5E5873" }} />
                  <div className={styles.addressCont}>{addressFormat()}</div>
                </div>
              </Grid>

              <Grid item xs={12} className={styles.divider}>
                {state.mobile_no && (
                  <div
                    style={{
                      paddingTop: "18px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <LocalPhone style={{ fill: "#5E5873" }} />
                    <span
                      style={{
                        display: "inline-block",
                        paddingLeft: "12px",
                        fontSize: "14px",
                      }}
                    >
                      <a href={`tel:${state.mobile_no}`}>{state.mobile_no}</a>
                    </span>
                  </div>
                )}
                {state.fixed_line && (
                  <div
                    style={{
                      paddingTop: "18px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <LocalPhone style={{ fill: "#5E5873" }} />
                    <span
                      style={{
                        display: "inline-block",
                        paddingLeft: "12px",
                        fontSize: "14px",
                      }}
                    >
                      <a href={`tel:${state.fixed_line}`}>{state.fixed_line}</a>
                    </span>
                  </div>
                )}
              </Grid>
              {(state.web_site_url ||
                state.facebook_url ||
                state.instagram_url ||
                state.twitter_url ||
                state.trip_advisor_url ||
                state.google_map_url) && (
                <Grid item xs={12}>
                  <div className={styles.line}></div>
                  <Grid container justify="center">
                    <Grid item xs={12} className={styles.subHeader}>
                      Follow us on
                    </Grid>

                    <div className={styles.socialButtonWrapper}>
                      <Grid container width={300}>
                        {state.web_site_url && (
                          <Grid item xs={2}>
                            <div
                              className={styles.socialIcons}
                              onClick={() => {
                                navigateToLink(state.web_site_url);
                              }}
                            >
                              <img
                                src="/images/website_logo.png"
                                width="38"
                                height="38"
                                alt="website"
                              />
                            </div>
                          </Grid>
                        )}
                        {state.facebook_url && (
                          <Grid item xs={2}>
                            <div
                              className={styles.socialIcons}
                              onClick={() => {
                                navigateToLink(state.facebook_url);
                              }}
                            >
                              <img
                                src="/images/facebook_logo.png"
                                width="38"
                                height="38"
                                alt="facebook"
                              />
                            </div>
                          </Grid>
                        )}
                        {state.instagram_url && (
                          <Grid item xs={2}>
                            <div
                              className={styles.socialIcons}
                              onClick={() => {
                                navigateToLink(state.instagram_url);
                              }}
                            >
                              <img
                                src="/images/instagram_logo.png"
                                width="38"
                                height="38"
                                alt="instagram"
                              />
                            </div>
                          </Grid>
                        )}
                        {state.twitter_url && (
                          <Grid item xs={2}>
                            <div
                              className={styles.socialIcons}
                              onClick={() => {
                                navigateToLink(state.twitter_url);
                              }}
                            >
                              <img
                                src="/images/twitter_logo.png"
                                width="38"
                                height="38"
                                alt="twitter"
                              />
                            </div>
                          </Grid>
                        )}
                        {state.trip_advisor_url && (
                          <Grid item xs={2}>
                            <div
                              className={styles.socialIcons}
                              onClick={() => {
                                navigateToLink(state.trip_advisor_url);
                              }}
                            >
                              <img
                                src="/images/trip_advisor.png"
                                width="38"
                                height="38"
                                alt="trip advisor"
                              />
                            </div>
                          </Grid>
                        )}
                        {state.google_map_url && (
                          <Grid item xs={2}>
                            <div
                              className={styles.socialIcons}
                              onClick={() => {
                                navigateToLink(state.google_map_url);
                              }}
                            >
                              <img
                                src="/images/google_map.png"
                                width="38"
                                height="38"
                                alt="google map"
                              />
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
}
