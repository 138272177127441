import React from "react";
import useStyles from "./footer_styles";

export default function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <p>&copy; {new Date().getFullYear()} CeyPay. All rights reserved.</p>
    </div>
  );
}
