import React from "react";
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import useWindowSize from "../../utils/use_window_size";

export default function SettingsMenu({
  open,
  anchorRef,
  setOpenMenu,
  showAdminPopup,
  type,
}) {
  const history = useHistory();
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenMenu(false);
  };
  const currentView = useStoreState((state) => state.currentView);
  const setCurrentView = useStoreActions((actions) => actions.setCurrentView);
  const setRequestedView = useStoreActions(
    (actions) => actions.setRequestedView
  );
  // const setSelectedTab = useStoreActions((actions) => actions.setSelectedTab);
  // const setSelectedItemCategory = useStoreActions(
  //   (actions) => actions.setSelectedItemCategory
  // );

  const logout = () => {
    localStorage.removeItem("sn_rest_restaurant");
    localStorage.removeItem("sn_rest_access_token");
    localStorage.removeItem("sn_rest_refresh_token");
    localStorage.removeItem("cey_pay_current_view");
    history.replace("/");
  };

  const { isTab } = useWindowSize();

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      style={{
        zIndex: 5,
      }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} id="menu-list-grow">
                {currentView === "CASHIER" ? (
                  <>
                    <MenuItem
                      onClick={(event) => {
                        setRequestedView("ADMIN");
                        showAdminPopup();
                        handleClose(event);
                      }}
                    >
                      Admin Panel
                    </MenuItem>
                    {!isTab && (
                      <MenuItem
                        onClick={(event) => {
                          setRequestedView("SALES");
                          showAdminPopup();
                          handleClose(event);
                        }}
                      >
                        Sales Panel
                      </MenuItem>
                    )}
                    {!isTab && (
                      <MenuItem
                        onClick={(event) => {
                          setRequestedView("PAYOUTS");
                          showAdminPopup();
                          handleClose(event);
                        }}
                      >
                        Payouts Panel
                      </MenuItem>
                    )}
                    {!isTab && (
                      <MenuItem
                        onClick={(event) => {
                          setRequestedView("SUBSCRIPTIONS");
                          showAdminPopup();
                          handleClose(event);
                        }}
                      >
                        Subscriptions Panel
                      </MenuItem>
                    )}
                  </>
                ) : currentView === "ADMIN" ? (
                  <>
                    <MenuItem
                      onClick={(event) => {
                        setCurrentView("CASHIER");
                        history.replace("/restaurant/home");
                        handleClose(event);
                      }}
                    >
                      Cashier Panel
                    </MenuItem>
                    {!isTab && (
                      <MenuItem
                        onClick={(event) => {
                          setCurrentView("SALES");
                          history.replace("/restaurant/sales");
                          handleClose(event);
                        }}
                      >
                        Sales Panel
                      </MenuItem>
                    )}
                    {!isTab && (
                      <MenuItem
                        onClick={(event) => {
                          setCurrentView("PAYOUTS");
                          history.replace("/restaurant/payouts");
                          handleClose(event);
                        }}
                      >
                        Payouts Panel
                      </MenuItem>
                    )}
                    {!isTab && (
                      <MenuItem
                        onClick={(event) => {
                          setCurrentView("SUBSCRIPTIONS");
                          history.replace("/restaurant/subscriptions");
                          handleClose(event);
                        }}
                      >
                        Subscriptions Panel
                      </MenuItem>
                    )}
                  </>
                ) : currentView === "SALES" ? (
                  <>
                    <MenuItem
                      onClick={(event) => {
                        setCurrentView("CASHIER");
                        history.push("/restaurant/home");
                        handleClose(event);
                      }}
                    >
                      Cashier Panel
                    </MenuItem>
                    {!isTab && (
                      <MenuItem
                        onClick={(event) => {
                          setCurrentView("PAYOUTS");
                          history.replace("/restaurant/payouts");
                          handleClose(event);
                        }}
                      >
                        Payouts Panel
                      </MenuItem>
                    )}
                    {!isTab && (
                      <MenuItem
                        onClick={(event) => {
                          setCurrentView("SUBSCRIPTIONS");
                          history.replace("/restaurant/subscriptions");
                          handleClose(event);
                        }}
                      >
                        Subscriptions Panel
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={(event) => {
                        setCurrentView("ADMIN");
                        history.push("/restaurant/admin");
                        handleClose(event);
                      }}
                    >
                      Admin Panel
                    </MenuItem>
                  </>
                ) : currentView === "PAYOUTS" ? (
                  <>
                    <MenuItem
                      onClick={(event) => {
                        setCurrentView("CASHIER");
                        history.push("/restaurant/home");
                        handleClose(event);
                      }}
                    >
                      Cashier Panel
                    </MenuItem>
                    {!isTab && (
                      <MenuItem
                        onClick={(event) => {
                          setCurrentView("SALES");
                          history.replace("/restaurant/sales");
                          handleClose(event);
                        }}
                      >
                        Sales Panel
                      </MenuItem>
                    )}
                    {!isTab && (
                      <MenuItem
                        onClick={(event) => {
                          setCurrentView("SUBSCRIPTIONS");
                          history.replace("/restaurant/subscriptions");
                          handleClose(event);
                        }}
                      >
                        Subscriptions Panel
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={(event) => {
                        setCurrentView("ADMIN");
                        history.push("/restaurant/admin");
                        handleClose(event);
                      }}
                    >
                      Admin Panel
                    </MenuItem>
                  </>
                ) : currentView === "SUBSCRIPTIONS" ? (
                  <>
                    <MenuItem
                      onClick={(event) => {
                        setCurrentView("CASHIER");
                        history.push("/restaurant/home");
                        handleClose(event);
                      }}
                    >
                      Cashier Panel
                    </MenuItem>
                    {!isTab && (
                      <MenuItem
                        onClick={(event) => {
                          setCurrentView("SALES");
                          history.replace("/restaurant/sales");
                          handleClose(event);
                        }}
                      >
                        Sales Panel
                      </MenuItem>
                    )}
                    {!isTab && (
                      <MenuItem
                        onClick={(event) => {
                          setCurrentView("PAYOUTS");
                          history.replace("/restaurant/payouts");
                          handleClose(event);
                        }}
                      >
                        Payouts Panel
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={(event) => {
                        setCurrentView("ADMIN");
                        history.push("/restaurant/admin");
                        handleClose(event);
                      }}
                    >
                      Admin Panel
                    </MenuItem>
                  </>
                ) : (
                  <div />
                )}

                <MenuItem
                  onClick={(event) => {
                    logout();
                    handleClose(event);
                  }}
                >
                  Logout
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
