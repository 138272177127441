import React from "react";
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { useHistory } from "react-router-dom";

export default function AdminSettingsMenu({ open, anchorRef, setOpenMenu }) {
  const history = useHistory();
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenMenu(false);
  };

  const logout = () => {
    localStorage.removeItem("sn_rest_admin_access_token");
    history.replace("/admin");
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      style={{
        zIndex: 5,
      }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} id="menu-list-grow">
                <MenuItem
                  onClick={(event) => {
                    history.push("/admin/home");
                    handleClose(event);
                  }}
                >
                  Home
                </MenuItem>
                <MenuItem
                  onClick={(event) => {
                    history.push("/admin/sales");
                    handleClose(event);
                  }}
                >
                  Sales
                </MenuItem>
                <MenuItem
                  onClick={(event) => {
                    history.push("/admin/payouts");
                    handleClose(event);
                  }}
                >
                  Payouts
                </MenuItem>
                <MenuItem
                  onClick={(event) => {
                    history.push("/admin/error_log");
                    handleClose(event);
                  }}
                >
                  Error Log
                </MenuItem>
                <MenuItem
                  onClick={(event) => {
                    history.push("/admin/config");
                    handleClose(event);
                  }}
                >
                  Config
                </MenuItem>
                <MenuItem
                  onClick={(event) => {
                    logout();
                    handleClose(event);
                  }}
                >
                  Logout
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
