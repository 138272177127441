import axios from "axios";
import { setLoading } from "../components/loader/loader";
import { showAlert } from "../components/message_box/show_alert";
import { api_base_url } from "../config/config";
import { getClientTimeZone } from "../utils/get_time_zone";

const handleApiCall = async (method, url, body, blockLoading) => {
  if (!blockLoading) {
    setLoading(true);
  }
  try {
    const response = await axios({
      baseURL: api_base_url,
      url: url,
      timeout: 60000,
      method: method,
      data: body,
      headers: {
        "Content-Type": "application/json",
        "Client-Timezone": getClientTimeZone(),
      },
    });
    setLoading(false);
    return response.data;
  } catch (error) {
    if (error.response) {
      setLoading(false);
      switch (error.response.status) {
        case 422:
          showAlert({
            head: "Unable to proceed with validation errors",
            body: error.response.data.data[0]
              ? error.response.data.data[0].message
              : "Please check form fields and try again",
          });
          return {
            alert_showed: true,
            success: false,
            data: null,
            message: "Validation Errors",
          };
        case 500:
          showAlert({
            head: "Something went wrong",
            body: "Please contact system admin",
          });
          return {
            success: false,
            data: null,
            message: "Internal Server Error",
          };
        default:
          showAlert({
            head: "Something went wrong",
            body: "Please contact system admin",
          });
          return {
            success: false,
            data: null,
            message: "Internal Server Error",
          };
      }
    } else {
      showAlert({
        head: "Something went wrong",
        body: "Please contact system admin",
      });
      return {
        success: false,
        data: null,
        message: "no response from server",
      };
    }
  }
};

const contactUsCall = async (data) => {
  const response = await handleApiCall("POST", "/api/public/contact", data);
  return response;
};

export default function PublicApiService() {
  return {
    contactUsCall: contactUsCall,
  };
}
