import { Card, Grid } from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import RestaurantApiService from "../../api_service/restaurant_api_service";
import ZoomInAnimatedDiv from "../animated_div/zoom_in_animated_div";
import Button1 from "../button_1/button_1";
import useStyles from "./charges_styles";

export default function Charges() {
  const charges = useStoreState((state) =>
    state.charges.filter((charge) => !charge.is_discount)
  );
  const classes = useStyles();
  const adminView = useStoreState((state) => state.currentView === "ADMIN");
  const setOpenEditCharge = useStoreActions(
    (actions) => actions.setOpenEditCharge
  );
  const setChargeToEdit = useStoreActions((actions) => actions.setChargeToEdit);

  useEffect(() => {
    RestaurantApiService().getRestaurantChargesCall();
  }, []);

  const onAddNewCharge = () => {
    setOpenEditCharge(true);
    setChargeToEdit(null);
  };

  return (
    <div>
      {adminView && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button1
            onClick={onAddNewCharge}
            title="Add New Charge"
            width="50%"
          />
        </div>
      )}

      <Card className={classes.outer_card}>
        {!adminView && (
          <div className={classes.other_charges}>Other Charges</div>
        )}
        <Grid className={classes.grid} container spacing={1}>
          {charges.map((charge, index) => (
            <ChargeItem key={index} charge={charge} index={index} />
          ))}
        </Grid>
      </Card>
    </div>
  );
}

const ChargeItem = ({ charge, index }) => {
  const classes = useStyles();
  const [isHover, setHover] = useState(false);
  const adminView = useStoreState((state) => state.currentView === "ADMIN");
  const setOpenEditCharge = useStoreActions(
    (actions) => actions.setOpenEditCharge
  );
  const setChargeToEdit = useStoreActions((actions) => actions.setChargeToEdit);
  const selectedTab = useStoreState((state) => state.selectedTab);
  const selectedOrder = useStoreState((state) => state.selectedOrder);

  const addChargeToOrder = async () => {
    if (selectedTab === "order_detail" && selectedOrder) {
      await RestaurantApiService().addChargeToOrderCall({
        order_id: selectedOrder.id,
        charge_id: charge.id,
      });
      RestaurantApiService().getRestaurantOrderByIdCall(selectedOrder.id, true);
    }
  };

  return adminView || (!adminView && charge.is_active) ? (
    <Grid style={{ backgroundColor: "transparent" }} item xs={6}>
      <ZoomInAnimatedDiv delay={index / 2} show={charge.id}>
        <Card
          onClick={
            adminView
              ? () => {
                  setOpenEditCharge(true);
                  setChargeToEdit(charge);
                }
              : addChargeToOrder
          }
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          elevation={isHover ? 15 : 3}
          className={classes.item_outer}
          style={{
            backgroundColor: !adminView
              ? "#e9e9e9"
              : charge.is_active
              ? "#d5ffbf"
              : "#ffbfbf",
          }}
        >
          <div className={classes.name}>{charge.name}</div>
          <div className={classes.price}>
            {charge.value_type === "PERCENTAGE"
              ? `${charge.value}%`
              : charge.value}
          </div>
        </Card>
      </ZoomInAnimatedDiv>
    </Grid>
  ) : (
    <div />
  );
};
