import { makeStyles } from "@mui/styles";
import { primary_gradient_reverse } from "../../themes/colors";

const useStyles = makeStyles({
  backdrop: {
    zIndex: 10,
    color: "#fff",
    overflowY: "scroll",
  },
  card: { width: "500px" },
  card_header: {
    backgroundImage: primary_gradient_reverse,
    color: "white",
    fontWeight: "bold",
    fontSize: "15px",
    padding: "10px",
  },
  text_field: { width: "100%" },
  button_row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
  },
  rest_name: {
    fontWeight: "300",
    fontSize: "15px",
    color: "gray",
  },
  qr: {
    margin: "10px",
  },
  desc_row: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    width: "230px",
    margin: "auto",
  },
  code: {
    fontWeight: "bold",
    color: "gray",
  },
  table_no: {
    fontWeight: "bold",
    color: "gray",
  },
  qr_outer: {
    border: "2px solid #000",
    borderRadius: "25px",
    padding: "5px",
    borderStyle: "dashed",
  },
  qr_outer_with_frame: {
    border: "2px solid #000",
    height: "1497px",
    padding: "3px",
    backgroundImage: `url("/images/mainQR_frame4.jpg")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },

  qr_outer_with_frame_download_actual: {
    border: "2px solid #000",
    height: "3515px",
    width: "2480px",
    padding: "3px",
    backgroundImage: `url("/images/mainQR_frame4.jpg")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "absolute !important;",
    // visibility: "hidden"
  },
  qr_holder_on_frame: {
    marginTop: "186px",
    marginLeft: "6px",
  },
  qr_holder_on_frame_actual_print: {
    position: "relative",
    top: "753px",
    left: "470px",
  },
  qr_outer2: {
    border: "8px solid #6A6C6D",
    borderRadius: "25px",
    padding: "10px",
  },
  qr_main_print: {
    height: "590px !important;",
    width: "587px !important;",
    marginTop: "372px",
  },
  view_menu: {
    fontWeight: "bold",
    fontSize: "18px",
  },
  scan_text: {
    fontWeight: "bold",
    fontSize: "20px",
  },
  table_qr_outer_with_frame: {
    border: "2px solid #000",
    height: "649px",
    padding: "3px",
    backgroundImage: `url("/images/mainQR_frame4.jpg")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  qr_deign_2: {
    marginTop: "9px",
  },
  rest_name_2: {
    color: "#414141",
    fontWeight: "bold",
    backgroundColor: "white",
    width: "fit-content",
    margin: "auto",
    padding: "0px 8px",
    marginTop: "7px",
    borderRadius: "20px",
    fontSize: "17px",
  },
  code_2: {
    fontWeight: "bold",
    color: "#414141",
    backgroundColor: "white",
    width: "fit-content",
    margin: "auto",
    padding: "1px 8px",
    borderRadius: "20px",
    fontSize: "17px",
  },
  table_no_2: {
    fontWeight: "bold",
    color: "#414141",
    backgroundColor: "white",
    width: "fit-content",
    margin: "auto",
    padding: "0px 8px",
    marginTop: "123px",
    borderRadius: "20px",
    fontSize: "17px",
  },
});

export default useStyles;
