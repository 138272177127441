import { Backdrop, Box, Card, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./payment_popup_styles";
import { useStoreActions, useStoreState } from "easy-peasy";
import Button1 from "../button_1/button_1";
import commaNumber from "comma-number";
import RestaurantApiService from "../../api_service/restaurant_api_service";
import ModelHeader from "../model_header/model_header";
import { showAlert } from "../message_box/show_alert";

export default function PaymentPopup() {
  const classes = useStyles();
  const openPaymentPopup = useStoreState((state) => state.openPaymentPopup);
  const setOpenPaymentPopup = useStoreActions(
    (actions) => actions.setOpenPaymentPopup
  );
  const selectedOrder = useStoreState((state) => state.selectedOrder);
  const { currency } = useStoreState((state) => state.restaurant);

  const [receivedAmount, setReceivedAmount] = useState("");
  const [paymentCurrency, setPaymentCurrency] = useState(currency);

  const [totalAmount, setTotalAmount] = useState(0);

  const onClose = () => {
    setOpenPaymentPopup(false);
  };

  useEffect(() => {
    if (openPaymentPopup) {
      const total = selectedOrder.order_amount;

      setPaymentCurrency(currency);
      setReceivedAmount(Number(total.toFixed(2)));
      setTotalAmount(total);
    }
  }, [openPaymentPopup]); // eslint-disable-line

  useEffect(() => {
    const total = selectedOrder.order_amount;
    setReceivedAmount(Number(total.toFixed(2)));
    setTotalAmount(total);
  }, [paymentCurrency]); // eslint-disable-line

  const onSubmit = async () => {
    if (receivedAmount < totalAmount) {
      return showAlert({
        head: "Unable to proceed!",
        body: "Received amount is less than the order total amount.",
      });
    }
    await RestaurantApiService().payOrderByCashCall(
      selectedOrder.id,
      receivedAmount,
      paymentCurrency
    );
  };

  return selectedOrder ? (
    <Backdrop className={classes.backdrop} open={openPaymentPopup}>
      <Card className={classes.card}>
        <ModelHeader title={"Payment Summary"} onClose={onClose} />
        <div style={{ padding: "20px" }}>
          <Box height={20} />
          <TextField
            className={classes.text_field}
            label="Pay by"
            value={paymentCurrency}
            InputLabelProps={{ shrink: true }}
            select
            onChange={(event) => {
              setPaymentCurrency(event.target.value);
            }}
            disabled={currency === "LKR"}
          >
            {["LKR", currency].map((curr, index) => (
              <MenuItem key={index} value={curr}>
                {curr}
              </MenuItem>
            ))}
          </TextField>
          <Box height={20} />
          <TextField
            className={classes.text_field}
            label="Total Amount"
            value={commaNumber(totalAmount.toFixed(2))}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
          <Box height={20} />
          <TextField
            type="number"
            className={classes.text_field}
            label="Received Amount"
            autoFocus={true}
            value={receivedAmount}
            onChange={(event) => {
              setReceivedAmount(Number(event.target.value));
            }}
            InputLabelProps={{ shrink: true }}
          />
          <Box height={20} />
          <TextField
            className={classes.text_field}
            label="Balance Amount"
            autoFocus={true}
            value={commaNumber(
              (Number(receivedAmount) - totalAmount.toFixed(2)).toFixed(2)
            )}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
          <Box height={20} />
          <div className={classes.button_row}>
            <Button1 onClick={onSubmit} isSelected title="Pay" />
          </div>
        </div>
      </Card>
    </Backdrop>
  ) : (
    <div />
  );
}
