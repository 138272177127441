import { makeStyles } from "@mui/styles";
import { background_color_2 } from "../../../themes/colors";

const useStyles = makeStyles({
  root: {
    height: "100vh",
    width: "100%",
    backgroundColor: background_color_2,
    padding: "20px",
    overflow: "auto",
  },
  main_row: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    zIndex: 4,
  },
  detail_outer: {
    display: "flex",
    flexDirection: "column",
    padding: 20,
    gap: 20,
    maxWidth: 500,
    margin: "20px auto",
    backgroundColor: "white",
    borderRadius: 10,
  },
  detail_name: {
    fontWeight: "bold",
    textAlign: "center",
  },
});

export default useStyles;
