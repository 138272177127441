import {
  ErrorOutlineOutlined,
  WarningAmberOutlined,
} from "@mui/icons-material";
import clsx from "clsx";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useState } from "react";
import AdminPopup from "../app_bar/admin_popup";
import Button1 from "../button_1/button_1";
import useStyles from "./subscription_banner_styles";

export default function SubscriptionBanner() {
  const classes = useStyles();

  const [openAdminPopup, setOpenAdminPopup] = useState(false);
  const subscriptionStatus = useStoreState((state) => state.subscriptionStatus);
  const setRequestedView = useStoreActions(
    (actions) => actions.setRequestedView
  );

  return (
    <>
      {subscriptionStatus && subscriptionStatus.show_warning && (
        <div
          className={clsx(
            classes.outer,
            subscriptionStatus.active
              ? classes.outer_warning
              : classes.outer_critical
          )}
        >
          <div>
            {subscriptionStatus.active ? (
              <WarningAmberOutlined />
            ) : (
              <ErrorOutlineOutlined />
            )}
          </div>
          <div className={classes.message}>{subscriptionStatus.message}</div>
          <div>
            <Button1
              onClick={() => {
                setRequestedView("SUBSCRIPTIONS");
                setOpenAdminPopup(true);
              }}
              title={"Subscribe"}
              className={
                subscriptionStatus.active
                  ? classes.button_warning
                  : classes.button_critical
              }
              isSelected
            />
          </div>
        </div>
      )}
      {subscriptionStatus && !subscriptionStatus.active && (
        <div
          style={{
            backgroundColor: "#000000a3",
            height: "100vh",
            width: "100%",
            position: "absolute",
            zIndex: 10,
          }}
        />
      )}
      <AdminPopup
        open={openAdminPopup}
        setOpen={(value) => {
          setOpenAdminPopup(value);
        }}
      />
    </>
  );
}
