import { Backdrop, Card, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import RestaurantApiService from "../../api_service/restaurant_api_service";
import ZoomInAnimatedDiv from "../animated_div/zoom_in_animated_div";
import Button1 from "../button_1/button_1";
import { showAlert } from "../message_box/show_alert";
import ModelHeader from "../model_header/model_header";
import useStyles from "./app_bar_styles";

export default function AdminPopup({ open, setOpen, customAction }) {
  const classes = useStyles();
  const history = useHistory();
  const restaurant = useStoreState((state) => state.restaurant);
  const requestedView = useStoreState((state) => state.requestedView);
  const [state, setState] = useState({
    password: "",
    loading: false,
  });
  const setCurrentView = useStoreActions((actions) => actions.setCurrentView);

  useEffect(() => {
    if (open) {
      setState({
        password: "",
        loading: false,
      });
    }
  }, [open]);
  const changeState = (key, value) => {
    setState({ ...state, [key]: value });
  };
  const onConfirm = async () => {
    if (restaurant.has_admin_password) {
      const response = await RestaurantApiService().checkAdminPasswordCall(
        state.password
      );
      if (response.success) {
        if (customAction) {
          customAction();
          setOpen(false);
        } else {
          if (requestedView === "ADMIN") {
            setCurrentView("ADMIN");
            history.push("/restaurant/admin");
          } else if (requestedView === "SALES") {
            setCurrentView("SALES");
            history.push("/restaurant/sales");
          } else if (requestedView === "PAYOUTS") {
            setCurrentView("PAYOUTS");
            history.push("/restaurant/payouts");
          } else if (requestedView === "SUBSCRIPTIONS") {
            setCurrentView("SUBSCRIPTIONS");
            history.push("/restaurant/subscriptions");
          }
        }
      } else {
        return showAlert({
          head: "Please be careful!",
          body: "The 'Admin Password' you have entered is incorrect. Please try again.",
        });
      }
    } else {
      if (!state.password) {
        return showAlert({
          head: "Please be careful!",
          body: "The 'Admin Password' should not be empty.",
        });
      }
      changeState("loading", true);
      RestaurantApiService()
        .setAdminPasswordCall(state.password)
        .then((response) => {
          changeState("loading", false);
          if (response.success) {
            setCurrentView("ADMIN");
            history.push("/restaurant/admin");
          }
        });
    }
  };
  return restaurant ? (
    <Backdrop className={classes.backdrop} open={open}>
      <ZoomInAnimatedDiv show={open}>
        <Card elevation={20} className={classes.message_card}>
          <ModelHeader
            title={
              restaurant.has_admin_password
                ? `Please enter 'Admin Password'`
                : `Please set an 'Admin Password'`
            }
            onClose={() => {
              setOpen(false);
            }}
          />
          <Box height={20} />
          <div className={classes.message_body}>
            <TextField
              type="password"
              style={{ width: "90%" }}
              label="Please enter 'Admin Password'"
              InputLabelProps={{ shrink: true }}
              value={state.password}
              onChange={(event) => {
                changeState("password", event.target.value);
              }}
              autoFocus
            />
          </div>
          <div className={classes.message_buttons}>
            {/* <Button1
              isSelected
              title="cancel"
              loading={state.loading}
              onClick={() => {
                setOpen(false);
              }}
            /> */}
            <Button1
              isSelected
              title="confirm"
              loading={state.loading}
              onClick={onConfirm}
            />
          </div>
        </Card>
      </ZoomInAnimatedDiv>
    </Backdrop>
  ) : (
    <div />
  );
}
