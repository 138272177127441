import {
  Button,
  Card,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import { Box } from "@mui/system";
import commaNumber from "comma-number";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import Loader from "react-spinners/PulseLoader";
import RestaurantApiService from "../../api_service/restaurant_api_service";
import { printReceipt } from "../../printers";
import { getPaymentStatus } from "../../utils/helpers";
import BillChargeItem from "../bill_charge_item/bill_charge_item";
import BillOrderItem from "../bill_order_item/bill_order_item";
import Charges from "../charges/charges";
import PaymentPopup from "../payment_popup/payment_popup";
import OptionsMenu from "./options_manu";
import useStyles from "./order_details_styles";
import Discounts from "../discounts/discounts";
import { Done } from "@mui/icons-material";

export default function OrderDetails() {
  const classes = useStyles();
  const selectedOrder = useStoreState((state) => state.selectedOrder);
  const [state, setState] = useState({
    waiterName: selectedOrder.waiter,
    customerName: selectedOrder.customer_name,
  });
  const isTablePadFullModeActive = useStoreState(
    (state) => state.isTablePadFullModeActive
  );

  useEffect(() => {
    const interval = setInterval(() => {
      RestaurantApiService().getRestaurantOrderByIdCall(selectedOrder.id, true);
    }, 10000);
    return () => clearInterval(interval);
  }, []); //eslint-disable-line

  const onWaiterNameSave = () => {
    RestaurantApiService().updateOrderCall(
      selectedOrder.id,
      {
        waiter: state.waiterName,
      },
      true
    );
  };

  const onCustomerNameChange = () => {
    RestaurantApiService().updateOrderCall(
      selectedOrder.id,
      {
        customer_name: state.customerName,
      },
      true
    );
  };

  return selectedOrder ? (
    <div>
      <Card className={classes.outer_card}>
        <div className={classes.outer_card_padding}>
          <div className={classes.order_details_row}>
            <span>
              Order No:
              <span
                className={classes.values_of_order_details}
              >{` ${selectedOrder.id}`}</span>
            </span>

            <span>
              Table No:
              <span
                className={classes.values_of_order_details}
              >{` ${selectedOrder.table.number}`}</span>
            </span>
            {!isTablePadFullModeActive && <OptionsMenu order={selectedOrder} />}
          </div>

          <div className={classes.details_row}>
            <FormControl fullWidth variant="filled">
              <InputLabel>Customer Name</InputLabel>
              <FilledInput
                type="text"
                name="customer_name"
                className={classes.customer_field}
                value={state.customerName}
                disabled={isTablePadFullModeActive}
                onChange={(event) => {
                  setState({ ...state, customerName: event.target.value });
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onCustomerNameChange();
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      disabled={isTablePadFullModeActive}
                      onClick={onCustomerNameChange}
                      size="small"
                      edge="end"
                    >
                      <Done />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Box width={10} />
            <FormControl fullWidth variant="filled">
              <InputLabel>Waiter Name</InputLabel>
              <FilledInput
                type="text"
                name="waiter_name"
                className={classes.customer_field}
                value={state.waiterName}
                disabled={isTablePadFullModeActive}
                onChange={(event) => {
                  setState({ ...state, waiterName: event.target.value });
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onWaiterNameSave();
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      disabled={isTablePadFullModeActive}
                      onClick={onWaiterNameSave}
                      size="small"
                      edge="end"
                    >
                      <Done />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>

          <div className={classes.label}>Order Items</div>
          {selectedOrder.order_items?.map((orderItem, index) => (
            <BillOrderItem
              key={orderItem.id}
              index={index}
              orderItem={orderItem}
            />
          ))}
          {!isTablePadFullModeActive && (
            <>
              {selectedOrder.order_discounts?.length > 0 && (
                <div className={classes.label}>Discounts</div>
              )}
              {selectedOrder.order_discounts?.map((discount, index) => (
                <BillChargeItem
                  key={discount.id}
                  index={index}
                  charge={discount}
                  order={selectedOrder}
                  isDiscount
                />
              ))}
              {selectedOrder.order_charges?.length > 0 && (
                <div className={classes.label}>Charges</div>
              )}
              {selectedOrder.order_charges?.map((charge, index) => (
                <BillChargeItem
                  key={charge.id}
                  index={index}
                  charge={charge}
                  order={selectedOrder}
                />
              ))}
            </>
          )}
        </div>
        {!isTablePadFullModeActive && (
          <>
            <TotalAmount order={selectedOrder} />
          </>
        )}
      </Card>
      {!isTablePadFullModeActive && (
        <>
          <div className={classes.button_row}>
            <PayButton order={selectedOrder} />
            {selectedOrder.restaurant.print_receipt &&
              selectedOrder.restaurant.printer_model && (
                <PrintButton order={selectedOrder} />
              )}
          </div>
          <Charges />
          <Discounts />
          <Box height={100} />
          <PaymentPopup />
        </>
      )}
      {isTablePadFullModeActive && <Box height={50} />}
    </div>
  ) : (
    <div />
  );
}

const TotalAmount = ({ order }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.total_amount}
      style={{
        marginTop: "2px",
      }}
    >
      <span>{`Total Amount in ${order.restaurant.currency}`}</span>
      <span>{commaNumber(order.order_amount.toFixed(2))}</span>
    </div>
  );
};

const PayButton = ({ order }) => {
  const classes = useStyles();
  const setOpenPaymentPopup = useStoreActions(
    (actions) => actions.setOpenPaymentPopup
  );
  const onPay = () => {
    setOpenPaymentPopup(true);
  };
  return (
    <Button
      onClick={
        getPaymentStatus(order.payment_status).allow_to_pay ? onPay : null
      }
      className={classes.pay_btn}
    >
      {getPaymentStatus(order.payment_status).label}
    </Button>
  );
};

const PrintButton = ({ order }) => {
  const classes = useStyles();
  const [isPrinting, setPrinting] = useState(false);
  const restaurant = useStoreState((state) => state.restaurant);

  const { printer_model } = useStoreState((state) => state.restaurant);

  const onPrint = () => {
    setPrinting(true);
    printReceipt(printer_model, order.id, false, restaurant.currency);
    setTimeout(() => {
      setPrinting(false);
    }, 2000);
  };

  return (
    <Button
      onClick={onPrint}
      disabled={isPrinting}
      className={classes.print_btn}
    >
      {isPrinting ? <Loader /> : "Print Receipt"}
    </Button>
  );
};
