import { Button } from "@mui/material";
import React from "react";

const CustomButton = ({
  title,
  align,
  mt,
  mb,
  ml,
  mr,
  pl,
  pr,
  pt,
  pb,
  border,
  bg,
  fontWeight,
  fontSize = "14px",
  children,
  style,
  disabled,
  variant,
  ...rest
}) => {
  return (
    <Button
      style={{
        justifyContent: align,
        marginTop: mt,
        marginBottom: mb,
        marginLeft: ml,
        marginRight: mr,
        paddingLeft: pl,
        paddingRight: pr,
        paddingTop: pt,
        paddingBottom: pb,
        height: 47,
        fontWeight: fontWeight,
        fontFamily: "Montserrat-Medium",
        fontSize: fontSize,
        color: variant === "outlined" ? "#262161" : "white",
        backgroundColor: variant === "outlined" ? "rgb(219 216 221)" : bg,
        border: border,
        borderWidth: 2,
        ...style,
      }}
      fullWidth
      variant={variant || "contained"}
      color="primary"
      disableElevation
      disabled={disabled}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
