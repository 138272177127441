import { Box, Card, Grid, IconButton } from "@mui/material";
import { Delete, Edit, RestoreFromTrash } from "@mui/icons-material";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useState } from "react";
import RestaurantApiService from "../../../../api_service/restaurant_api_service";
import FadeInAnimatedDiv from "../../../animated_div/fade_in_animated_div";
import useStyles from "./index.styled";
import ZoomInAnimatedDiv from "../../../animated_div/zoom_in_animated_div";
import { getImageUrl } from "../../../../utils/helpers";
const commaNumber = require("comma-number");

export default function Index({ item, index }) {
  const classes = useStyles();
  const [isHover, setHover] = useState(false);

  const selectedTab = useStoreState((state) => state.selectedTab);
  const selectedOrder = useStoreState((state) => state.selectedOrder);
  const adminView = useStoreState((state) => state.currentView === "ADMIN");

  const setRestaurantSelectItemPopup = useStoreActions(
    (actions) => actions.setRestaurantSelectItemPopup
  );
  const setRestaurantSelectedItem = useStoreActions(
    (actions) => actions.setRestaurantSelectedItem
  );

  const addItemToOrder = () => {
    if (selectedTab === "order_detail" && selectedOrder) {
      setRestaurantSelectItemPopup(true);
      setRestaurantSelectedItem(item);
    }
  };
  return !adminView && !item.is_active ? (
    <div />
  ) : (
    <Grid style={{ backgroundColor: "transparent" }} item xs={3}>
      <ZoomInAnimatedDiv delay={index} show={item.id}>
        <Card
          onClick={adminView ? null : addItemToOrder}
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          elevation={10}
          className={classes.item_outer}
          style={{
            backgroundImage: `url(${getImageUrl(item.image)})`,
          }}
        >
          <PriceTag item={item} />
          <div className={classes.name}>{item.name}</div>
          {adminView && !item.is_active && (
            <div className={classes.inactive_seal} />
          )}
          {adminView && isHover && <ItemActions item={item} />}
        </Card>
      </ZoomInAnimatedDiv>
    </Grid>
  );
}

const PriceTag = ({ item }) => {
  const classes = useStyles();
  const { currency } = useStoreState((state) => state.restaurant);
  const getDiscountText = () => {
    if (item.discount_type === "PERCENTAGE") {
      return `${item.discount}% off`;
    }
    return `${item.discount.toFixed(2)} ${currency} off`;
  };
  return (
    <div>
      <div className={classes.price}>
        <span>{`${commaNumber(item.unit_price.toFixed(2))} ${currency}`}</span>
      </div>
      {item.discount ? (
        <div className={classes.discount}>
          <span>{getDiscountText()}</span>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

const ItemActions = ({ item }) => {
  const classes = useStyles();

  const setOpenEditItem = useStoreActions((actions) => actions.setOpenEditItem);
  const setItemToEdit = useStoreActions((actions) => actions.setItemToEdit);

  const updateItem = () => {
    setItemToEdit(item);
    setOpenEditItem(true);
  };

  const onDeleteItem = (status) => {
    RestaurantApiService().updateItemCall(
      {
        is_active: status,
      },
      item.id
    );
  };

  return (
    <FadeInAnimatedDiv>
      <div className={classes.edit_row}>
        <IconButton onClick={updateItem} className={classes.edit_button}>
          <Edit />
        </IconButton>
        <Box width={10} />
        <IconButton
          onClick={() => onDeleteItem(!item.is_active)}
          className={classes.edit_button}
        >
          {item.is_active ? <Delete /> : <RestoreFromTrash />}
        </IconButton>
      </div>
    </FadeInAnimatedDiv>
  );
};
