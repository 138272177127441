import { makeStyles } from "@mui/styles";
import {
  primary_gradient,
  primary_gradient_reverse,
} from "../../themes/colors";

const useStyles = makeStyles({
  image_row: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
  },
  image_container_outer: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: "10px",
    padding: "3px",
    backgroundImage: primary_gradient_reverse,
  },
  image_container: {
    height: "100%",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: "10px",
    position: "relative",
  },
  add_button: {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
  },
  add_icon: { fontSize: "100px !important", color: "white" },
  edit_button_outer: { position: "absolute", right: "-15px", bottom: "-15px" },
  edit_button: { backgroundImage: primary_gradient, color: "white" },
  title: {
    position: "absolute",
    top: 0,
    color: "white",
    backgroundColor: "gray",
    padding: "2px 10px",
    borderRadius: "5px",
    border: "0.1px solid white",
    fontSize: "13px",
    textTransform: "initial",
    width: "100%",
    textAlign: "center",
  },
  edit_icon: { color: "white" },
});

export default useStyles;
