import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  outer: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    alignItems: "center",
    padding: "5px 20px",
  },
  outer_critical: {
    backgroundColor: "rgba(254, 211, 209, 1)",
    color: "rgba(215, 44, 13, 1)",
  },
  message: {
    width: "100%",
  },
  button_critical: {
    color: "white !important",
    backgroundColor: "rgba(215, 44, 13, 1) !important",
    "&:hover": {
      backgroundColor: "rgba(215, 44, 13, 1) !important",
    },
  },
});

export default useStyles;
