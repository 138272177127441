import { Backdrop, Card } from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";
import React from "react";
import useWindowSize from "../../utils/use_window_size";
import ZoomInAnimatedDiv from "../animated_div/zoom_in_animated_div";
import CustomButton from "../custom_button";
import useStyles from "./message_box_styles";

export default function MessageBox() {
  const { isTab } = useWindowSize();
  const classes = useStyles();

  const alert = useStoreState((state) => state.alert);
  const setAlert = useStoreActions((actions) => actions.setAlert);

  const closeAlert = () => {
    setAlert({
      open: false,
      head: "",
      body: "",
      actions: [],
      isCustomer: false,
    });
  };

  return (
    <Backdrop open={alert.open} className={classes.backdrop}>
      <ZoomInAnimatedDiv show={alert.open}>
        <Card
          elevation={20}
          className={classes.message_card}
          style={{
            width: alert.isCustomer ? "300px" : isTab ? "300px" : "400px",
          }}
        >
          <div className={classes.message_head}>{alert.head}</div>
          <div className={classes.message_body}>{alert.body}</div>
          <div className={classes.message_buttons}>
            {alert.actions && alert.actions.length > 0 ? (
              alert.actions.map((action, index) => (
                <CustomButton
                  key={index}
                  style={{
                    width: "fit-content",
                    fontWeight: "bold",
                    maxHeight: 30,
                  }}
                  onClick={() => {
                    closeAlert();
                    if (action.action) {
                      action.action();
                    }
                  }}
                  bg={action.color || undefined}
                >
                  {action.name}
                </CustomButton>
              ))
            ) : (
              <CustomButton
                style={{
                  width: "fit-content",
                }}
                onClick={() => {
                  closeAlert();
                }}
              >
                {"Okay"}
              </CustomButton>
            )}
          </div>
        </Card>
      </ZoomInAnimatedDiv>
    </Backdrop>
  );
}
