import { Backdrop, Box, Card, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./edit_charge_styles";
import { useStoreActions, useStoreState } from "easy-peasy";
import Button1 from "../button_1/button_1";
import RestaurantApiService from "../../api_service/restaurant_api_service";
import ModelHeader from "../model_header/model_header";
import { showAlert } from "../message_box/show_alert";

export default function EditCharge() {
  const classes = useStyles();
  const openEditCharge = useStoreState((state) => state.openEditCharge);
  const chargeToEdit = useStoreState((state) => state.chargeToEdit);
  const setOpenEditCharge = useStoreActions(
    (actions) => actions.setOpenEditCharge
  );
  const setChargeToEdit = useStoreActions((actions) => actions.setChargeToEdit);

  const [state, setState] = useState({
    name: chargeToEdit?.name || "",
    type: chargeToEdit?.type || "OPTIONAL",
    value_type: chargeToEdit?.value_type || "AMOUNT",
    value: chargeToEdit?.value || 0,
    status: chargeToEdit?.is_active || true,
    is_discount: false,
  });

  const setInitialData = () => {
    setState({
      ...state,
      name: chargeToEdit?.name || "",
      type: chargeToEdit?.type || "OPTIONAL",
      value_type: chargeToEdit?.value_type || "AMOUNT",
      value: chargeToEdit?.value || 0,
      status: chargeToEdit?.is_active || true,
    });
  };
  useEffect(() => {
    if (openEditCharge) {
      setInitialData();
    }
  }, [openEditCharge]); // eslint-disable-line

  const onClose = () => {
    setOpenEditCharge(false);
    setChargeToEdit(null);
  };

  const onSubmit = () => {
    if (!state.name) {
      return showAlert({
        head: "Please be careful!",
        body: "Please enter the name of your 'Charge'",
      });
    }
    if (!state.value) {
      return showAlert({
        head: "Please be careful!",
        body: "Please enter the value of your 'Charge'",
      });
    }
    if (state.value_type === "PERCENTAGE" && state.value > 100) {
      return showAlert({
        head: "Please be careful!",
        body: "Value percentage must be less than 100%",
      });
    }
    if (chargeToEdit) {
      state.is_active = state.status;
      delete state.status;
      RestaurantApiService()
        .updateChargeCall(state, chargeToEdit.id)
        .then((response) => {
          if (response.success) {
            onClose();
          }
        });
    } else {
      delete state.status;
      RestaurantApiService()
        .createChargeCall(state)
        .then((response) => {
          if (response.success) {
            onClose();
          }
        });
    }
  };
  return (
    <Backdrop className={classes.backdrop} open={openEditCharge}>
      <Card className={classes.card}>
        <ModelHeader
          title={chargeToEdit ? "Update Charge" : "Add New Charge"}
          onClose={onClose}
        />
        <div style={{ padding: "20px" }}>
          <Box height={30} />
          <TextField
            className={classes.text_field}
            label="Charge Name"
            value={state.name}
            onChange={(event) => {
              setState({ ...state, name: event.target.value });
            }}
            InputLabelProps={{ shrink: true }}
          />
          <Box height={20} />
          <TextField
            variant="outlined"
            className={classes.text_field}
            label="Charge Type"
            value={state.type}
            onChange={(event) => {
              setState({ ...state, type: event.target.value });
            }}
            InputLabelProps={{ shrink: true }}
            select
          >
            <MenuItem value={"OPTIONAL"}>Optional Charge</MenuItem>
            <MenuItem value={"MANDATORY"}>Mandatory Charge</MenuItem>
          </TextField>
          <Box height={30} />
          <TextField
            variant="outlined"
            className={classes.text_field}
            style={{ width: "35%" }}
            label="Value Type"
            value={state.value_type}
            onChange={(event) => {
              setState({ ...state, value_type: event.target.value, value: 0 });
              document.getElementById("text_field_charge_value").focus();
              document.getElementById("text_field_charge_value").select();
            }}
            InputLabelProps={{ shrink: true }}
            select
          >
            <MenuItem value={"AMOUNT"}>Amount</MenuItem>
            <MenuItem value={"PERCENTAGE"}>Percentage</MenuItem>
          </TextField>
          <TextField
            id="text_field_charge_value"
            variant="outlined"
            type="number"
            className={classes.text_field}
            style={{ width: "29%", marginLeft: "3%" }}
            label="Charge Value"
            value={state.value}
            onChange={(event) => {
              setState({ ...state, value: Number(event.target.value) });
            }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
          <TextField
            variant="outlined"
            className={classes.text_field}
            style={{ width: "30%", marginLeft: "3%" }}
            label="Status"
            value={state.status}
            onChange={(event) => {
              setState({ ...state, status: event.target.value });
            }}
            InputLabelProps={{ shrink: true }}
            select
          >
            <MenuItem value={true}>Activated</MenuItem>
            <MenuItem value={false}>Deactivated</MenuItem>
          </TextField>
          <Box height={40} />
          <div className={classes.button_row}>
            <Button1 onClick={onSubmit} isSelected title="Submit" />
          </div>
        </div>
      </Card>
    </Backdrop>
  );
}
