import React from "react";
import useStyles from "./terms_and_conditions_styles";

export default function TermsAndConditions() {
  const classes = useStyles();
  return (
    <div className={classes.terms_and_conditions}>
      <a href="/terms_conditions">
        <span className={classes.words}>Terms and Conditions</span>
      </a>{" "}
      |{" "}
      <a href="/privacy_policy">
        <span className={classes.words}>Privacy Policy</span>
      </a>{" "}
      |{" "}
      <a href="/refund_policy">
        <span className={classes.words}>Refund Policy</span>
      </a>
    </div>
  );
}
