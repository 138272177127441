import React from "react";
import useStyles from "./edit_table_styles";
import QRCode from "qrcode.react";

export default function SNQrCodeMainDoor({ qr, id }) {
  const classes = useStyles();

  const logoInsideQR = {
    src: "/images/ceypaysvglogo.svg",
    x: undefined,
    y: undefined,
    height: 160,
    width: 160,
    excavate: true,
  };
  return (
    <div id={id} className={classes.qr_outer_with_frame}>
        <QRCode
          value={qr.url}
          size={800}
          level={"H"}
          className={classes.qr_main_print}
          imageSettings={logoInsideQR}
        />        
    </div>
  );
}
