export const PaymentMethods = {
  CASHIER: "CASHIER",
  NONE: "NONE",
  PAYHERE: "PAYHERE",
};

export const PayoutsStartDate = new Date("2023-01-01");

export const PayoutStatuses = {
  INITIALIZED: "INITIALIZED",
  PAID: "PAID",
};

export const ModifierTypes = {
  SINGLE_CHOICE: "SINGLE_CHOICE",
  MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
};

export const SubscriptionStatus = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
};
