import React, { useState } from "react";
import { Clear, Delete, MoreVert, PushPin, Web } from "@mui/icons-material";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import RestaurantApiService from "../../api_service/restaurant_api_service";
import AdminPopup from "../app_bar/admin_popup";
import { useStoreActions, useStoreState } from "easy-peasy";
import { button_green, button_red } from "../../themes/colors";
import { showAlert } from "../message_box/show_alert";

export default function OptionsMenu({ order }) {
  const setSelectedTab = useStoreActions((actions) => actions.setSelectedTab);
  const selectedOrder = useStoreState((state) => state.selectedOrder);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [state, setState] = useState({
    admin_popup: false,
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDelete = (event) => {
    handleClose();
    showAlert({
      head: "Are you sure?",
      body: "Tap on 'yes' if you're want to delete this order.",
      actions: [
        {
          name: "No",
          color: button_red,
        },
        {
          name: "Yes",
          color: button_green,
          action: () => {
            setState({ ...state, admin_popup: true });
          },
        },
      ],
      isCustomer: false,
    });
  };

  const deleteAction = async () => {
    await RestaurantApiService().closeOrderCall(order.id);
    setSelectedTab("orders");
  };

  const onPin = (event) => {
    handleClose();
    showAlert({
      head: "Are you sure?",
      body: order.pinned
        ? "This action will Unpin this order from the table. "
        : "This action will PIN this orders as the default order in this table and all the customers can see this order once they scan the QR code in this table.",
      actions: [
        {
          name: "No",
          color: button_red,
        },
        {
          name: "Yes",
          color: button_green,
          action: () => {
            if (order.pinned) {
              RestaurantApiService().unpinOrderCall(order.id);
            } else {
              RestaurantApiService().pinOrderCall(order.id);
            }
          },
        },
      ],
      isCustomer: false,
    });
  };

  const clearPaymentRequest = () => {
    handleClose();
    RestaurantApiService().clearPaymentRequestCall(order.id);
  };

  const onCopyLink = () => {
    navigator.clipboard.writeText(selectedOrder.order_url);
    handleClose();
  };

  return (
    <div>
      <AdminPopup
        open={state.admin_popup}
        setOpen={(val) => {
          setState({ ...state, admin_popup: val });
        }}
        customAction={deleteAction}
      />
      <IconButton
        size="small"
        color="info"
        id="order-options-button"
        aria-controls={open ? "order-options-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="order-options-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "order-options-button",
        }}
      >
        <MenuItem onClick={onDelete}>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete this order</ListItemText>
        </MenuItem>
        <MenuItem onClick={onPin}>
          <ListItemIcon>
            <PushPin fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {order.pinned ? "Unpin this order" : "Pin this order as default"}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={onCopyLink}>
          <ListItemIcon>
            <Web fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy link to order</ListItemText>
        </MenuItem>
        {order.is_payment_requested && (
          <MenuItem onClick={clearPaymentRequest}>
            <ListItemIcon>
              <Clear fontSize="small" />
            </ListItemIcon>
            <ListItemText>Clear payment request</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
