import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomerApiService from "../../../api_service/customer_api_service";
import Layout from "../../../components/layout";
import RestaurantLogo from "../../../components/restaurant_logo";
import styles from "../../../styles/landing.module.scss";
import { useHistory } from "react-router-dom";
import CustomButton from "../../../components/custom_button";
import commaNumber from "comma-number";
import qs from "querystring";
import { showAlert } from "../../../components/message_box/show_alert";

export default function LandingPage({ match, location }) {
  const { restaurant_code, table_no } = match.params;
  const { search } = location;
  const query = search ? qs.parse(search.substring(1)) : {};

  const history = useHistory();

  const getRestaurantDetails = async () => {
    setState({ ...state, loading: true });
    const response = await CustomerApiService().getRestaurantDetailsQrCall(
      restaurant_code,
      table_no
    );
    if (response.success) {
      setState({
        ...state,
        loading: false,
        restaurant: response.data.restaurant,
        table: response.data.table,
        order: response.data.order,
      });

      if (
        query.go_to_bill === "1" &&
        response.data.order &&
        response.data.order.order_amount > 0
      ) {
        window.history.pushState({}, document.title, window.location.pathname);
        history.push(
          `/restaurants/${response.data.restaurant.id}/tables/${response.data.table.id}/orders/${response.data.order.id}`
        );
      }
    } else {
      if (response.data && response.data.restaurant_not_available) {
        history.push("/customer/order_history");
        showAlert({ head: "Info", body: response.message, isCustomer: true });
      } else if (response.data && response.data.table_not_available) {
        history.push(`/`);
      }
    }
  };

  const [state, setState] = useState({
    loading: true,
    restaurant: null,
    table: null,
    order: null,
  });

  useEffect(() => {
    getRestaurantDetails();
  }, []); //eslint-disable-line

  return state.loading ? (
    <div />
  ) : (
    <Layout
      footer
      className={styles.container}
      containerClassName={styles.innerConntainer}
    >
      <Grid container justify="center">
        {state.restaurant && (
          <Grid item xs={12}>
            <div className={styles.restaurentLogoContainer}>
              {state.restaurant.logo && (
                <RestaurantLogo url={state.restaurant.logo} />
              )}
              {/* <RestaurantLogo url={state.restaurant.logo} /> */}
              <div className={styles.restaurantNameContainer}>
                {state.restaurant.name}
              </div>
            </div>
          </Grid>
        )}
        {state.restaurant && (
          <Grid item xs={12}>
            <div className={styles.tableNumberContainer}>
              <div className={styles.tableName}>Table</div>
              <div className={styles.tableChild}>{state.table.number}</div>
            </div>
            {/* <Link
              id="landing-edit-table"
              to={`/restaurants/${restaurant_code}/change_table`}
            >
              <div className={styles.linkCont}>Not your table?</div>
            </Link> */}
          </Grid>
        )}

        {state.restaurant && (
          <Grid item xs={12} className={styles.buttonSet}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomButton
                  id="auth-view-menu"
                  variant={state.order ? "outlined" : "contained"}
                  onClick={() => {
                    history.push(
                      `/restaurants/${restaurant_code}/tables/${table_no}/menu`
                    );
                  }}
                >
                  {`View Menu ${
                    state.restaurant.self_ordering ? "/ Order" : ""
                  }`}
                </CustomButton>
              </Grid>

              {state.order && (
                <Grid item xs={12}>
                  <CustomButton
                    id="landing-pay"
                    disabled={!state.order}
                    onClick={() => {
                      if (state.order) {
                        history.push(
                          `/restaurants/${state.restaurant.id}/tables/${state.table.id}/orders/${state.order.id}`
                        );
                      } else {
                        // router.reload();
                      }
                    }}
                  >
                    View Bill &nbsp;
                    {commaNumber(state.order.order_amount.toFixed(2))}
                    &nbsp; {state.restaurant?.currency}
                  </CustomButton>
                </Grid>
              )}
              <Grid item xs={12}>
                <div
                  className={styles.moreInfo}
                  onClick={() => {
                    history.push(`/restaurants/${restaurant_code}/information`);
                  }}
                >
                  More Information
                </div>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Layout>
  );
}
