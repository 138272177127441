import { makeStyles } from "@mui/styles";
import { ceypay_logo_blue_color, text_color_light } from "../../themes/colors";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  message_card: {
    borderRadius: "3px",
  },
  message_head: {
    padding: "10px",
    backgroundColor: ceypay_logo_blue_color,
    color: text_color_light,
    fontWeight: "bold",
    fontFamily: "sans-serif",
  },
  message_body: {
    padding: "10px",
    fontFamily: "system-ui",
    minHeight: "50px",
    color: "#6f6f6f",
  },
  message_buttons: {
    justifyContent: "end",
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
}));

export default useStyles;
