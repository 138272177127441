import styled, { keyframes } from "styled-components";

export const MainContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  ${({ isTablePadOnly }) =>
    `${
      !isTablePadOnly &&
      `display: grid;
       grid-template-columns: 2.7fr 1.3fr; `
    }`}
`;

export const LogoContianer = styled.div`
  height: 50px;
  padding: 14px 14px 4px 14px;
  box-sizing: content-box;
`;

export const LeftMainContainer = styled.div`
  display: grid;
  grid-template-columns: 1.4fr 8.6fr;
  ${({ isTablePadOnly }) => `${isTablePadOnly ? "display: none;" : ""}`}
`;

export const LeftFirstChildContainer = styled.div`
  padding-bottom: 180px;
`;

export const LeftSecondChildContainer = styled.div`
  padding-top: 20px;
`;

export const LeftSecondChildHeaderContainer = styled.div``;

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: ${({ height }) => `${height}px`};
`;

export const CategoryHeader = styled.div`
  padding: 10px 20px;
  text-align: center;
  background-color: #fff;
  width: fit-content;
  border-radius: 40px;
  margin: auto;
  font-weight: 600;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CategoryText = styled.div`
  padding: 0px 10px;
`;

export const RightMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px 0 20px;
  background: #fff;
  position: relative;
  ${({ isTablePadOnly }) =>
    `${
      isTablePadOnly &&
      `  max-width: 1100px;
         margin: auto;`
    }`}
`;

export const RightHeaderContainer = styled.div`
  padding-top: 10px;
  font-size: 24px;
`;

export const TablePadContainer = styled.div`
  height: ${({ height }) => `${height - 30}px`};
  overflow-y: scroll;
  padding-right: 10px;
`;

const breathingAnimation = keyframes`
  0% {
    -webkit-transform: scale(0.9);
    transform:  scale(0.9);
  }
  25% {
    -webkit-transform: scale(1);
    transform:  scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    transform:  scale(1.2);
  }
  75% {
    -webkit-transform: scale(1);
    transform:  scale(1);
  }
  100% {
    -webkit-transform: scale(0.9);
    transform:  scale(0.9);
  }
`;

export const ExpandICContainer = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  left: -28px;
  top: ${({ height }) => `${height / 2 - 25}px`};
  transform: rotate(90deg);
  z-index: 4;
  background-color: #fff;
  border-radius: 30px;
  cursor: pointer;
  & > button > svg {
    animation: ${breathingAnimation} 3s ease-out infinite normal;
  }
`;

export const ShrinkICContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  top: 60px;
  right: 40%;
  z-index: 4;
  cursor: pointer;
  border-radius: 6px;
  padding: 0 10px;
  background-color: #f4f5f8;
`;

export const TextContent = styled.div``;

export const CategoryEditBtnContainer = styled.div`
  padding-top: 13px;
  text-align: center;
`;
