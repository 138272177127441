import React, { useEffect, useState } from "react";
import useStyles from "./subscriptions_page_styles";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import AdminApiService from "../../../api_service/admin_api_service";
import AdminAppBar from "../../../components/admin_app_bar/app_bar";
import { CheckCircle, Download } from "@mui/icons-material";
import commaNumber from "comma-number";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import Button1 from "../../../components/button_1/button_1";
import { getImageUrl } from "../../../utils/helpers";
import { SubscriptionStatus } from "../../../utils/constants";
import { button_green, button_red } from "../../../themes/colors";
import { showAlert } from "../../../components/message_box/show_alert";

export default function AdminSubscriptionDetails() {
  const classes = useStyles();
  const { id } = useParams();

  const [state, setState] = useState({
    loading: true,
    restaurant: null,
  });

  const getSubscriptionDetails = async () => {
    const response = await AdminApiService().getAdminSubscriptions(id);
    if (response.success) {
      setState({ ...state, loading: false, restaurant: response.data });
    }
  };
  useEffect(() => {
    getSubscriptionDetails();
  }, []); //eslint-disable-line

  return (
    !state.loading &&
    state.restaurant && (
      <div className={classes.root}>
        <AdminAppBar title={"Subscriptions"} />
        <Details restaurant={state.restaurant} />
        <div className={classes.main_row}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 130 }} align="left">
                    Uploaded date
                  </TableCell>
                  <TableCell align="left">Amount</TableCell>
                  <TableCell style={{ minWidth: 150 }} align="left">
                    Note
                  </TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.restaurant?.subscriptions.map((subscription) => (
                  <Row
                    key={subscription.id}
                    subscription={subscription}
                    onRefresh={getSubscriptionDetails}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    )
  );
}

function Row({ subscription, onRefresh }) {
  const onApproveConf = () => {
    showAlert({
      head: "Are you sure?",
      body: "This action will approve this payment and it cannot be undone. Don't forget to update the subscription period.",
      actions: [
        {
          name: "No",
          color: button_red,
        },
        {
          name: "Yes",
          color: button_green,
          action: () => {
            onApprove();
          },
        },
      ],
    });
  };
  const onApprove = () => {
    AdminApiService()
      .approveAdminSubscription(subscription.id, subscription.restaurant_id)
      .then((response) => {
        if (response.success) {
          onRefresh();
        }
      });
  };
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="left">
          {format(new Date(subscription.createdAt), "yyyy MMM d")}
        </TableCell>
        <TableCell align="left">
          {commaNumber(subscription.amount.toFixed(2))}
        </TableCell>
        <TableCell align="left">{subscription.note}</TableCell>
        <TableCell
          style={{
            fontWeight: "bold",
            color:
              subscription.status === SubscriptionStatus.APPROVED
                ? "green"
                : "blue",
          }}
          align="center"
        >
          {subscription.status}
        </TableCell>
        <TableCell align="center">
          <Tooltip title="View payment receipt">
            <IconButton
              onClick={() => {
                window.open(getImageUrl(subscription.receipt), "_blank");
              }}
            >
              <Download color="primary" />
            </IconButton>
          </Tooltip>
          {subscription.status !== SubscriptionStatus.APPROVED && (
            <Tooltip title="Approve payment">
              <IconButton onClick={onApproveConf}>
                <CheckCircle color="success" />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function Details({ restaurant }) {
  const classes = useStyles();

  const [state, setState] = useState({
    subscription_fee: 0,
    subscribed_until: "",
  });

  useEffect(() => {
    setState({
      ...state,
      subscription_fee: restaurant.subscription_fee || 0,
      subscribed_until: restaurant.subscribed_until
        ? format(new Date(restaurant.subscribed_until), "yyyy-MM-dd")
        : "",
    });
  }, [restaurant]); //eslint-disable-line

  const onSubmit = () => {
    AdminApiService().updateAdminSubscriptionDetails(restaurant.id, {
      subscription_fee: state.subscription_fee,
      subscribed_until: state.subscribed_until,
    });
  };
  return (
    <div className={classes.detail_outer}>
      <div className={classes.detail_name}>{restaurant.name}</div>
      <TextField
        type={"number"}
        label={"Subscription Fee"}
        InputLabelProps={{ shrink: true }}
        value={state.subscription_fee}
        onChange={(event) => {
          setState({ ...state, subscription_fee: Number(event.target.value) });
        }}
        inputProps={{ min: 0 }}
      />
      <TextField
        type={"date"}
        label={"Subscribed Until"}
        InputLabelProps={{ shrink: true }}
        value={state.subscribed_until}
        onChange={(event) => {
          setState({ ...state, subscribed_until: event.target.value });
        }}
      />
      <Button1 onClick={onSubmit} isSelected title={"Update Details"} />
    </div>
  );
}
