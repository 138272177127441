import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useStoreActions, useStoreState } from "easy-peasy";

export default function TabView({
  tables,
  orders,
  orderDetails,
  charges,
  discounts,
}) {
  const selectedTab = useStoreState((state) => state.selectedTab);
  const setSelectedTab = useStoreActions((actions) => actions.setSelectedTab);
  const selectedOrder = useStoreState((state) => state.selectedOrder);
  const adminView = useStoreState((state) => state.currentView === "ADMIN");
  const selectedTable = useStoreState((state) => state.selectedTable);

  const setSelectedOrder = useStoreActions(
    (actions) => actions.setSelectedOrder
  );
  const setSelectedTable = useStoreActions(
    (actions) => actions.setSelectedTable
  );

  const handleChange = (event, newValue) => {
    if (newValue === "tables") {
      setSelectedOrder(null);
      setSelectedTable(null);
    } else if (newValue === "orders") {
      setSelectedOrder(null);
    }
    setSelectedTab(newValue);
  };
  return (
    <div>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab value="tables" label="Tables" />
        {selectedTable && !adminView && <Tab value="orders" label="Orders" />}
        {selectedOrder && !adminView && (
          <Tab value="order_detail" label="Order Details" />
        )}
        {adminView && <Tab value="charges" label="Charges" />}
        {adminView && <Tab value="discounts" label="Discounts" />}
      </Tabs>
      <div style={{ height: "10px" }} />
      <TabPanel value={selectedTab} index={"tables"}>
        {tables}
      </TabPanel>
      {selectedTable && !adminView && (
        <TabPanel value={selectedTab} index={"orders"}>
          {orders}
        </TabPanel>
      )}
      {selectedOrder && !adminView && (
        <TabPanel value={selectedTab} index={"order_detail"}>
          {orderDetails}
        </TabPanel>
      )}
      {adminView && (
        <TabPanel value={selectedTab} index={"charges"}>
          {charges}
        </TabPanel>
      )}
      {adminView && (
        <TabPanel value={selectedTab} index={"discounts"}>
          {discounts}
        </TabPanel>
      )}
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
