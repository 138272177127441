import { Backdrop, Box, Card, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./edit_category_styles";
import { useStoreActions, useStoreState } from "easy-peasy";
import Button1 from "../button_1/button_1";
import ImagePicker from "../image_picker/image_picker";
import RestaurantApiService from "../../api_service/restaurant_api_service";
import ModelHeader from "../model_header/model_header";
import { showAlert } from "../message_box/show_alert";

export default function EditCategory() {
  const classes = useStyles();
  const openEditCategory = useStoreState((state) => state.openEditCategory);
  const categoryToEdit = useStoreState((state) => state.categoryToEdit);
  const setOpenEditCategory = useStoreActions(
    (actions) => actions.setOpenEditCategory
  );

  const [state, setState] = useState({
    image: null,
    image_url: categoryToEdit?.image || "",
    name: categoryToEdit?.name || "",
    description: categoryToEdit?.description || "",
    order_id: categoryToEdit?.order_id || undefined,
  });
  const changeState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const setInitialData = () => {
    setState({
      ...state,
      image: null,
      image_url: categoryToEdit?.image || "",
      name: categoryToEdit?.name || "",
      description: categoryToEdit?.description || "",
      order_id: categoryToEdit?.order_id || undefined,
    });
  };
  useEffect(() => {
    if (openEditCategory) {
      setInitialData();
    }
  }, [openEditCategory]); // eslint-disable-line

  const onClose = () => {
    setOpenEditCategory(false);
  };

  const setFile = (file) => {
    changeState("image", file);
  };

  const onSubmit = () => {
    const formData = new FormData();
    if (state.image) {
      formData.append("file", state.image);
    }
    if (!state.name) {
      return showAlert({
        head: "Please be careful!",
        body: "Please enter the name of your 'Item Category'",
      });
    }
    formData.append("name", state.name);
    if (state.description || state.description === "") {
      formData.append("description", state.description);
    }

    if (categoryToEdit && state.order_id) {
      formData.append("order_id", state.order_id);
    }
    if (categoryToEdit) {
      RestaurantApiService()
        .updateItemCategoryCall(formData, categoryToEdit.id)
        .then((response) => {
          if (response.success) {
            onClose();
          }
        });
    } else {
      RestaurantApiService()
        .createItemCategoryCall(formData)
        .then((response) => {
          if (response.success) {
            onClose();
          }
        });
    }
  };
  return (
    <Backdrop className={classes.backdrop} open={openEditCategory}>
      <Card className={classes.card}>
        <ModelHeader
          title={categoryToEdit ? "Update Category" : "Add New Category"}
          onClose={onClose}
        />
        <div style={{ padding: "20px" }}>
          <Box height={10} />
          <ImagePicker
            imageUrl={state.image_url}
            setFile={setFile}
            file={state.image}
            title={"Category Image"}
          />
          <Box height={30} />
          <TextField
            className={classes.text_field}
            label="Please enter 'Category Name'"
            value={state.name}
            onChange={(event) => {
              changeState("name", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
          <Box height={40} />
          <TextField
            className={classes.text_field}
            label="Please enter 'Category Description'"
            value={state.description}
            onChange={(event) => {
              changeState("description", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
          {categoryToEdit && (
            <>
              <Box height={40} />
              <TextField
                className={classes.text_field}
                label="Please enter 'Order Id'"
                value={state.order_id}
                onChange={(event) => {
                  changeState("order_id", event.target.value);
                }}
                InputLabelProps={{ shrink: true }}
                type="number"
              />
            </>
          )}
          <Box height={20} />
          <div className={classes.button_row}>
            <Button1 onClick={onSubmit} isSelected title="Submit" />
          </div>
        </div>
      </Card>
    </Backdrop>
  );
}
