import { makeStyles } from "@mui/styles";
import { primary_gradient } from "../../themes/colors";

const useStyles = makeStyles({
  price: {
    color: "white",
    fontWeight: "bold",
    backgroundImage: primary_gradient,
    width: "fit-content",
    padding: "2px 10px",
    borderRadius: "5px",
    fontSize: "15px",
    margin: "auto",
  },
});

export default useStyles;
