import { makeStyles } from "@mui/styles";
import { background_color_2 } from "../../../themes/colors";

const useStyles = makeStyles({
  root: {
    height: "100vh",
    width: "100%",
    backgroundColor: background_color_2,
    padding: "20px",
    overflow: "auto",
  },
  main_row: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    zIndex: 4,
  },
  detail_outer: {
    display: "flex",
    flexDirection: "column",
    padding: 20,
    gap: 20,
    maxWidth: 500,
    margin: "20px auto",
    backgroundColor: "white",
    borderRadius: 10,
  },
  add_btn: {
    width: "fit-content",
    alignSelf: "end",
  },
  deposit_card: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  deposit_outer: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    paddingLeft: 15,
  },
});

export default useStyles;
