import CustomerApiService from "../api_service/customer_api_service";
import { getWithExpiry, setWithExpiry } from "./helpers";

export default function UserHooks() {
  const getUser = async () => {
    const user = getWithExpiry("sn_rest_local_cust_profile");
    if (user) {
      return {
        name: user.name,
        email: user.email,
        image: user.image,
      };
    } else {
      const response = await CustomerApiService().getProfileCall();
      if (response.success) {
        const user = response.data;
        setUser(user);
        return {
          name: user.name,
          email: user.email,
          image: user.image,
        };
      }
    }
  };

  const setUser = (user) => {
    setWithExpiry("sn_rest_local_cust_profile", user, 1000 * 60);
  };

  return {
    getUser: getUser,
    setUser: setUser,
  };
}
